var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table_wrap"},[_c('div',{staticClass:"left_option",style:({ height: _vm.rightHeight })},[_c('div',{staticStyle:{"height":"6.3rem"}},[_c('el-scrollbar',{ref:"scrollbar",staticStyle:{"height":"100%"}},[_vm._l((_vm.leftList),function(item,index){return _c('div',{key:index,staticClass:"left_option_item",class:{ left_active: _vm.selectLeftKey === ("d" + (index + 1)) },on:{"click":function($event){return _vm.selectLeft(index, 'left')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectLeftKey === ("d" + (index + 1))),expression:"selectLeftKey === `d${index + 1}`"}],staticClass:"left_wire"}),_vm._v(" "+_vm._s(item.title)+" ")])})],2)],1)]),_c('div',{ref:"rightTable",staticClass:"right_table"},[(_vm.problemList && _vm.problemList.length > 0)?_c('div',{staticClass:"dia_problem"},[_c('div',{staticClass:"flex-y-c mb10"},[_c('img',{staticClass:"mr10",staticStyle:{"width":"24px"},attrs:{"alt":"","src":require("../../../common/imgs/case/dia_warn_cion.png")}}),_c('h5',[_vm._v(_vm._s(_vm.$t("cases.createImage.czrxwt")))])]),_c('div',{staticClass:"pro_box curp"},_vm._l((_vm.problemList),function(item,index){return _c('span',{key:((item.id) + "_" + index),on:{"click":function($event){return _vm.selectLeft(+item.groupNo)}}},[_vm._v(" "+_vm._s(item.groupNo)+" "+_vm._s(item.groupName)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"select_type",attrs:{"id":"1"}},[_c('div',{staticClass:"flex-y-c"},[_vm._m(0),_c('p',{staticClass:"select_type_title mr10"},[_vm._v(" 1."+_vm._s(_vm.$t("cases.createDiagnosis.zyjzmb"))+" ")])]),(_vm.diagnosisProblem['1'])?_c('p',{staticClass:"problem_note"},[_vm._v(" "+_vm._s(_vm.diagnosisProblem["1"])+" ")]):_vm._e(),_c('div',{staticClass:"flex-y-c"},[_c('div',{staticClass:"option_left_title",style:(_vm.language === 'en_US'
              ? 'width: 1rem; text-align: left;margin-right:0'
              : '')},[_vm._v(" "+_vm._s(_vm.$t("cases.createDiagnosis.ycwt"))+" ")]),_c('div',{staticClass:"flex-y-c mt10"},_vm._l((_vm.caseOptions['tooth_problem_i']),function(item,index){return _c('div',{key:((item.key) + "_" + index),staticClass:"common_btn",class:_vm.values(
                _vm.map(_vm.diagnosisParams.toothProblemTwo.children, function (it) { return it.key; })
              ).indexOf(item.key) !== -1
                ? 'is_active'
                : '',on:{"click":function($event){return _vm.levelOneClick({
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'toothProblemTwo',
                },
                radio: false,
              })}}},[_vm._v(" "+_vm._s(item.value)+" ")])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.values(
            _vm.map(_vm.diagnosisParams.toothProblemTwo.children, function (it) { return it.key; })
          ).indexOf('other') !== -1
            ? 'is_active'
            : ''
        ),expression:"\n          values(\n            map(diagnosisParams.toothProblemTwo.children, (it) => it.key)\n          ).indexOf('other') !== -1\n            ? 'is_active'\n            : ''\n        "}],staticClass:"remark_box"},[_c('el-input',{staticClass:"tex_b_a",attrs:{"autosize":{ minRows: 4, maxRows: 6 },"disabled":_vm.isBackDisabled,"placeholder":_vm.$t('common.common.qsrnr'),"maxlength":"2000","show-word-limit":"","type":"textarea"},model:{value:(_vm.diagnosisParams.toothOtherProblemTwo),callback:function ($$v) {_vm.$set(_vm.diagnosisParams, "toothOtherProblemTwo", $$v)},expression:"diagnosisParams.toothOtherProblemTwo"}})],1)]),_c('div',{staticClass:"select_type",attrs:{"id":"2"}},[_c('div',{staticClass:"flex-y-c mb10"},[_vm._m(1),_c('p',{staticClass:"select_type_title mr10"},[_vm._v(" 2."+_vm._s(_vm.$t("cases.createDiagnosis.txt3"))+" ")]),_c('span',{staticClass:"main_theme_color_999 fz14 ml10"},[_vm._v(_vm._s(_vm.$t("cases.createDiagnosis.jsdgjz")))])]),(_vm.diagnosisProblem[2])?_c('p',{staticClass:"problem_note"},[_vm._v(" "+_vm._s(_vm.diagnosisProblem[2])+" ")]):_vm._e(),_c('div',{staticClass:"flex-y-c"},_vm._l((_vm.caseOptions['to_correct_tooth_jaw']),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.levelOneClick({
              noClear: true,
              addItem: {
                key: item.key,
                value: item.value,
                submitKey: 'toCorrectToothJaw',
              },
            })}}},[(item.key !== 'whole_jaw')?_c('el-popover',{attrs:{"disabled":_vm.isBackDisabled,"popper-options":{
              boundariesElement: 'body',
            },"placement":"bottom","trigger":"click"}},[_c('div',{staticClass:"flex-y-c"},_vm._l((_vm.diagnosisParams.toCorrectToothJaw.key ===
                'upjaw'
                  ? _vm.caseOptions['to_correct_tooth_upjaw']
                  : _vm.caseOptions['to_correct_tooth_downjaw']),function(it,idx){return _c('div',{key:idx,staticClass:"common_btn",class:{
                  is_active:
                    _vm.diagnosisParams['toCorrectToothJaw'].children &&
                    _vm.diagnosisParams['toCorrectToothJaw'].children[0].key ===
                      it.key,
                },on:{"click":function($event){return _vm.levelTowClick({
                    wrapFiled: 'toCorrectToothJaw',
                    addItem: {
                      key: it.key,
                      submitKey: {
                        upjaw: 'toCorrectToothUpjaw',
                        downjaw: 'toCorrectToothDownjaw',
                      }[item.key],
                      value: _vm.diaKeyObj[it.key],
                    },
                    radio: true,
                  })}}},[_vm._v(" "+_vm._s(it.value)+" ")])}),0),_c('div',{staticClass:"common_btn",class:{
                is_active: _vm.diagnosisParams.toCorrectToothJaw.key === item.key,
              },attrs:{"slot":"reference","data-key":item.key},slot:"reference"},[_vm._v(" "+_vm._s(item.value)+" ")])]):_c('div',{staticClass:"common_btn",class:{
              is_active: _vm.diagnosisParams.toCorrectToothJaw.key === item.key,
            },attrs:{"data-key":item.key}},[_vm._v(" "+_vm._s(item.value)+" ")])],1)}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.get(_vm.diagnosisParams.toCorrectToothJaw, 'children[0].value')),expression:"get(diagnosisParams.toCorrectToothJaw, 'children[0].value')"}],staticClass:"single_tip_box"},[_c('p',[_vm._v(" "+_vm._s(_vm.get(_vm.diagnosisParams.toCorrectToothJaw, "children[0].value"))+" ")])])]),_c('div',{staticClass:"select_type",attrs:{"id":"3"}},[_c('div',{staticClass:"flex-y-c mb2rem"},[_c('p',{staticClass:"select_type_title mr10"},[_vm._v(" 3. "+_vm._s(_vm.$t("cases.createDiagnosis.txt4"))+" ")]),_c('p',[_c('span',{staticClass:"main_theme_color_999 fz14"},[_vm._v(_vm._s(_vm.$t("cases.createDiagnosis.gaptxt3")))])])]),(_vm.diagnosisProblem[3])?_c('p',{staticClass:"problem_note"},[_vm._v(" "+_vm._s(_vm.diagnosisProblem[3])+" ")]):_vm._e(),_c('ChildrenTeeth',{staticClass:"mb40 mt40",attrs:{"commonTeeth":_vm.commonTeeth,"patientType":'gap',"readOnly":_vm.isBackDisabled,"teethType":1},on:{"updateTeeth":_vm.updateTeeth}})],1),_c('div',{staticClass:"select_type",attrs:{"id":"4"}},[_c('div',{staticClass:"select_type_title mb2rem"},[_vm._v(" 4. "+_vm._s(_vm.$t("cases.createDiagnosis.txt5"))+" "),_c('span',{staticClass:"main_theme_color_999 fz14"},[_vm._v(_vm._s(_vm.$t("cases.createDiagnosis.kcydbkyd")))]),(_vm.diagnosisProblem[4])?_c('p',{staticClass:"problem_note mt2rem"},[_vm._v(" "+_vm._s(_vm.diagnosisProblem[4])+" ")]):_vm._e(),_c('div',{staticClass:"mt10 flex-y-c"},_vm._l((_vm.caseOptions['non_moveable_teeth']),function(item,index){return _c('div',{key:index,staticClass:"common_btn",class:{
              is_active: item.key === _vm.diagnosisParams.nonMoveableTeeth.key,
            },on:{"click":function($event){return _vm.levelOneClick({
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'nonMoveableTeeth',
                },
              })}}},[_vm._v(" "+_vm._s(item.value)+" ")])}),0),_c('ChildrenTeeth',{directives:[{name:"show",rawName:"v-show",value:(_vm.diagnosisParams.nonMoveableTeeth.key === 'true'),expression:"diagnosisParams.nonMoveableTeeth.key === 'true'"}],ref:"teethComponents",staticClass:"mb40 mt40",attrs:{"commonTeeth":_vm.commonTeeth,"patientType":'gap',"readOnly":_vm.isBackDisabled,"teethType":2},on:{"updateTeeth":_vm.updateTeeth}})],1)]),_c('div',{staticClass:"select_type",attrs:{"id":"5"}},[_c('div',{staticClass:"select_type_title mb2rem"},[_vm._v(" 5. "+_vm._s(_vm.$t("cases.createDiagnosis.txt6"))+" "),_c('span',{staticClass:"main_theme_color_999 fz14"},[_vm._v(_vm._s(_vm.$t("cases.createDiagnosis.kcyfj")))]),(_vm.diagnosisProblem[5])?_c('p',{staticClass:"problem_note mt2rem"},[_vm._v(" "+_vm._s(_vm.diagnosisProblem[5])+" ")]):_vm._e(),_c('div',{staticClass:"mt1rem flex-y-c"},_vm._l((_vm.caseOptions[
              'not_available_for_attachments'
            ]),function(item,index){return _c('div',{key:index,staticClass:"common_btn",class:{
              is_active:
                item.key === _vm.diagnosisParams.notAvailableAttachments.key,
            },on:{"click":function($event){return _vm.levelOneClick({
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'notAvailableAttachments',
                },
              })}}},[_vm._v(" "+_vm._s(item.value)+" ")])}),0),_c('ChildrenTeeth',{directives:[{name:"show",rawName:"v-show",value:(_vm.diagnosisParams.notAvailableAttachments.key === 'true'),expression:"diagnosisParams.notAvailableAttachments.key === 'true'"}],staticClass:"mb40 mt40",attrs:{"commonTeeth":_vm.commonTeeth,"patientType":'gap',"readOnly":_vm.isBackDisabled,"teethType":3},on:{"updateTeeth":_vm.updateTeeth}})],1)]),_c('div',{staticClass:"select_type",attrs:{"id":"6"}},[_c('p',{staticClass:"select_type_title",staticStyle:{"margin-bottom":"24px"}},[_vm._v(" 6."+_vm._s(_vm.$t("cases.createDiagnosis.txt13"))+" "),_c('span',{staticClass:"main_theme_color_999 fz14"},[_vm._v(_vm._s(_vm.$t("cases.createDiagnosis.sjfasmrgbszyx")))])]),(_vm.diagnosisProblem[6])?_c('p',{staticClass:"problem_note"},[_vm._v(" "+_vm._s(_vm.diagnosisProblem[6])+" ")]):_vm._e(),_c('IntervalCorrect',{ref:"intervalCorrect",staticClass:"mb40",attrs:{"commonTeeth":_vm.commonTeeth,"readOnly":_vm.isBackDisabled,"uploadIntervalList":_vm.uploadIntervalList,"productType":"I"},on:{"updateInterval":_vm.updateInterval}})],1),_c('div',{staticClass:"select_type",attrs:{"id":"7"}},[_c('p',{staticClass:"select_type_title mb20"},[_vm._v(" 7."+_vm._s(_vm.$t("cases.createDiagnosis.txt17"))+" ")]),(_vm.diagnosisProblem[7])?_c('p',{staticClass:"problem_note"},[_vm._v(" "+_vm._s(_vm.diagnosisProblem[7])+" ")]):_vm._e(),_c('div',{staticClass:"remark_box"},[_c('el-input',{staticClass:"tex_b_a",attrs:{"autosize":{ minRows: 4, maxRows: 6 },"disabled":_vm.isBackDisabled,"placeholder":_vm.$t('common.common.qsrnr'),"maxlength":"2000","show-word-limit":"","type":"textarea"},model:{value:(_vm.diagnosisParams.specialInstructions),callback:function ($$v) {_vm.$set(_vm.diagnosisParams, "specialInstructions", $$v)},expression:"diagnosisParams.specialInstructions"}})],1)])]),_c('full-loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"main_theme_color_red fz14 mr4"},[_vm._v("●")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"main_theme_color_red fz14 mr4"},[_vm._v("●")])])}]

export { render, staticRenderFns }