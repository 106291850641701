var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create_preview"},[_c('div',{staticClass:"preview_tip"},[(
        _vm.previewNo.groupOneNameList.length > 0 ||
          _vm.previewNo.groupTwoNameList.length > 0
      )?[_c('h5',{staticClass:"tip_h5"},[_c('span',{staticClass:"tip_icon"}),_vm._v(_vm._s(_vm.$t("cases.createPreview.wwcts"))+" ")]),_c('ul',{staticClass:"p_tip_ul"},[(_vm.previewNo.groupOneNameList.length > 0)?_c('li',{staticClass:"p_tip_li"},[_c('h5',{staticClass:"p_tip_l_h"},[_vm._v(" "+_vm._s(_vm.$t("cases.createPreview.jzzl"))+" ")]),_c('div',{staticClass:"p_tip_l_box"},_vm._l((_vm.previewNo.groupOneNameList),function(item){return _c('span',{key:((_vm.$t('cases.createPreview.jzzl')) + "_" + item),on:{"click":function($event){return _vm.groupClick(item, '1')}}},[_vm._v(_vm._s(item))])}),0)]):_vm._e(),(_vm.previewNo.groupTwoNameList.length > 0)?_c('li',{staticClass:"p_tip_li"},[_c('h5',{staticClass:"p_tip_l_h"},[_vm._v(" "+_vm._s(_vm.$t("cases.createPreview.yxzlsm"))+" ")]),_c('div',{staticClass:"p_tip_l_box"},_vm._l((_vm.previewNo.groupTwoNameList),function(item){return _c('span',{key:((_vm.$t('cases.createPreview.yxzlsm')) + "_" + item),attrs:{"data-tit":item,"data-type":"2"},on:{"click":function($event){return _vm.groupClick(item, '2')}}},[_vm._v(_vm._s(item))])}),0)]):_vm._e()])]:_vm._e(),(!_vm.queer)?[_c('h2',{staticClass:"tip_h5"},[_c('span',{staticClass:"tip_icon"}),_vm._v(_vm._s(_vm.$t("cases.createPreview.wwcts"))+" ")]),_c('div',{staticClass:"queer"},[_c('span',{staticClass:"mr10",on:{"click":function($event){return _vm.goBackQueer('1')}}},[_vm._v(" "+_vm._s(_vm.$t("cases.createPreview.jzzl"))+" ")]),_c('span',{on:{"click":function($event){return _vm.goBackQueer('2')}}},[_vm._v(" "+_vm._s(_vm.$t("cases.createPreview.yxzlsm"))+" ")])])]:_vm._e()],2),(
      _vm.previewNo.groupOneNameList.length < 1 &&
        _vm.previewNo.groupTwoNameList.length < 1 &&
        _vm.queer
    )?_c('div',{staticClass:"preview_box"},[_c('h5',{staticClass:"tip_h5"},[_c('span',{staticClass:"tip_icon tip_icon_ok"}),_vm._v(" "+_vm._s(_vm.$t("cases.createPreview.bltxwz"))+" ")]),_c('div',{staticClass:"preview_box_content"},[_c('div',{staticClass:"flex-y-c mt2rem mb2rem"},[_c('p',{staticClass:"to_clinical",on:{"click":function($event){$event.stopPropagation();return _vm.goClinical($event)}}},[_vm._v(" "+_vm._s(_vm.$t("common.lcph.cklcph"))+" ")]),_c('p',{staticClass:"fz14 main_theme_color_999"},[_vm._v(" "+_vm._s(_vm.$t("cases.createPreview.ggphtext"))+" ")])]),_c('ul',{staticClass:"preview_box_ul"},[_c('public-cases-user',{attrs:{"caseInfo":_vm.details.caseInfo,"userInfo":_vm.userInfo}}),_c('PreviewDiagnosis',{key:1,attrs:{"casesData":_vm.casesData,"commonTeeth":_vm.commonTeeth,"crowdTeethList":_vm.crowdTeethList,"details":_vm.details,"productType":_vm.$route.query.productType,"uploadIntervalList":_vm.uploadIntervalList,"veneerList":_vm.commonVeneerList}}),_c('public-cases-pic',{attrs:{"details":_vm.details,"surfacePicList":_vm.routeQueryType === 'F' ? _vm.yueyaPicList : _vm.surfacePicList,"xPicList":_vm.xPicList}}),_c('public-teeth-model',{attrs:{"details":_vm.details,"fileList":_vm.details.attachmentList}})],1),_c('div',{staticClass:"preview_foo"},[_c('p',{staticClass:"submit_btn curp flex-x-y-c fz14 main_theme_color_333",staticStyle:{"width":"2rem","height":"0.48rem"},on:{"click":_vm.submitCase}},[_vm._v(" "+_vm._s(_vm.$t("common.message.tj"))+" ")]),_c('p',{staticClass:"mt10 main_theme_color_red fz14"},[_vm._v(" "+_vm._s(_vm.$t("common.message.qqrww"))+" ")])])])]):_vm._e(),_c('FullLoading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]})],1)}
var staticRenderFns = []

export { render, staticRenderFns }