<template>
  <div class="cases_create">
    <div class="create_head" :class="{ top068: !isShowNews }">
      <h5 class="create_head_h5">{{ $t("cases.casesCreate.xjbl") }}</h5>
      <div class="create_head_ul" v-if="clinicMsg.pic || productMsg.pic">
        <p class="h_line"></p>
        <div class="h_single">
          <h5 class="h_single_h5">
            {{ $t("cases.cases.zs") }}：{{ clinicMsg.name }}
          </h5>
        </div>
        <p class="h_line"></p>
        <div class="h_single">
          <h5 class="h_single_h5">
            {{ $t("cases.cases.cp") }}：{{ productName }}
          </h5>
        </div>
      </div>
    </div>
    <div class="create_tabs" :class="{ top18: !isShowNews }">
      <ul class="tab_left">
        <li
          class="tab_single"
          :class="tabKey === '0' ? 'tab_active' : ''"
          @click="() => changeTabs('0')"
        >
          <span
            class="user_icon"
            :class="tabKey === '0' ? 'user_icon_active' : ''"
          ></span
          >{{ $t("cases.casesCreate.jbxx") }}
        </li>
        <li
          class="tab_single"
          :class="tabKey === '1' ? 'tab_active' : ''"
          @click="
            () => {
              changeTabs('1');
            }
          "
        >
          <span
            class="diagnosis_icon"
            :class="tabKey === '1' ? 'diagnosis_icon_active' : ''"
          ></span
          >{{ $t("cases.casesCreate.zdsm") }}
        </li>
        <li
          class="tab_single"
          :class="tabKey === '2' ? 'tab_active' : ''"
          @click="
            () => {
              changeTabs('2');
            }
          "
        >
          <span
            class="image_icon"
            :class="tabKey === '2' ? 'image_icon_active' : ''"
          ></span
          >{{ $t("cases.casesCreate.yxsm") }}
        </li>
        <li
          class="tab_single"
          :class="tabKey === '3' ? 'tab_active' : ''"
          @click="
            () => {
              changeTabs('3');
            }
          "
        >
          <span
            class="preview_icon"
            :class="tabKey === '3' ? 'preview_icon_active' : ''"
          ></span
          >{{ $t("cases.casesCreate.lltj") }}
        </li>
      </ul>
      <div class="tab_right">
        <div
          v-show="tabKey !== '0'"
          class="next_page flex-x-y-c curp mr10"
          :style="lang === 'en_US' ? 'width: 1.2rem' : ''"
          :class="tabKey !== '0' ? 'to_active' : ''"
          @click="
            () => {
              toStep('0');
            }
          "
        >
          <i class="el-icon-arrow-left mr4 mt2"></i>
          {{ $t("cases.casesCreate.syy") }}
        </div>
        <div
          v-if="tabKey !== '3'"
          class="next_page flex-x-y-c curp"
          :class="tabKey !== '3' ? 'to_active' : ''"
          @click="
            () => {
              toStep('1');
            }
          "
        >
          {{ $t("cases.casesCreate.xyy") }}
          <i class="el-icon-arrow-right ml4 mt2"></i>
        </div>
        <div
          v-if="isCurePlanSubmit"
          class="save_btn flex-x-y-c curp"
          @click="saveMsg"
        >
          {{
            tabKey !== "3"
              ? $t("cases.casesCreate.zc")
              : $t("common.message.tj")
          }}
        </div>
      </div>
      <div class="transverse_line"></div>
    </div>
    <div :class="{ mt28: !isShowNews }" class="create_con">
      <create-user
        ref="user"
        :productClear="prodcuct_clear"
        v-show="tabKey === '0'"
        :tabIndex="tabKey"
        @saveError="saveError"
        @getClinicMsg="getClinicMsg"
        @getProductMsg="getProductMsg"
      />
      <template v-if="tabKey === '1'">
        <create-diagnosis
          ref="diagnosis"
          @saveError="saveError"
          v-if="productType === 'A'"
        />
        <YouthTable
          ref="youthDiagnosis"
          v-else-if="['B', 'G', 'H'].includes(productType)"
        />
        <ChildrenTable
          ref="childrenDiagnosis"
          v-else-if="productType === 'C'"
        />
        <BraceRetaining ref="braceDiagnosis" v-else-if="productType === 'D'" />
        <VeneersTable ref="vennersDiagnosis" v-else-if="productType === 'E'" />
        <YysdTable ref="yysdDiagnosis" v-else-if="productType === 'F'" />
        <GapTable ref="gapDiagnosis" v-else-if="productType === 'I'" />
      </template>
      <create-iamge
        :caseDetail="commonCaseDetail.detail"
        v-if="tabKey === '2' && commonCaseDetail.state !== 'loading'"
        ref="image"
        @saveError="saveError"
      />
      <create-preview
        ref="preview"
        v-if="tabKey === '3'"
        :isSubmit="isSubmit"
        @resetSubStatus="resetSubStatus"
        @saveDiagnosis="saveDiagnosis"
        @submitCase="saveMsg"
        @oneJumpFour="oneJumpFour"
      />
    </div>
    <confirm-modal
      :confirmObj="{
        tipText: $t('cases.casesCreate.sftj'),
        backText: $t('casesDetail.casesChildren.common.qx'),
        okText: $t('cases.casesCreate.qdtj'),
        initText: productType === 'D' ? $t('cases.casesCreate.rntj') : ''
      }"
      @confirmSubmit="confirmSubmit()"
      @confirmHide="isShowConfirm = false"
      v-show="isShowConfirm"
    />
    <FullLoading v-show="isLoading" />
  </div>
</template>

<script>
import CreateUser from "./cases-create/create-user";
import CreateDiagnosis from "./cases-create/create-diagnosis";
import CreateIamge from "./cases-create/create-image";
import CreatePreview from "./cases-create/create-preview";
import FullLoading from "components/full-loading/full-loading";
import { mapState } from "vuex";
import YouthTable from "../../components/treatment-table/youth-table/index";
import ChildrenTable from "../../components/treatment-table/children-table/index";
import BraceRetaining from "../../components/treatment-table/brace-retaining-appliance/index";
import VeneersTable from "../../components/treatment-table/veneers-table/veneers-table.vue";
import YysdTable from "../../components/treatment-table/yysd-table/yysd-table.vue";
import GapTable from "../../components/treatment-table/gap-table/index.vue";
import { find, get, extend } from "lodash";
import { mapActions, mapGetters } from "vuex";
import ConfirmModal from "components/confirm-modal/confirm";

export default {
  data() {
    return {
      tabKey: "0", //每个组件的key
      isLoading: false,
      clinicMsg: {},
      productMsg: {},
      isSaveOk: true,
      timeout: null,
      isSubmit: false,
      lang: localStorage.getItem("user_lang") || "zh_CN",
      isCurePlanSubmit: true,
      details: {},
      isShowConfirm: false,
      prodcuct_clear: false,
      productType: ""
    };
  },
  computed: {
    ...mapState({
      isShowNews: state => state.isShowNews,
      language: state => state.language
    }),
    ...mapGetters({
      commonCaseDetail: "getCommonCaseDetail" //病例详情
    }),
    productName() {
      let list = this.$refs["user"] && this.$refs["user"].productList;
      const current = get(
        find(
          list,
          item =>
            item.id === this.$route.query.productId ||
            item.name === this.$route.query.productId
        ),
        "name"
      );
      return current;
    }
  },
  methods: {
    ...mapActions({
      getCommonCaseDetail: "actionGetCommonCaseDetail" //病例详情
    }),
    getCaseDetail() {
      let id = this.$route.query.caseId;
      if (id) {
        return this.getCommonCaseDetail(id)
          .then(data => {
            let route = this.$route;
            this.details = data;
            this.productType = data["cureType"];
            if (data.currentCurePlanId && !route.query.curePlanId) {
              this.$router.push({
                name: route.name,
                query: extend({}, route.query, {
                  curePlanId: data.currentCurePlanId,
                  productType: data["cureType"]
                })
              });
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    oneJumpFour(msg) {
      this.isCurePlanSubmit = msg;
    },
    getClinicMsg(msg) {
      this.clinicMsg = msg || {};
      sessionStorage.setItem("clinicMsg", msg || {});
    },
    getProductMsg(msg) {
      this.productMsg = msg || {};
      sessionStorage.setItem("productMsg", msg || {});
    },
    saveError() {
      this.isLoading = false;
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
    },
    confirmSubmit() {
      let dom = {
        0: this.$refs["user"],
        1: this.$refs[
          {
            A: "diagnosis",
            B: "youthDiagnosis",
            C: "childrenDiagnosis",
            D: "braceDiagnosis",
            E: "vennersDiagnosis",
            F: "yysdDiagnosis",
            G: "youthDiagnosis",
            H: "youthDiagnosis",
            I: "gapDiagnosis"
          }[this.productType]
        ],
        2: this.$refs["image"],
        3: this.$refs["preview"]
      }[this.tabKey];
      dom
        .saveData()
        .then(() => {
          this.isLoading = false;
          this.isShowConfirm = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    saveMsg() {
      if (this.tabKey === "3") {
        this.isShowConfirm = true;
      } else {
        this.confirmSubmit();
      }
    },
    resetSubStatus() {
      this.isSubmit = false;
    },
    toStep(type) {
      let dom = {
        0: this.$refs["user"],
        1: this.$refs[
          {
            A: "diagnosis",
            B: "youthDiagnosis",
            C: "childrenDiagnosis",
            D: "braceDiagnosis",
            E: "vennersDiagnosis",
            F: "yysdDiagnosis",
            G: "youthDiagnosis",
            H: "youthDiagnosis",
            I: "gapDiagnosis"
          }[this.productType]
        ],
        2: this.$refs["image"]
      }[this.tabKey];
      if (this.tabKey === "3") {
        this.getCaseDetail();
        this.isLoading = false;
        document.body.scrollTop = 0;
        let key = this.tabKey;
        if (type === "1") {
          // 下一页
          if (key < 3) {
            key++;
          }
        } else {
          if (key > 0) {
            key--;
          }
        }
        this.tabKey = key + "";
      }
      dom &&
        dom.isResponse < 1 &&
        dom
          .saveData()
          .then(() => {
            this.getCaseDetail();
            this.isLoading = false;
            document.body.scrollTop = 0;
            let key;
            key = this.tabKey;
            if (type === "1") {
              // 下一页
              if (key < 3) {
                key++;
              }
            } else {
              if (key > 0) {
                key--;
              }
            }
            this.tabKey = key + "";
          })
          .catch(() => {
            this.isLoading = false;
          });
    },
    changeTabs(key) {
      if (key === this.tabKey) return;
      this.isLoading = true;
      if (this.tabKey === "3") {
        this.tabKey = key.toString();
        this.isLoading = false;
        return (document.body.scrollTop = 0);
      }
      if (this.tabKey !== 0) {
        this.prodcuct_clear = false;
      }
      let dom = {
        0: this.$refs["user"],
        1: this.$refs[
          {
            A: "diagnosis",
            B: "youthDiagnosis",
            C: "childrenDiagnosis",
            D: "braceDiagnosis",
            E: "vennersDiagnosis",
            F: "yysdDiagnosis",
            G: "youthDiagnosis",
            H: "youthDiagnosis",
            I: "gapDiagnosis"
          }[this.productType]
        ],
        2: this.$refs["image"]
      }[this.tabKey];
      this.isLoading = false;
      dom &&
        dom.isResponse < 1 &&
        dom
          .saveData()
          .then(() => {
            this.tabKey = key.toString();
            this.isLoading = false;
            document.body.scrollTop = 0;
            this.getCaseDetail();
          })
          .catch(() => {
            this.isLoading = false;
          });
    },
    saveDiagnosis(key) {
      if (key === "clear") {
        this.prodcuct_clear = true;
        return;
      }
      this.tabKey = key;
    }
  },
  watch: {
    tabKey(val) {
      sessionStorage.setItem("casesTabKey", val);
    }
  },
  beforeDestroy() {
    sessionStorage.removeItem("needRTD");
    sessionStorage.removeItem("logisticsId");
  },
  components: {
    CreateUser,
    CreateDiagnosis,
    CreateIamge,
    CreatePreview,
    FullLoading,
    YouthTable,
    ChildrenTable,
    BraceRetaining,
    VeneersTable,
    ConfirmModal,
    YysdTable,
    GapTable
  },
  created() {
    document.title = this.$t("cases.casesCreate.title");
    this.tabKey =
      localStorage.getItem("casesTabKey") ||
      sessionStorage.getItem("casesTabKey") ||
      "0";
    sessionStorage.removeItem("diagnosisKey");
    sessionStorage.removeItem("product_clear");
    localStorage.removeItem("casesTabKey");

    sessionStorage.setItem("casesTabKey", this.tabKey);
    this.getCaseDetail();
  }
};
</script>

<style scoped lang="scss">
.cases_create {
  width: 13.44rem;
  margin: 0 auto 1rem;

  .create_head {
    width: 13.44rem;
    display: flex;
    align-items: center;
    padding-top: 53px;
    padding-bottom: 11px;
    position: fixed;
    top: 1.03rem;
    transition: top 0.3s;
    background-color: #efeff4;
    z-index: 1000;

    .create_head_h5 {
      color: $main_theme_color_333;
      font-size: 0.2rem;
      margin-right: 0.1rem;
      position: relative;
      top: -10px;
      white-space: nowrap;
    }

    .create_head_ul {
      display: flex;
      align-items: center;
      position: relative;
      top: -10px;

      .h_line {
        width: 0.01rem;
        height: 0.16rem;
        background-color: $main_theme_color_666;
      }

      .h_single {
        padding: 0 0.1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .h_single_pic {
          width: 0.76rem;
          height: 0.4rem;
          margin-bottom: 0.08rem;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }

        .h_single_h5 {
          max-width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 0.16rem;
          color: $main_theme_color_333;
        }
      }
    }
  }

  .create_tabs {
    width: 13.44rem;
    padding: 0 0.3rem 0 0.3rem;
    height: 0.72rem;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    background-color: #fff;
    position: fixed;
    top: 1.83rem;
    z-index: 1000;
    transition: top 0.3s;

    .tab_left {
      display: flex;
      align-items: center;
      height: 100%;

      .tab_single {
        border-bottom: 0.05rem solid transparent;
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 100%;
        font-size: 18px;
        margin-right: 60px;
        color: $main_theme_color_999;
        text-align: center;

        span {
          display: inline-block;
          width: 0.24rem;
          height: 0.24rem;
          margin-right: 0.04rem;
          background-size: 100% 100%;
          border-radius: 100%;
        }

        .user_icon {
          background-image: url("../../common/imgs/case/case_tabs_user.png");
        }

        .user_icon_active {
          background-image: url("../../common/imgs/case/case_tabs_user_y.png");
        }

        .diagnosis_icon {
          background-image: url("../../common/imgs/case/case_tabs_cure.png");
        }

        .diagnosis_icon_active {
          background-image: url("../../common/imgs/case/case_tabs_cure_y.png");
        }

        .image_icon {
          background-image: url("../../common/imgs/case/case_tabs_img.png");
        }

        .image_icon_active {
          background-image: url("../../common/imgs/case/case_tabs_img_y.png");
        }

        .preview_icon {
          background-image: url("../../common/imgs/case/case_tabs_preview.png");
        }

        .preview_icon_active {
          background-image: url("../../common/imgs/case/case_tabs_preview_y.png");
        }
      }

      .tab_active {
        color: $main_theme_color_333;
        border-color: $main_theme_color;
        z-index: 9999;
      }
    }

    .tab_right {
      display: flex;
      align-items: center;

      .next_page {
        width: 102px;
        height: 36px;
        background: #fcc80e;
        border-radius: 4px;
        color: $main_theme_color_333;
        font-size: 14px;
      }

      .save_btn {
        width: 96px;
        height: 36px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #bbbbbb;
        margin-left: 10px;
        color: $main_theme_color_333;
        font-size: 14px;
      }
    }
  }

  .transverse_line {
    width: 13.44rem;
    height: 0.06rem;
    background-color: #efeff4;
    /*background-color: pink;*/
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -6px;
    z-index: 99;
  }

  .create_con {
    margin-top: 2.6rem;
    transition: margin-top 0.3s;
  }
}

.top208 {
  top: 2.08rem !important;
}

.top068 {
  top: 0.67rem !important;
}

.top18 {
  top: 1.46rem !important;
}

.mt28 {
  margin-top: 2.27rem !important;
}
</style>
