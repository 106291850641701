<template>
  <div class="table_wrap">
    <div :style="{ height: rightHeight }" class="left_option">
      <div style="height: 6.3rem">
        <el-scrollbar ref="scrollbar" style="height: 100%">
          <template>
            <div
              v-for="(item, index) in leftList"
              class="left_option_item"
              :class="{ left_active: selectLeftKey === `d${index + 1}` }"
              @click="selectLeft(index, 'left')"
              :key="index"
            >
              <div
                v-show="selectLeftKey === `d${index + 1}`"
                class="left_wire"
              ></div>
              {{ item.title }}
            </div>
          </template>
        </el-scrollbar>
      </div>
    </div>
    <div ref="rightTable" class="right_table">
      <div class="dia_problem" v-if="problemList && problemList.length > 0">
        <div class="flex-y-c mb10">
          <img
            class="mr10"
            style="width: 24px"
            src="../../../common/imgs/case/dia_warn_cion.png"
            alt=""
          />
          <h5>{{ $t("cases.createImage.czrxwt") }}</h5>
        </div>
        <div class="pro_box curp">
          <span
            @click="selectLeft(+item.groupNo)"
            v-for="(item, index) in problemList"
            :key="`${item.id}_${index}`"
          >
            {{ item.groupNo }} {{ item.groupName }}
          </span>
        </div>
      </div>
      <div class="select_type" id="1">
        <div class="flex-y-c">
          <p>
            <span class="main_theme_color_red fz14 mr4">●</span>
          </p>
          <p class="select_type_title mr10">
            1.{{ $t("cases.createDiagnosis.txt1") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['1']">
          {{ diagnosisProblem["1"] }}
        </p>
        <div class="flex-y-c">
          <div
            :style="
              language === 'en_US'
                ? 'width: 1rem; text-align: left;margin-right:0'
                : ''
            "
            class="option_left_title"
          >
            {{ $t("cases.createDiagnosis.ycwt") }}
          </div>
          <div class="flex-y-c mt10">
            <div
              class="common_btn"
              @click="
                levelOneClick({
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'toothProblem',
                  },
                  radio: false,
                })
              "
              :class="
                values(
                  map(diagnosisParams.toothProblem.children, (it) => it.key)
                ).indexOf(item.key) !== -1
                  ? 'is_active'
                  : ''
              "
              v-for="(item, index) in caseOptions['tooth_problem']"
              :key="`${item.key}_${index}`"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div class="flex-y-c">
          <div
            :style="
              language === 'en_US'
                ? 'width: 1rem; text-align: left;margin-right:0'
                : ''
            "
            class="option_left_title"
          >
            {{ $t("cases.createDiagnosis.ggwt") }}
          </div>
          <div class="flex-y-c mt10">
            <div
              class="common_btn"
              @click="
                levelOneClick({
                  radio: false,
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'jawProblem',
                  },
                })
              "
              :class="{
                is_active: values(
                  map(diagnosisParams.jawProblem.children, (it) => it.key)
                ).includes(item.key),
              }"
              v-for="(item, index) in caseOptions['jaw_problem']"
              :key="`${item.key}_${index}`"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="remark_box"
          v-show="
            values(
              map(diagnosisParams.toothProblem.children, (it) => it.key)
            ).indexOf('other') !== -1
              ? 'is_active'
              : ''
          "
        >
          <el-input
            :disabled="isBackDisabled"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            class="tex_b_a"
            type="textarea"
            show-word-limit
            v-model="diagnosisParams.toothOtherProblem"
            :placeholder="$t('common.common.qsrnr')"
          ></el-input>
        </div>
      </div>
      <div class="select_type" id="2">
        <div class="flex-y-c">
          <p v-if="!needRTD">
            <span class="main_theme_color_red fz14 mr4">●</span>
          </p>
          <p class="select_type_title mr10">
            2.{{ $t("cases.createDiagnosis.zyjzmb") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['2']">
          {{ diagnosisProblem["2"] }}
        </p>
        <div style="display: flex">
          <div
            :style="
              language === 'en_US'
                ? 'width: 1.1rem; text-align: left;margin-right:0'
                : ''
            "
            class="option_left_title mt22"
          >
            {{ $t("cases.createDiagnosis.ycwt") }}
          </div>
          <div class="flex-y-c-w mt10 w100">
            <div
              class="common_btn"
              @click="
                levelOneClick({
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'toothProblemTwo',
                  },
                  radio: false,
                })
              "
              :class="{
                is_active: values(
                  map(diagnosisParams.toothProblemTwo.children, (it) => it.key)
                ).includes(item.key),
              }"
              v-for="(item, index) in caseOptions['tooth_problem_two']"
              :key="`${item.key}_${index}`"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div class="flex-y-c">
          <div
            :style="
              language === 'en_US'
                ? 'width: 1rem; text-align: left;margin-right:0'
                : ''
            "
            class="option_left_title"
          >
            {{ $t("cases.createDiagnosis.ggwt") }}
          </div>
          <div class="flex-y-c-w mt10">
            <div
              class="common_btn"
              @click="
                levelOneClick({
                  ev: item,
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'jawProblemTwo',
                  },
                })
              "
              :class="{
                is_active: item.key === diagnosisParams.jawProblemTwo.key,
              }"
              v-for="(item, index) in caseOptions['jaw_problem_two']"
              :key="`${item.key}_${index}`"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="remark_box"
          v-show="
            values(
              map(diagnosisParams.toothProblemTwo.children, (it) => it.key)
            ).includes('other')
          "
        >
          <el-input
            :disabled="isBackDisabled"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            class="tex_b_a"
            type="textarea"
            show-word-limit
            v-model="diagnosisParams.toothOtherProblemTwo"
            :placeholder="$t('common.common.qsrnr')"
          ></el-input>
        </div>
      </div>
      <div class="select_type" id="3">
        <div class="flex-y-c">
          <p v-show="!needRTD">
            <span class="main_theme_color_red fz14 mr4">●</span>
          </p>
          <p class="select_type_title mr10">
            3.{{ $t("cases.createDiagnosis.txt2") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['3']">
          {{ diagnosisProblem["3"] }}
        </p>
        <div class="flex-y-c">
          <div class="flex-y-c">
            <span v-show="!needRTD" class="main_theme_color_red fz14 mr4"
              >●</span
            >
            <div
              :style="
                language === 'en_US'
                  ? 'width: 1.48rem; text-align: left;margin-right:0'
                  : 'width: 0.76rem;'
              "
              class="option_left_title"
            >
              {{ $t("cases.createDiagnosis.asfl") }}
            </div>
          </div>
          <div :class="{ ml14: needRTD }" class="flex-y-c mt10">
            <div
              class="common_btn"
              @click="
                levelOneClick({
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'angleClassification',
                  },
                })
              "
              :class="{
                is_active: item.key === diagnosisParams.angleClassification.key,
              }"
              v-for="(item, index) in caseOptions['angle_classification']"
              :key="index"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div class="flex-y-c">
          <div
            :style="
              language === 'en_US'
                ? 'width: 1.62rem; text-align: left;margin-right:0'
                : 'width: 0.9rem;'
            "
            class="option_left_title"
          >
            <span
              :style="{ position: 'relative', left: needRTD ? '0' : '0.12rem' }"
              >{{ $t("cases.createDiagnosis.gxfl") }}</span
            >
          </div>
          <div class="flex-y-c mt10">
            <div
              class="common_btn"
              @click="
                levelOneClick({
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'sagittalMaxillomandibularClassification',
                  },
                })
              "
              :class="{
                is_active:
                  item.key ===
                  diagnosisParams.sagittalMaxillomandibularClassification.key,
              }"
              v-for="(item, index) in caseOptions[
                'sagittal_maxillomandibular_classification'
              ]"
              :key="`${item.key}_${index}`"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div style="display: flex" class="mt10">
          <div
            :style="language === 'en_US' ? 'width: 1.62rem;' : 'width: 0.9rem;'"
            style="position: relative; top: 0.03rem"
            class="option_left_title mt8"
          >
            <span v-show="!needRTD" class="main_theme_color_red fz14 mr4"
              >●</span
            >
            <span>{{ $t("cases.createDiagnosis.chlx") }}</span>
          </div>
          <div style="flex: 1" class="flex-y-c-w">
            <div
              class="common_btn"
              @click="
                levelOneClick({
                  radio: false,
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'clinicInfo',
                  },
                })
              "
              :class="{
                is_active: values(
                  map(diagnosisParams.clinicInfo.children, (it) => it.key)
                ).includes(item.key),
              }"
              v-for="(item, index) in caseOptions['clinic_info']"
              :key="index"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="remark_box"
          v-show="
            values(
              map(diagnosisParams.clinicInfo.children, (it) => it.key)
            ).includes('other')
          "
        >
          <el-input
            :disabled="isBackDisabled"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            class="tex_b_a"
            type="textarea"
            show-word-limit
            v-model="diagnosisParams.clinicOtherInfo"
            :placeholder="$t('common.common.qsrnr')"
          ></el-input>
        </div>
      </div>

      <div class="select_type" id="4">
        <div class="flex-y-c mb10">
          <p>
            <span class="main_theme_color_red fz14 mr4">●</span>
          </p>
          <p class="select_type_title mr10">
            4.{{ $t("cases.createDiagnosis.txt3") }}
          </p>
          <span class="main_theme_color_999 fz14 ml10">{{
            $t("cases.createDiagnosis.jsdgjz")
          }}</span>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[4]">
          {{ diagnosisProblem[4] }}
        </p>
        <div class="flex-y-c">
          <div
            v-for="(item, index) in caseOptions['to_correct_tooth_jaw']"
            @click="
              levelOneClick({
                noClear: true,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'toCorrectToothJaw',
                },
              })
            "
            :key="index"
          >
            <el-popover
              :disabled="isBackDisabled"
              placement="bottom"
              :popper-options="{
                boundariesElement: 'body',
              }"
              trigger="click"
              v-if="item.key !== 'whole_jaw'"
            >
              <div class="flex-y-c">
                <div
                  class="common_btn"
                  :class="{
                    is_active:
                      diagnosisParams['toCorrectToothJaw'].children &&
                      diagnosisParams['toCorrectToothJaw'].children[0].key ===
                        it.key,
                  }"
                  @click="
                    levelTowClick({
                      wrapFiled: 'toCorrectToothJaw',
                      addItem: {
                        key: it.key,
                        submitKey: {
                          upjaw: 'toCorrectToothUpjaw',
                          downjaw: 'toCorrectToothDownjaw',
                        }[item.key],
                        value: diaKeyObj[it.key],
                      },
                      radio: true,
                    })
                  "
                  v-for="(it, idx) in diagnosisParams.toCorrectToothJaw.key ===
                  'upjaw'
                    ? caseOptions['to_correct_tooth_upjaw']
                    : caseOptions['to_correct_tooth_downjaw']"
                  :key="idx"
                >
                  {{ it.value }}
                </div>
              </div>
              <div
                class="common_btn"
                :class="{
                  is_active: diagnosisParams.toCorrectToothJaw.key === item.key,
                }"
                slot="reference"
                :data-key="item.key"
              >
                {{ item.value }}
              </div>
            </el-popover>
            <div
              class="common_btn"
              :class="{
                is_active: diagnosisParams.toCorrectToothJaw.key === item.key,
              }"
              v-else
              :data-key="item.key"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="single_tip_box"
          v-show="get(diagnosisParams.toCorrectToothJaw, 'children[0].value')"
        >
          <p>
            {{ get(diagnosisParams.toCorrectToothJaw, "children[0].value") }}
          </p>
        </div>
      </div>
      <div class="select_type" id="5">
        <div class="flex-y-c mb2rem">
          <p class="select_type_title mr10">
            5.
            {{ $t("cases.createDiagnosis.txt4") }}
          </p>
          <p>
            <span class="main_theme_color_999 fz14">{{
              $t("cases.createDiagnosis.txt4ms")
            }}</span>
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[5]">
          {{ diagnosisProblem[5] }}
        </p>
        <ChildrenTeeth
          :commonTeeth="commonTeeth"
          :patientType="'adult'"
          :readOnly="isBackDisabled"
          @updateTeeth="updateTeeth"
          class="mb40 mt40"
          :teethType="1"
        />
      </div>
      <div class="select_type" id="6">
        <div class="select_type_title mb2rem">
          6.
          {{ $t("cases.createDiagnosis.txt5") }}
          <span class="main_theme_color_999 fz14">{{
            $t("cases.createDiagnosis.kcydbkyd")
          }}</span>
          <p class="problem_note mt2rem" v-if="diagnosisProblem[6]">
            {{ diagnosisProblem[6] }}
          </p>

          <div class="mt10 flex-y-c">
            <div
              class="common_btn"
              :class="{
                is_active: item.key === diagnosisParams.nonMoveableTeeth.key,
              }"
              @click="
                levelOneClick({
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'nonMoveableTeeth',
                  },
                })
              "
              v-for="(item, index) in caseOptions['non_moveable_teeth']"
              :key="index"
            >
              {{ item.value }}
            </div>
          </div>
          <ChildrenTeeth
            :commonTeeth="commonTeeth"
            ref="teethComponents"
            :patientType="'adult'"
            v-show="diagnosisParams.nonMoveableTeeth.key === 'true'"
            :readOnly="isBackDisabled"
            @updateTeeth="updateTeeth"
            class="mb40 mt40"
            :teethType="2"
          />
        </div>
      </div>
      <div class="select_type" id="7">
        <div class="select_type_title mb2rem">
          7.
          {{ $t("cases.createDiagnosis.txt6") }}
          <span class="main_theme_color_999 fz14">{{
            $t("cases.createDiagnosis.kcyfj")
          }}</span>
          <p class="problem_note mt2rem" v-if="diagnosisProblem[7]">
            {{ diagnosisProblem[7] }}
          </p>

          <div class="mt1rem flex-y-c">
            <div
              class="common_btn"
              :class="{
                is_active:
                  item.key === diagnosisParams.notAvailableAttachments.key,
              }"
              @click="
                levelOneClick({
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'notAvailableAttachments',
                  },
                })
              "
              v-for="(item, index) in caseOptions[
                'not_available_for_attachments'
              ]"
              :key="index"
            >
              {{ item.value }}
            </div>
          </div>
          <ChildrenTeeth
            :commonTeeth="commonTeeth"
            ref="teethComponents"
            :readOnly="isBackDisabled"
            v-show="diagnosisParams.notAvailableAttachments.key === 'true'"
            @updateTeeth="updateTeeth"
            class="mb40 mt40"
            :teethType="3"
          />
        </div>
      </div>
      <div class="select_type" id="8">
        <div class="flex-y-c">
          <p v-show="!needRTD">
            <span class="main_theme_color_red fz14 mr4">●</span>
          </p>
          <p class="select_type_title mr10">
            8.{{ $t("cases.createDiagnosis.txt7") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['8']">
          {{ diagnosisProblem["8"] }}
        </p>
        <div class="flex-y-c">
          <span
            :style="
              language === 'en_US'
                ? 'width: 0.4rem; text-align: left;margin-right:0'
                : ''
            "
            class="fz14 main_theme_color_333 mr10"
            >{{ $t("cases.createDiagnosis.you") }}</span
          >
          <div
            class="mt10"
            v-for="(item, index) in caseOptions['right_sagittal_relation']"
            :key="index"
            @click="
              levelOneClick({
                noClear: true,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'rightSagittalRelation',
                },
              })
            "
          >
            <el-popover
              :disabled="isBackDisabled"
              placement="bottom-start"
              :offset="
                language === 'en_US' && item.key === 'improve_to_middle'
                  ? -500
                  : -200
              "
              :popper-options="{
                boundariesElement: 'body',
              }"
              trigger="click"
              v-if="
                [
                  'improve_tip',
                  'improve_tip_grind',
                  'improve_to_middle',
                ].includes(item.key)
              "
            >
              <div class="popover_con">
                <div style="flex-direction: column">
                  <div class="flex-y-c">
                    <div
                      class="common_btn"
                      :class="{
                        is_active: map(
                          diagnosisParams.rightSagittalRelation['children'],
                          (dia) => dia.key
                        ).includes(it.key),
                      }"
                      @click.stop="
                        levelTowClick({
                          oneOfMore: {
                            one: 'after_design',
                            more: [
                              'after_design',
                              'tooth_extraction',
                              'bit_jump',
                              'far_grind',
                              'side_de_glaze',
                            ],
                          },
                          addItem: {
                            key: it.key,
                            submitKey: 'rightSagittalRelationNote',
                            value: diaKeyObj[it.key],
                          },
                          wrapFiled: 'rightSagittalRelation',
                        })
                      "
                      v-for="(it, itx) in sagittalRelationshipOption"
                      :key="itx"
                    >
                      {{ it.value }}
                    </div>
                    <p
                      class="ml20 mb10 c-white fz14"
                      :class="{
                        'c-red': map(
                          diagnosisParams.rightSagittalRelation['children'],
                          (a) => a.key
                        ).includes('far_grind'),
                      }"
                    >
                      {{ $t("cases.createDiagnosis.rxztip") }}
                    </p>
                  </div>
                  <div
                    style="margin-left: 1rem; margin-top: -0.3rem"
                    v-show="
                      map(
                        diagnosisParams.rightSagittalRelation['children'],
                        (a) => a.key
                      ).includes('far_grind')
                    "
                    class="popover_db"
                  >
                    <div class="popover_db_left"></div>
                    <div class="popover_db_right">
                      <div
                        class="common_btn"
                        :class="{
                          is_active: values(
                            map(
                              diagnosisParams.rightSagittalRelationFar.children,
                              (it) => it.key
                            )
                          ).includes(singleItem.key),
                        }"
                        @click.stop="
                          levelOneClick({
                            radio: false,
                            addItem: {
                              submitKey: 'rightSagittalRelationFar',
                              value: singleItem.value,
                              key: singleItem.key,
                            },
                          })
                        "
                        v-for="(singleItem, index) in caseOptions[
                          'precision_cut'
                        ]"
                        :key="index"
                      >
                        {{ singleItem.value }}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="flex-y-c">
                      <div
                        class="common_btn"
                        :class="{
                          is_active: map(
                            diagnosisParams.rightSagittalRelation['children'],
                            (dia) => dia.key
                          ).includes(it.key),
                        }"
                        @click.stop="
                          levelTowClick({
                            oneOfMore: {
                              one: 'after_design',
                              more: [
                                'after_design',
                                'tooth_extraction',
                                'bit_jump',
                                'far_grind',
                                'side_de_glaze',
                              ],
                            },
                            addItem: {
                              key: it.key,
                              submitKey: 'rightSagittalRelationNote',
                              value: diaKeyObj[it.key],
                            },
                            wrapFiled: 'rightSagittalRelation',
                          })
                        "
                        v-for="(it, itx) in sagittalRelationshipOptionTow"
                        :key="itx"
                      >
                        {{ it.value }}
                      </div>
                    </div>
                    <div
                      style="margin-left: -1rem; margin-top: -0.3rem"
                      v-show="
                        map(
                          diagnosisParams.rightSagittalRelation['children'],
                          (a) => a.key
                        ).includes('bit_jump')
                      "
                      class="popover_db"
                    >
                      <div class="popover_db_left"></div>
                      <div class="popover_db_right">
                        <div
                          class="common_btn"
                          :class="{
                            is_active: values(
                              map(
                                diagnosisParams.rightSagittalRelationBitJump
                                  .children,
                                (it) => it.key
                              )
                            ).includes(singleItem.key),
                          }"
                          @click.stop="
                            levelOneClick({
                              radio: false,
                              addItem: {
                                submitKey: 'rightSagittalRelationBitJump',
                                value: singleItem.value,
                                key: singleItem.key,
                              },
                            })
                          "
                          v-for="(singleItem, index) in caseOptions[
                            'precision_cut'
                          ]"
                          :key="index"
                        >
                          {{ singleItem.value }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="flex-y-c">
                      <div
                        class="common_btn"
                        :class="{
                          is_active: map(
                            diagnosisParams.rightSagittalRelation['children'],
                            (dia) => dia.key
                          ).includes(it.key),
                        }"
                        @click.stop="
                          levelTowClick({
                            oneOfMore: {
                              one: 'after_design',
                              more: [
                                'after_design',
                                'tooth_extraction',
                                'bit_jump',
                                'far_grind',
                                'side_de_glaze',
                              ],
                            },
                            addItem: {
                              key: it.key,
                              submitKey: 'rightSagittalRelationNote',
                              value: diaKeyObj[it.key],
                            },
                            wrapFiled: 'rightSagittalRelation',
                          })
                        "
                        v-for="(it, itx) in sagittalRelationshipOptionThree"
                        :key="itx"
                      >
                        {{ it.value }}
                      </div>
                    </div>
                    <div
                      :style="
                        language === 'en_US'
                          ? 'margin-left: -0.6rem'
                          : 'margin-left: -2rem;'
                      "
                      style="margin-top: -0.3rem"
                      v-show="
                        map(
                          diagnosisParams.rightSagittalRelation['children'],
                          (a) => a.key
                        ).includes('tooth_extraction')
                      "
                      class="popover_db"
                    >
                      <div class="popover_db_left"></div>
                      <div class="popover_db_right">
                        <div
                          class="common_btn"
                          :class="{
                            is_active: values(
                              map(
                                diagnosisParams.rightSagittalRelationExtraction
                                  .children,
                                (it) => it.key
                              )
                            ).includes(singleItem.key),
                          }"
                          @click="
                            levelOneClick({
                              oneOfMore: {
                                one: 'tooth_extraction_nono',
                                more: ['inside_jaw', 'between_jaw'],
                              },
                              radio: false,
                              addItem: {
                                submitKey: 'rightSagittalRelationExtraction',
                                value: singleItem.value,
                                key: singleItem.key,
                              },
                            })
                          "
                          v-for="(singleItem, singleItemIndex) in caseOptions[
                            'tooth_extraction'
                          ]"
                          :key="singleItem.key + singleItemIndex"
                        >
                          {{ singleItem.value }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-y-c">
                    <div
                      class="common_btn"
                      :class="{
                        is_active: map(
                          diagnosisParams.rightSagittalRelation['children'],
                          (dia) => dia.key
                        ).includes(it.key),
                      }"
                      @click.stop="
                        levelTowClick({
                          oneOfMore: {
                            one: 'after_design',
                            more: [
                              'after_design',
                              'tooth_extraction',
                              'bit_jump',
                              'far_grind',
                              'side_de_glaze',
                            ],
                          },
                          addItem: {
                            key: it.key,
                            submitKey: 'rightSagittalRelationNote',
                            value: diaKeyObj[it.key],
                          },
                          wrapFiled: 'rightSagittalRelation',
                        })
                      "
                      v-for="(it, itx) in sagittalRelationshipOptionFour"
                      :key="itx"
                    >
                      {{ it.value }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="common_btn"
                :class="{
                  is_active:
                    item.key === diagnosisParams.rightSagittalRelation.key,
                }"
                slot="reference"
              >
                {{ item.value }}
              </div>
            </el-popover>
            <div
              v-else
              class="common_btn"
              :class="{
                is_active:
                  item.key === diagnosisParams.rightSagittalRelation.key,
              }"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="single_tip_box"
          v-show="
            get(diagnosisParams.rightSagittalRelation, 'children[0].value')
          "
        >
          <p
            :key="nox"
            v-for="(note, nox) in diagnosisParams['rightSagittalRelation']
              .children"
          >
            {{ note.value }}
            <span
              v-if="
                get(diagnosisParams['rightSagittalRelationFar'], 'children') &&
                get(diagnosisParams['rightSagittalRelationFar'], 'children')
                  .length &&
                note.key === 'far_grind'
              "
            >
              {{
                "- " +
                map(
                  diagnosisParams["rightSagittalRelationFar"]["children"],
                  (a) => a.value
                ).join()
              }}
            </span>
            <span
              v-if="
                get(diagnosisParams['rightSagittalRelationFar'], 'children') &&
                get(diagnosisParams['rightSagittalRelationBitJump'], 'children')
                  .length &&
                note.key === 'bit_jump'
              "
            >
              {{
                "- " +
                map(
                  diagnosisParams["rightSagittalRelationBitJump"]["children"],
                  (a) => a.value
                ).join()
              }}
            </span>
            <span
              v-if="
                get(diagnosisParams['rightSagittalRelationFar'], 'children') &&
                get(
                  diagnosisParams['rightSagittalRelationExtraction'],
                  'children'
                ).length &&
                note.key === 'tooth_extraction'
              "
            >
              {{
                "- " +
                map(
                  diagnosisParams["rightSagittalRelationExtraction"][
                    "children"
                  ],
                  (a) => a.value
                ).join("、")
              }}
            </span>
            <span
              v-if="note.key === 'far_grind'"
              :class="{ 'c-red': note.key === 'far_grind' }"
            >
              {{ $t("cases.createDiagnosis.yjdjzzyymy") }}
            </span>
          </p>
        </div>
        <div class="flex-y-c">
          <span
            :style="
              language === 'en_US'
                ? 'width: 0.4rem; text-align: left;margin-right:0'
                : ''
            "
            class="fz14 main_theme_color_333 mr10"
            >{{ $t("cases.createDiagnosis.zuo") }}</span
          >
          <div
            class="mt10"
            v-for="(item, index) in caseOptions['left_sagittal_relation']"
            :key="index"
            @click="
              levelOneClick({
                noClear: true,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'leftSagittalRelation',
                },
              })
            "
          >
            <el-popover
              placement="bottom-start"
              :disabled="isBackDisabled"
              :offset="
                language === 'en_US' && item.key === 'improve_to_middle'
                  ? -500
                  : -200
              "
              :popper-options="{
                boundariesElement: 'body',
              }"
              trigger="click"
              v-if="
                [
                  'improve_tip',
                  'improve_tip_grind',
                  'improve_to_middle',
                ].includes(item.key)
              "
            >
              <div class="popover_con">
                <div style="flex-direction: column">
                  <div>
                    <div class="flex-y-c">
                      <div
                        class="common_btn"
                        :class="{
                          is_active: map(
                            diagnosisParams.leftSagittalRelation['children'],
                            (dia) => dia.key
                          ).includes(it.key),
                        }"
                        @click.stop="
                          levelTowClick({
                            oneOfMore: {
                              one: 'after_design',
                              more: [
                                'after_design',
                                'tooth_extraction',
                                'bit_jump',
                                'far_grind',
                                'side_de_glaze',
                              ],
                            },
                            addItem: {
                              key: it.key,
                              submitKey: 'leftSagittalRelationNote',
                              value: diaKeyObj[it.key],
                            },
                            wrapFiled: 'leftSagittalRelation',
                          })
                        "
                        v-for="(it, itx) in sagittalRelationshipOption"
                        :key="itx"
                      >
                        {{ it.value }}
                      </div>
                      <p
                        class="ml20 mb10"
                        :class="{
                          'c-red': map(
                            diagnosisParams.leftSagittalRelation['children'],
                            (a) => a.key
                          ).includes('far_grind'),
                        }"
                      >
                        {{ $t("cases.createDiagnosis.rxztip") }}
                      </p>
                    </div>
                    <div
                      style="margin-left: 1rem; margin-top: -0.3rem"
                      v-show="
                        map(
                          diagnosisParams.leftSagittalRelation['children'],
                          (a) => a.key
                        ).includes('far_grind')
                      "
                      class="popover_db"
                    >
                      <div class="popover_db_left"></div>
                      <div class="popover_db_right">
                        <div
                          class="common_btn"
                          :class="{
                            is_active: values(
                              map(
                                diagnosisParams.leftSagittalRelationFar
                                  .children,
                                (it) => it.key
                              )
                            ).includes(singleItem.key),
                          }"
                          @click.stop="
                            levelOneClick({
                              radio: false,
                              addItem: {
                                submitKey: 'leftSagittalRelationFar',
                                value: singleItem.value,
                                key: singleItem.key,
                              },
                            })
                          "
                          v-for="(singleItem, singleItemIndex) in caseOptions[
                            'precision_cut'
                          ]"
                          :key="singleItemIndex"
                        >
                          {{ singleItem.value }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="flex-y-c">
                      <div
                        class="common_btn"
                        :class="{
                          is_active: map(
                            diagnosisParams.leftSagittalRelation['children'],
                            (dia) => dia.key
                          ).includes(it.key),
                        }"
                        @click.stop="
                          levelTowClick({
                            oneOfMore: {
                              one: 'after_design',
                              more: [
                                'after_design',
                                'tooth_extraction',
                                'bit_jump',
                                'far_grind',
                                'side_de_glaze',
                              ],
                            },
                            addItem: {
                              key: it.key,
                              submitKey: 'leftSagittalRelationNote',
                              value: diaKeyObj[it.key],
                            },
                            wrapFiled: 'leftSagittalRelation',
                          })
                        "
                        v-for="(it, itx) in sagittalRelationshipOptionTow"
                        :key="itx"
                      >
                        {{ it.value }}
                      </div>
                    </div>
                    <div
                      style="margin-left: -1rem; margin-top: -0.3rem"
                      v-show="
                        map(
                          diagnosisParams.leftSagittalRelation['children'],
                          (a) => a.key
                        ).includes('bit_jump')
                      "
                      class="popover_db"
                    >
                      <div class="popover_db_left"></div>
                      <div class="popover_db_right">
                        <div
                          class="common_btn"
                          :class="{
                            is_active: values(
                              map(
                                diagnosisParams.leftSagittalRelationBitJump
                                  .children,
                                (it) => it.key
                              )
                            ).includes(singleItem.key),
                          }"
                          @click.stop="
                            levelOneClick({
                              radio: false,
                              addItem: {
                                submitKey: 'leftSagittalRelationBitJump',
                                value: singleItem.value,
                                key: singleItem.key,
                              },
                            })
                          "
                          v-for="(singleItem, singleItemIndex) in caseOptions[
                            'precision_cut'
                          ]"
                          :key="singleItemIndex"
                        >
                          {{ singleItem.value }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="flex-y-c">
                      <div
                        class="common_btn"
                        :class="{
                          is_active: map(
                            diagnosisParams.leftSagittalRelation['children'],
                            (dia) => dia.key
                          ).includes(it.key),
                        }"
                        @click.stop="
                          levelTowClick({
                            oneOfMore: {
                              one: 'after_design',
                              more: [
                                'after_design',
                                'tooth_extraction',
                                'bit_jump',
                                'far_grind',
                                'side_de_glaze',
                              ],
                            },
                            addItem: {
                              key: it.key,
                              submitKey: 'leftSagittalRelationNote',
                              value: diaKeyObj[it.key],
                            },
                            wrapFiled: 'leftSagittalRelation',
                          })
                        "
                        v-for="(it, itx) in sagittalRelationshipOptionThree"
                        :key="itx"
                      >
                        {{ it.value }}
                      </div>
                    </div>
                    <div
                      :style="
                        language === 'en_US'
                          ? 'margin-left: -0.6rem'
                          : 'margin-left: -2rem;'
                      "
                      style="margin-top: -0.3rem"
                      v-show="
                        map(
                          diagnosisParams.leftSagittalRelation['children'],
                          (a) => a.key
                        ).includes('tooth_extraction')
                      "
                      class="popover_db"
                    >
                      <div class="popover_db_left"></div>
                      <div class="popover_db_right">
                        <div
                          class="common_btn"
                          :class="{
                            is_active: values(
                              map(
                                diagnosisParams.leftSagittalRelationExtraction
                                  .children,
                                (it) => it.key
                              )
                            ).includes(singleItem.key),
                          }"
                          @click.stop="
                            levelOneClick({
                              oneOfMore: {
                                one: 'tooth_extraction_nono',
                                more: ['inside_jaw', 'between_jaw'],
                              },
                              radio: false,
                              addItem: {
                                submitKey: 'leftSagittalRelationExtraction',
                                value: singleItem.value,
                                key: singleItem.key,
                              },
                            })
                          "
                          v-for="(singleItem, singleItemIndex) in caseOptions[
                            'tooth_extraction'
                          ]"
                          :key="singleItemIndex"
                        >
                          {{ singleItem.value }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-y-c">
                    <div
                      class="common_btn"
                      :class="{
                        is_active: map(
                          diagnosisParams.leftSagittalRelation['children'],
                          (dia) => dia.key
                        ).includes(it.key),
                      }"
                      @click.stop="
                        levelTowClick({
                          oneOfMore: {
                            one: 'after_design',
                            more: [
                              'after_design',
                              'tooth_extraction',
                              'bit_jump',
                              'far_grind',
                              'side_de_glaze',
                            ],
                          },
                          addItem: {
                            key: it.key,
                            submitKey: 'leftSagittalRelationNote',
                            value: diaKeyObj[it.key],
                          },
                          wrapFiled: 'leftSagittalRelation',
                        })
                      "
                      v-for="(it, itx) in sagittalRelationshipOptionFour"
                      :key="itx"
                    >
                      {{ it.value }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="common_btn"
                :class="{
                  is_active:
                    item.key === diagnosisParams.leftSagittalRelation.key,
                }"
                slot="reference"
              >
                {{ item.value }}
              </div>
            </el-popover>
            <div
              v-else
              class="common_btn"
              :class="{
                is_active:
                  item.key === diagnosisParams.leftSagittalRelation.key,
              }"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="single_tip_box"
          v-show="
            get(diagnosisParams.leftSagittalRelation, 'children[0].value')
          "
        >
          <p
            :key="nox"
            v-for="(note, nox) in diagnosisParams['leftSagittalRelation']
              .children"
          >
            {{ note.value }}
            <span
              v-if="
                get(diagnosisParams['leftSagittalRelationFar'], 'children') &&
                get(diagnosisParams['leftSagittalRelationFar'], 'children')
                  .length &&
                note.key === 'far_grind'
              "
            >
              {{
                "- " +
                map(
                  diagnosisParams["leftSagittalRelationFar"]["children"],
                  (a) => a.value
                ).join()
              }}
            </span>
            <span
              v-if="
                get(diagnosisParams['leftSagittalRelationFar'], 'children') &&
                get(diagnosisParams['leftSagittalRelationBitJump'], 'children')
                  .length &&
                note.key === 'bit_jump'
              "
            >
              {{
                "- " +
                map(
                  diagnosisParams["leftSagittalRelationBitJump"]["children"],
                  (a) => a.value
                ).join()
              }}
            </span>
            <span
              v-if="
                get(diagnosisParams['leftSagittalRelationFar'], 'children') &&
                get(
                  diagnosisParams['leftSagittalRelationExtraction'],
                  'children'
                ).length &&
                note.key === 'tooth_extraction'
              "
            >
              {{
                "- " +
                map(
                  diagnosisParams["leftSagittalRelationExtraction"]["children"],
                  (a) => a.value
                ).join("、")
              }}
            </span>
            <span
              v-if="note.key === 'far_grind'"
              :class="{ 'c-red': note.key === 'far_grind' }"
            >
              {{ $t("cases.createDiagnosis.yjdjzzyymy") }}
            </span>
          </p>
        </div>
      </div>
      <div class="select_type" id="9">
        <p class="select_type_title mb10">
          9.{{ $t("cases.createDiagnosis.txt8") }}
        </p>
        <p class="problem_note" v-if="diagnosisProblem['9']">
          {{ diagnosisProblem["9"] }}
        </p>

        <div class="flex-y-c">
          <div
            class="common_btn"
            @click="
              levelOneClick({
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'coverInfo',
                },
              })
            "
            :class="{ is_active: item.key === diagnosisParams.coverInfo.key }"
            v-for="(item, index) in caseOptions['cover_info']"
            :key="index"
          >
            {{ item.value }}
          </div>
        </div>
      </div>
      <div class="select_type" id="10">
        <p class="select_type_title mb10">
          10.{{ $t("cases.createDiagnosis.txt9") }}
        </p>
        <p class="problem_note" v-if="diagnosisProblem['10']">
          {{ diagnosisProblem["10"] }}
        </p>
        <div class="flex-y-c">
          <div
            @click="
              levelOneClick({
                noClear: true,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'overbiteInfo',
                },
              })
            "
            v-for="(item, index) in caseOptions['overbite_info']"
            :key="index"
          >
            <el-popover
              placement="bottom-start"
              :disabled="isBackDisabled"
              trigger="click"
              :popper-options="{
                boundariesElement: 'body',
              }"
              :offset="-200"
              v-if="item.key === 'correct_open'"
            >
              <div class="popover_con">
                <div style="flex-direction: column">
                  <div class="flex-y-c">
                    <div
                      class="common_btn"
                      :class="{
                        is_active: map(
                          diagnosisParams.overbiteInfo['children'],
                          (dia) => dia.key
                        ).includes(it.key),
                      }"
                      @click.stop="
                        levelTowClick({
                          multipleRadio: ['other'],
                          multipleRadios: [
                            'press_back_tooth',
                            'extend_front_press_back',
                            'extend_front_tooth',
                          ],
                          addItem: {
                            key: it.key,
                            submitKey: 'overbiteInfoOpen',
                            value: it.value,
                          },
                          wrapFiled: 'overbiteInfo',
                        })
                      "
                      v-for="(it, itx) in caseOptions['correct_open']"
                      :key="itx"
                    >
                      {{ it.value }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="common_btn"
                :class="{
                  is_active: item.key === diagnosisParams.overbiteInfo.key,
                }"
                slot="reference"
              >
                {{ item.value }}
              </div>
            </el-popover>
            <el-popover
              placement="bottom-start"
              trigger="click"
              :disabled="isBackDisabled"
              :offset="-200"
              :popper-options="{
                boundariesElement: 'body',
              }"
              v-else-if="item.key === 'correct_deep_cover'"
            >
              <div class="popover_con">
                <div style="flex-direction: column">
                  <div class="flex-y-c">
                    <div
                      class="common_btn"
                      :class="{
                        is_active: map(
                          diagnosisParams.overbiteInfo['children'],
                          (dia) => dia.key
                        ).includes(it.key),
                      }"
                      @click.stop="
                        levelTowClick({
                          multipleRadio: ['other'],
                          multipleRadios: [
                            'press_front_rise_back',
                            'press_front',
                          ],
                          addItem: {
                            key: it.key,
                            submitKey: 'overbiteInfoDeep',
                            value: it.value,
                          },
                          wrapFiled: 'overbiteInfo',
                        })
                      "
                      v-for="(it, itx) in caseOptions['correct_deep_cover']"
                      :key="itx"
                    >
                      {{ it.value }}
                    </div>
                  </div>
                  <div
                    style="margin-left: -2.4rem; margin-top: -0.3rem"
                    v-show="
                      map(
                        diagnosisParams.overbiteInfo['children'],
                        (dia) => dia.key
                      ).includes('press_front')
                    "
                    class="popover_db"
                  >
                    <div class="popover_db_left"></div>
                    <div class="popover_db_right">
                      <div
                        class="common_btn"
                        :class="{
                          is_active: map(
                            find(
                              diagnosisParams.overbiteInfo['children'],
                              (dia) => dia.key === 'press_front'
                            ) &&
                              find(
                                diagnosisParams.overbiteInfo['children'],
                                (dia) => dia.key === 'press_front'
                              )['children'],
                            (diaC) => diaC.key
                          ).includes(singleItem.key),
                        }"
                        @click.stop="
                          levelThreeClick({
                            wrapFiled: 'overbiteInfo',
                            addItem: {
                              submitKey: 'overbiteInfoIntrudeAnterior',
                              value: singleItem.value,
                              key: singleItem.key,
                            },
                            parentKey: 'press_front',
                          })
                        "
                        v-for="(singleItem, index) in caseOptions['updown_jaw']"
                        :key="index"
                      >
                        {{ singleItem.value }}
                      </div>
                    </div>
                  </div>
                  <div
                    style="margin-left: 1rem; margin-top: -0.3rem"
                    v-show="
                      map(
                        diagnosisParams.overbiteInfo['children'],
                        (dia) => dia.key
                      ).includes('press_front_rise_back')
                    "
                    class="popover_db"
                  >
                    <div class="popover_db_left"></div>
                    <div class="popover_db_right">
                      <div
                        class="common_btn"
                        :class="{
                          is_active: map(
                            find(
                              diagnosisParams.overbiteInfo['children'],
                              (dia) => dia.key === 'press_front_rise_back'
                            ) &&
                              find(
                                diagnosisParams.overbiteInfo['children'],
                                (dia) => dia.key === 'press_front_rise_back'
                              )['children'],
                            (diaC) => diaC.key
                          ).includes(singleItem.key),
                        }"
                        @click.stop="
                          levelThreeClick({
                            wrapFiled: 'overbiteInfo',
                            addItem: {
                              submitKey: 'overbiteInfoPosteriorsExrusion',
                              value: singleItem.value,
                              key: singleItem.key,
                            },
                            parentKey: 'press_front_rise_back',
                          })
                        "
                        v-for="(singleItem, singleItemIndex) in caseOptions[
                          'updown_jaw'
                        ]"
                        :key="singleItemIndex"
                      >
                        {{ singleItem.value }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="common_btn"
                :class="{
                  is_active: item.key === diagnosisParams.overbiteInfo.key,
                }"
                slot="reference"
              >
                {{ item.value }}
              </div>
            </el-popover>
            <div
              v-else
              class="common_btn"
              :class="{
                is_active: item.key === diagnosisParams.overbiteInfo.key,
              }"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="single_tip_box"
          v-show="
            map(
              diagnosisParams['overbiteInfo'].children,
              (dia) => dia.value
            ).join()
          "
        >
          <p>
            {{ overbiteText() }}
          </p>
        </div>
        <div
          v-show="
            map(
              diagnosisParams.overbiteInfo['children'],
              (dia) => dia.key
            ).includes('other')
          "
          class="remark_box"
        >
          <el-input
            :disabled="isBackDisabled"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            type="textarea"
            class="tex_b_a"
            show-word-limit
            v-model="diagnosisParams.overbiteOtherInfo"
            :placeholder="$t('common.common.qsrnr')"
          ></el-input>
        </div>
      </div>
      <div class="select_type" id="11">
        <p class="select_type_title mb10">
          11.{{ $t("cases.createDiagnosis.txt10") }}
        </p>
        <p class="problem_note" v-if="diagnosisProblem['11']">
          {{ diagnosisProblem["11"] }}
        </p>

        <div class="flex-y-c">
          <div
            @click="
              levelOneClick({
                noClear: true,
                radio: false,
                oneOfMore: {
                  one: 'uncorrect',
                  more: ['correct_front_reverse', 'correct_front_opposite'],
                },
                addItem: {
                  submitKey: 'frontToothInfo',
                  key: item.key,
                  value: item.value,
                },
              })
            "
            v-for="(item, index) in caseOptions['front_tooth_info']"
            :key="index"
          >
            <el-popover
              placement="bottom-start"
              :offset="-200"
              :disabled="isBackDisabled"
              :popper-options="{
                boundariesElement: 'body',
              }"
              trigger="click"
              v-if="
                ['correct_front_reverse', 'correct_front_opposite'].includes(
                  item.key
                )
              "
            >
              <template v-if="['correct_front_reverse'].includes(item.key)">
                <div class="popover_con mb2rem">
                  <div class="flex-y-c">
                    <div
                      class="common_btn"
                      :class="{
                        is_active: map(
                          diagnosisParams.frontToothInfo['children'],
                          (dia) => dia.key
                        ).includes(it.key),
                      }"
                      @click.stop="
                        levelTowClick({
                          multipleRadio: [
                            'front_tooth_unback',
                            'front_tooth_back',
                          ],
                          addItem: {
                            key: it.key,
                            submitKey: 'correctFrontReverse',
                            value: diaKeyObj[it.key],
                          },
                          noClear: true,
                          wrapFiled: 'frontToothInfo',
                        })
                      "
                      v-for="(it, itx) in caseOptions['correct_front_reverse']"
                      :key="itx"
                    >
                      {{ it.value }}
                    </div>
                  </div>
                  <p
                    :class="{
                      'c-red': filter(
                        diagnosisParams.frontToothInfo['children'],
                        (dia) => ['front_tooth_back'].includes(dia.key)
                      ).length,
                    }"
                    class="ml20 mt8"
                  >
                    {{ $t("cases.cases.rxz") }}
                  </p>
                </div>
                <div class="popover_con">
                  <div class="flex-y-c">
                    <div
                      class="common_btn"
                      :class="{
                        is_active: map(
                          diagnosisParams.frontToothInfo['children'],
                          (dia) => dia.key
                        ).includes(it.key),
                      }"
                      @click.stop="
                        levelTowClick({
                          multipleRadios: [
                            'lip_up_front',
                            'tongue_down_front',
                            'lip_up_front_tongue_down_front',
                          ],
                          addItem: {
                            key: it.key,
                            submitKey: 'correctFrontReverse',
                            value: diaKeyObj[it.key],
                          },
                          wrapFiled: 'frontToothInfo',
                        })
                      "
                      v-for="(it, itx) in caseOptions[
                        'correct_front_reverse_two'
                      ]"
                      :key="itx"
                    >
                      {{ it.value }}
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="popover_con">
                  <div class="flex-y-c">
                    <div
                      class="common_btn"
                      :class="{
                        is_active: map(
                          diagnosisParams.frontToothInfo['children'],
                          (dia) => dia.key
                        ).includes(it.key),
                      }"
                      @click.stop="
                        levelTowClick({
                          addItem: {
                            key: it.key,
                            submitKey: 'correctFrontOpposite',
                            value: diaKeyObj[it.key],
                          },
                          wrapFiled: 'frontToothInfo',
                        })
                      "
                      v-for="(it, itx) in caseOptions['correct_front_opposite']"
                      :key="itx"
                    >
                      {{ it.value }}
                    </div>
                  </div>
                </div>
              </template>
              <div
                class="common_btn"
                :class="
                  map(
                    diagnosisParams.frontToothInfo.children,
                    (it) => it.key
                  ).indexOf(item.key) !== -1
                    ? 'is_active'
                    : ''
                "
                slot="reference"
              >
                {{ item.value }}
              </div>
            </el-popover>
            <div
              v-else
              class="common_btn"
              :class="
                map(
                  diagnosisParams.frontToothInfo.children,
                  (it) => it.key
                ).indexOf(item.key) !== -1
                  ? 'is_active'
                  : ''
              "
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="single_tip_box"
          v-show="
            get(
              map(diagnosisParams['frontToothInfo'].children, (dia) => dia.key),
              '[0]'
            ) !== 'maintain' &&
            isArray(diagnosisParams['frontToothInfo'].children) &&
            get(diagnosisParams['frontToothInfo'], 'children').length
          "
        >
          <p
            style="line-height: 0.22rem"
            :key="nox"
            v-for="(node, nox) in filter(
              diagnosisParams['frontToothInfo'].children,
              (dia) => dia.submitKey === 'frontToothInfo'
            )"
          >
            {{ node.value }}
            {{
              filter(diagnosisParams["frontToothInfo"].children, (dia) =>
                node.key === "correct_front_reverse"
                  ? dia.submitKey === "correctFrontReverse"
                  : dia.submitKey === "correctFrontOpposite"
              ).length
                ? "-"
                : ""
            }}
            <span
              v-for="(dic, dix) in filter(
                diagnosisParams['frontToothInfo'].children,
                (dia) =>
                  node.key === 'correct_front_reverse'
                    ? dia.submitKey === 'correctFrontReverse'
                    : dia.submitKey === 'correctFrontOpposite'
              )"
              :key="dix"
            >
              <span v-if="dic.key === 'front_tooth_back'">
                {{ dic.value }}
                <span class="c-red">{{
                  $t("cases.createDiagnosis.qubtjjl") +
                  (dix <
                  filter(diagnosisParams["frontToothInfo"].children, (dia) =>
                    node.key === "correct_front_reverse"
                      ? dia.submitKey === "correctFrontReverse"
                      : dia.submitKey === "correctFrontOpposite"
                  ).length -
                    1
                    ? "、"
                    : "")
                }}</span>
              </span>
              <span v-else>{{
                dic.value +
                (dix <
                filter(diagnosisParams["frontToothInfo"].children, (dia) =>
                  node.key === "correct_front_reverse"
                    ? dia.submitKey === "correctFrontReverse"
                    : dia.submitKey === "correctFrontOpposite"
                ).length -
                  1
                  ? "、"
                  : "")
              }}</span>
            </span>
          </p>
        </div>
      </div>

      <div class="select_type" id="12">
        <p class="select_type_title mb10">
          12.{{ $t("cases.createDiagnosis.txt11") }}
        </p>
        <p class="problem_note" v-if="diagnosisProblem['12']">
          {{ diagnosisProblem["12"] }}
        </p>

        <div class="flex-y-c">
          <div
            v-for="(item, index) in caseOptions['back_tooth_info']"
            @click="
              levelOneClick({
                noClear: true,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'backToothInfo',
                },
              })
            "
            :key="index"
          >
            <el-popover
              placement="bottom"
              :disabled="isBackDisabled"
              :popper-options="{
                boundariesElement: 'body',
              }"
              trigger="click"
              v-if="item.key === 'correct'"
            >
              <div class="flex-y-c">
                <div
                  class="common_btn"
                  :class="{
                    is_active: map(
                      diagnosisParams['backToothInfo'].children,
                      (a) => a.key
                    ).includes(it.key),
                  }"
                  @click="
                    levelTowClick({
                      oneOfMore: {
                        one: 'after_design',
                        more: ['traction', 'resistance_nail'],
                      },
                      wrapFiled: 'backToothInfo',
                      addItem: {
                        key: it.key,
                        submitKey: 'backToothInfoCorrect',
                        value: it.value,
                      },
                    })
                  "
                  v-for="(it, idx) in caseOptions['back_tooth_info_correct']"
                  :key="idx"
                >
                  {{ it.value }}
                </div>
              </div>
              <div
                class="common_btn"
                :class="{
                  is_active: diagnosisParams.backToothInfo.key === item.key,
                }"
                slot="reference"
                :data-key="item.key"
              >
                {{ item.value }}
              </div>
            </el-popover>
            <div
              class="common_btn"
              :class="{
                is_active: diagnosisParams.backToothInfo.key === item.key,
              }"
              v-else
              :data-key="item.key"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="single_tip_box"
          v-show="
            map(
              diagnosisParams['backToothInfo'].children,
              (dia) => dia.value
            ).join()
          "
        >
          <p>
            {{
              `${map(
                diagnosisParams["backToothInfo"].children,
                (dia) => dia.value
              ).join("、")}`
            }}
          </p>
        </div>
      </div>

      <div class="select_type" id="13">
        <div class="flex-y-c mb10">
          <p><span class="main_theme_color_red fz14 mr4">●</span></p>
          <p class="select_type_title mr10">
            13.{{ $t("cases.createDiagnosis.txt12") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['13']">
          {{ diagnosisProblem["13"] }}
        </p>

        <div class="flex-y-c">
          <div
            v-for="(item, index) in caseOptions['middle_line']"
            :key="index"
            @click="
              levelOneClick({
                noClear: true,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'middleLine',
                },
              })
            "
          >
            <el-popover
              placement="bottom"
              :disabled="isBackDisabled"
              :popper-options="{
                boundariesElement: 'body',
              }"
              trigger="click"
              v-if="item.value === $t('cases.createDiagnosis.gszx')"
            >
              <div class="popover_con">
                <div style="width: 0.7rem" class="popover_con_left">
                  {{ $t("cases.createDiagnosis.se") }}
                </div>
                <div style="flex-direction: column">
                  <div class="flex-y-c">
                    <div
                      class="common_btn"
                      @click.stop="
                        levelTowClick({
                          wrapFiled: 'middleLine',
                          radio: true,
                          addItem: {
                            key: get(it, 'key'),
                            submitKey: 'upjawMiddleLineNote',
                            value: diaKeyObj[get(it, 'key')],
                            input: '',
                            inputKey: 'upjawMiddleLine',
                          },
                          parentFiled: item.key,
                        })
                      "
                      :class="{
                        is_active: find(
                          diagnosisParams.middleLine['children'],
                          (dia) =>
                            get(dia, 'key') === get(it, 'key') &&
                            get(dia, 'submitKey') === 'upjawMiddleLineNote'
                        ),
                      }"
                      v-for="(it, itx) in caseOptions['improve_middle_move']"
                      :key="itx"
                    >
                      {{ it.value }}
                    </div>
                  </div>
                  <p
                    class="flex-y-c posr"
                    v-show="
                      find(
                        diagnosisParams.middleLine['children'],
                        (dia) => get(dia, 'submitKey') === 'upjawMiddleLineNote'
                      )
                    "
                  >
                    <el-input
                      style="width: 2.6rem"
                      :disabled="isBackDisabled"
                      :value="
                        find(
                          diagnosisParams.middleLine['children'],
                          (dia) =>
                            get(dia, 'submitKey') === 'upjawMiddleLineNote'
                        ) &&
                        find(
                          diagnosisParams.middleLine['children'],
                          (dia) =>
                            get(dia, 'submitKey') === 'upjawMiddleLineNote'
                        )['input']
                      "
                      @change="
                        disposeNumber({
                          key: find(
                            diagnosisParams.middleLine['children'],
                            (dia) =>
                              get(dia, 'submitKey') === 'upjawMiddleLineNote'
                          ),
                          value: arguments[0],
                        })
                      "
                      @input="
                        disposeNumber({
                          key: find(
                            diagnosisParams.middleLine['children'],
                            (dia) =>
                              get(dia, 'submitKey') === 'upjawMiddleLineNote'
                          ),
                          value: arguments[0],
                        })
                      "
                      :placeholder="$t('cases.createDiagnosis.srjtsz')"
                    />
                    <span class="midcourt_line_input_mm c-white">mm</span>
                  </p>
                </div>
              </div>
              <div class="popover_con mt10">
                <div style="width: 0.7rem" class="popover_con_left">
                  {{ $t("cases.createDiagnosis.xe") }}
                </div>
                <div class="popover_con_right" style="flex-direction: column">
                  <div class="flex-y-c">
                    <div
                      class="common_btn"
                      :class="{
                        is_active: find(
                          diagnosisParams.middleLine['children'],
                          (dia) =>
                            dia.key === it.key &&
                            get(dia, 'submitKey') === 'downjawMiddleLineNote'
                        ),
                      }"
                      @click.stop="
                        levelTowClick({
                          wrapFiled: 'middleLine',
                          radio: true,
                          addItem: {
                            key: it.key,
                            submitKey: 'downjawMiddleLineNote',
                            value: diaKeyObj[it.key],
                            input: '',
                            inputKey: 'downjawMiddleLine',
                          },
                        })
                      "
                      v-for="(it, itx) in caseOptions['improve_middle_move']"
                      :key="itx"
                    >
                      {{ it.value }}
                    </div>
                  </div>
                  <div
                    class="flex-y-c posr"
                    v-show="
                      find(
                        diagnosisParams.middleLine['children'],
                        (dia) => dia.submitKey === 'downjawMiddleLineNote'
                      )
                    "
                  >
                    <el-input
                      style="width: 2.6rem"
                      :disabled="isBackDisabled"
                      :value="
                        find(
                          diagnosisParams.middleLine['children'],
                          (dia) =>
                            get(dia, 'submitKey') === 'downjawMiddleLineNote'
                        ) &&
                        find(
                          diagnosisParams.middleLine['children'],
                          (dia) =>
                            get(dia, 'submitKey') === 'downjawMiddleLineNote'
                        )['input']
                      "
                      @change="
                        disposeNumber({
                          key: find(
                            diagnosisParams.middleLine['children'],
                            (dia) =>
                              get(dia, 'submitKey') === 'downjawMiddleLineNote'
                          ),
                          value: arguments[0],
                        })
                      "
                      @input="
                        disposeNumber({
                          key: find(
                            diagnosisParams.middleLine['children'],
                            (dia) =>
                              get(dia, 'submitKey') === 'downjawMiddleLineNote'
                          ),
                          value: arguments[0],
                        })
                      "
                      :placeholder="$t('cases.createDiagnosis.srjtsz')"
                    />
                    <span class="midcourt_line_input_mm_two c-white">mm</span>
                  </div>
                </div>
              </div>
              <div
                class="common_btn"
                :class="{
                  is_active: diagnosisParams.middleLine.key === item.key,
                }"
                slot="reference"
              >
                {{ item.value }}
              </div>
            </el-popover>
            <div
              v-else
              class="common_btn"
              :class="
                values(diagnosisParams.middleLine).indexOf(item.key) !== -1
                  ? 'is_active'
                  : ''
              "
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="single_tip_box"
          v-show="
            get(
              find(
                diagnosisParams['middleLine'].children,
                (dia) => get(dia, 'submitKey') === 'upjawMiddleLineNote'
              ),
              'value'
            ) ||
            get(
              find(
                diagnosisParams['middleLine'].children,
                (dia) => get(dia, 'submitKey') === 'downjawMiddleLineNote'
              ),
              'value'
            )
          "
        >
          <p>
            {{
              get(
                find(
                  diagnosisParams["middleLine"].children,
                  (dia) => get(dia, "submitKey") === "upjawMiddleLineNote"
                ),
                "value"
              )
                ? $t("cases.createImage.se") + ":"
                : ""
            }}
            {{
              get(
                find(
                  diagnosisParams["middleLine"].children,
                  (dia) => get(dia, "submitKey") === "upjawMiddleLineNote"
                ),
                "value"
              )
            }}
            {{
              get(
                find(
                  diagnosisParams["middleLine"].children,
                  (dia) => get(dia, "submitKey") === "upjawMiddleLineNote"
                ),
                "input"
              )
            }}
            {{
              get(
                find(
                  diagnosisParams["middleLine"].children,
                  (dia) => dia["submitKey"] === "upjawMiddleLineNote"
                ),
                "input"
              )
                ? "mm"
                : ""
            }}
          </p>
          <p>
            {{
              get(
                find(
                  diagnosisParams["middleLine"].children,
                  (dia) => get(dia, "submitKey") === "downjawMiddleLineNote"
                ),
                "value"
              )
                ? $t("cases.createImage.xe") + ":"
                : ""
            }}
            {{
              get(
                find(
                  diagnosisParams["middleLine"].children,
                  (dia) => get(dia, "submitKey") === "downjawMiddleLineNote"
                ),
                "value"
              )
            }}
            {{
              get(
                find(
                  diagnosisParams["middleLine"].children,
                  (dia) => get(dia, "submitKey") === "downjawMiddleLineNote"
                ),
                "input"
              )
            }}
            {{
              get(
                find(
                  diagnosisParams["middleLine"].children,
                  (dia) => get(dia, "submitKey") === "downjawMiddleLineNote"
                ),
                "input"
              )
                ? "mm"
                : ""
            }}
          </p>
        </div>
      </div>

      <div class="select_type" id="14">
        <p class="select_type_title">
          14.{{ $t("cases.createDiagnosis.txt13") }}
        </p>
        <p class="problem_note" v-if="diagnosisProblem['14']">
          {{ diagnosisProblem["14"] }}
        </p>

        <div class="flex-y-c">
          <span
            :style="
              language === 'en_US'
                ? 'width: 0.8rem; text-align: left;margin-right:0'
                : ''
            "
            class="main_theme_color_666 fz14 mr10"
            >{{ $t("cases.createDiagnosis.se") }}</span
          >
          <div
            class="mt10"
            v-for="(item, index) in caseOptions['updown_jaw_gap_correct']"
            :key="index"
            @click="
              levelOneClick({
                noClear: false,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'upjawGapCorrect',
                },
              })
            "
          >
            <div
              class="common_btn"
              :class="{
                is_active: item.key === diagnosisParams.upjawGapCorrect.key,
              }"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div class="flex-y-c">
          <span
            :style="
              language === 'en_US'
                ? 'width: 0.8rem; text-align: left;margin-right:0'
                : ''
            "
            class="main_theme_color_666 fz14 mr10"
            >{{ $t("cases.createDiagnosis.xe") }}</span
          >
          <div
            class="mt10"
            v-for="(item, index) in caseOptions['updown_jaw_gap_correct']"
            :key="index"
            @click="
              levelOneClick({
                noClear: false,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'downjawGapCorrect',
                },
              })
            "
          >
            <div
              class="common_btn"
              :class="{
                is_active: item.key === diagnosisParams.downjawGapCorrect.key,
              }"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          style="
            height: 0.01rem;
            background-color: #c3c3c3;
            transform: translateY(0.54rem);
          "
        ></div>
        <div style="display: flex; flex-flow: column">
          <div
            v-for="(item, index) in caseOptions['gap_correct']"
            :key="index"
            @click="
              levelOneClick({
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'gapCorrect',
                },
                radio: false,
              })
            "
            class="common_btn mt10"
            style="margin-left: 0"
            :style="
              lang === 'zh_CN' ? 'max-width: 1.5rem' : 'max-width: 2.6rem'
            "
            :class="
              values(
                map(diagnosisParams.gapCorrect.children, (it) => it.key)
              ).indexOf(item.key) !== -1
                ? 'is_active'
                : ''
            "
          >
            {{ item.value }}
          </div>
        </div>
        <ReserveDinterval
          ref="intervalCorrect"
          @updateInterval="updateInterval"
          :readOnly="isBackDisabled"
          :crowdTeethList="uploadIntervalList"
          :commonTeeth="commonTeeth"
          v-show="
            values(
              map(diagnosisParams.gapCorrect.children, (it) => it.key)
            ).includes('reserved_gap')
          "
        ></ReserveDinterval>
      </div>
      <div class="select_type" id="15">
        <div class="flex-y-c mb10">
          <p v-if="needRTD">
            <span class="main_theme_color_red fz14 mr4">●</span>
          </p>
          <p class="select_type_title mr10">
            15.{{ $t("cases.createDiagnosis.txt14") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['15']">
          {{ diagnosisProblem["15"] }}
        </p>
        <div class="ml20" v-if="needRTD">
          <p class="fz14 mb20 mt20">
            <span class="main_theme_color_red fz14 mr4">●</span>
            <span class="main_theme_color_333"
              >15.1 {{ $t("cases.createDiagnosis.jcyjsx") }}</span
            >
          </p>

          <p class="fz14 mt10 main_theme_color_666">
            {{ $t("cases.createDiagnosis.hzsfjsbczc") }}
          </p>
          <div class="flex-y-c-w mt10">
            <div
              class="m210"
              v-for="(it, itx) in caseOptions['underjaw_abnormal_flag']"
              :key="itx"
              @click="
                levelOneClick({
                  addItem: {
                    key: it.key,
                    value: it.value,
                    submitKey: 'acceptWisdomTeethExtraction',
                  },
                  noClear: true,
                })
              "
            >
              <div
                class="common_btn"
                :class="{
                  is_active:
                    values(diagnosisParams.acceptWisdomTeethExtraction).indexOf(
                      it.key
                    ) !== -1,
                }"
              >
                {{ it.value }}
              </div>
            </div>
          </div>

          <p class="fz14 mt10 main_theme_color_666">
            {{ $t("cases.createDiagnosis.hzsfjsbcqmy") }}
          </p>
          <div class="flex-y-c-w mt10">
            <div
              v-for="(it, itx) in caseOptions['underjaw_abnormal_flag']"
              :key="itx"
              @click="
                levelOneClick({
                  noClear: true,
                  addItem: {
                    key: it.key,
                    value: it.value,
                    submitKey: 'acceptPremolarsExtraction',
                  },
                })
              "
            >
              <div
                class="common_btn"
                :class="{
                  is_active:
                    values(diagnosisParams.acceptPremolarsExtraction).indexOf(
                      it.key
                    ) !== -1,
                }"
              >
                {{ it.value }}
              </div>
            </div>
          </div>

          <p class="fz14 mt10 main_theme_color_666">
            {{ $t("cases.createDiagnosis.hzsfjsbchy") }}
          </p>
          <div class="flex-y-c-w mt10">
            <div
              v-for="(it, itx) in caseOptions[
                'accept_decayed_teeth_extraction'
              ]"
              :key="itx"
              @click="
                levelOneClick({
                  noClear: true,
                  addItem: {
                    key: it.key,
                    value: it.value,
                    submitKey: 'acceptDecayedTeethExtraction',
                  },
                })
              "
            >
              <div
                class="common_btn"
                :class="{
                  is_active:
                    values(
                      diagnosisParams.acceptDecayedTeethExtraction
                    ).indexOf(it.key) !== -1,
                }"
              >
                {{ it.value }}
              </div>
            </div>
          </div>

          <p class="fz14 mt10 main_theme_color_666">
            {{ $t("cases.createDiagnosis.hzsfjslmqy") }}
          </p>
          <div class="flex-y-c-w mt10">
            <div
              v-for="(it, itx) in caseOptions['underjaw_abnormal_flag']"
              :key="itx"
              @click="
                levelOneClick({
                  noClear: true,
                  addItem: {
                    key: it.key,
                    value: it.value,
                    submitKey: 'acceptIpr',
                  },
                })
              "
            >
              <div
                class="common_btn"
                :class="{
                  is_active:
                    values(diagnosisParams.acceptIpr).indexOf(it.key) !== -1,
                }"
              >
                {{ it.value }}
              </div>
            </div>
          </div>

          <p class="fz14 mb20 mt10">
            <span class="main_theme_color_red fz14 mr4">●</span>
            <span class="main_theme_color_333"
              >15.2 {{ $t("cases.createDiagnosis.qtlczc") }}</span
            >
          </p>

          <p class="fz14 mt10 main_theme_color_666">
            {{ $t("cases.createDiagnosis.sfyycxf") }}
          </p>
          <div class="flex-y-c-w mt10">
            <div
              v-for="(it, itx) in caseOptions['undergo_teeth_restoration']"
              :key="itx"
              @click="
                levelOneClick({
                  noClear: true,
                  addItem: {
                    key: it.key,
                    value: it.value,
                    submitKey: 'undergoTeethRestoration',
                  },
                })
              "
            >
              <div
                class="common_btn"
                :class="{
                  is_active:
                    values(diagnosisParams.undergoTeethRestoration).indexOf(
                      it.key
                    ) !== -1,
                }"
              >
                {{ it.value }}
              </div>
            </div>
          </div>

          <p class="fz14 mt10 main_theme_color_666">
            {{ $t("cases.createDiagnosis.zssfjb") }}
          </p>
          <div class="flex-y-c-w mt10">
            <div
              v-for="(it, itx) in caseOptions['underjaw_abnormal_flag']"
              :key="itx"
              @click="
                levelOneClick({
                  noClear: true,
                  addItem: {
                    key: it.key,
                    value: it.value,
                    submitKey: 'implantAnchoragePlacement',
                  },
                })
              "
            >
              <div
                class="common_btn"
                :class="{
                  is_active:
                    values(diagnosisParams.implantAnchoragePlacement).indexOf(
                      it.key
                    ) !== -1,
                }"
              >
                {{ it.value }}
              </div>
            </div>
          </div>

          <p class="fz14 mt10 main_theme_color_666">
            {{ $t("cases.createDiagnosis.zssfjbps") }}
          </p>
          <div class="flex-y-c-w mt10">
            <div
              v-for="(it, itx) in caseOptions['underjaw_abnormal_flag']"
              :key="itx"
              @click="
                levelOneClick({
                  noClear: true,
                  addItem: {
                    key: it.key,
                    value: it.value,
                    submitKey: 'cbctPhotography',
                  },
                })
              "
            >
              <div
                class="common_btn"
                :class="{
                  is_active:
                    values(diagnosisParams.cbctPhotography).indexOf(it.key) !==
                    -1,
                }"
              >
                {{ it.value }}
              </div>
            </div>
          </div>

          <p class="fz14 mt10 main_theme_color_666">
            {{ $t("cases.createDiagnosis.hzsfyzjs") }}
          </p>
          <div class="flex-y-c-w mt10">
            <div
              v-for="(it, itx) in caseOptions['underjaw_abnormal_flag']"
              :key="itx"
              @click="
                levelOneClick({
                  noClear: true,
                  addItem: {
                    key: it.key,
                    value: it.value,
                    submitKey: 'orthodonticsTreatmentBefore',
                  },
                })
              "
            >
              <div
                class="common_btn"
                :class="{
                  is_active:
                    values(diagnosisParams.orthodonticsTreatmentBefore).indexOf(
                      it.key
                    ) !== -1,
                }"
              >
                {{ it.value }}
              </div>
            </div>
          </div>
          <p class="fz14 mb20 mt10 main_theme_color_333">
            15.3 {{ $t("cases.createDiagnosis.jcyjjzfs") }}
          </p>
        </div>
        <div :class="{ ml20: needRTD }" class="select_type">
          <div class="single_con">
            <div class="s_c_box">
              <div
                :style="lang === 'en_US' ? 'width: 1.2rem' : 'width: 0.9rem'"
                class="s_c_b_left"
              >
                {{ $t("cases.createDiagnosis.se") }}
              </div>
              <div class="s_c_b_right">
                <div class="t_b_box">
                  <p
                    :style="lang === 'en_US' ? 'width: 1.3rem' : ''"
                    class="t_b_p fz14"
                  >
                    {{ $t("cases.createDiagnosis.kk") }}
                  </p>
                  <div
                    class="common_btn"
                    @click="
                      levelOneClick({
                        addItem: {
                          key: item.key,
                          value: item.value,
                          submitKey: 'upjawExt',
                        },
                      })
                    "
                    :class="{
                      is_active: item.key === diagnosisParams.upjawExt.key,
                    }"
                    v-for="(item, index) in caseOptions.choose_option"
                    :key="index"
                  >
                    {{ item.value }}
                  </div>
                </div>
                <div class="t_b_box">
                  <p
                    :style="lang === 'en_US' ? 'width: 1.3rem' : ''"
                    class="t_b_p fz14"
                  >
                    {{ $t("cases.createDiagnosis.cq") }}
                  </p>
                  <div
                    class="common_btn"
                    @click="
                      levelOneClick({
                        addItem: {
                          key: item.key,
                          value: item.value,
                          submitKey: 'upjawLip',
                        },
                      })
                    "
                    :class="{
                      is_active: item.key === diagnosisParams.upjawLip.key,
                    }"
                    v-for="(item, index) in caseOptions.choose_option"
                    :key="index"
                  >
                    {{ item.value }}
                  </div>
                </div>
                <div class="t_b_box">
                  <p
                    :style="lang === 'en_US' ? 'width: 1.3rem' : ''"
                    class="t_b_p fz14"
                  >
                    {{ $t("cases.createDiagnosis.lmqz") }}
                  </p>
                  <span
                    @click="
                      levelOneClick({
                        noClear: true,
                        addItem: {
                          key: item.key,
                          value: item.value,
                          submitKey: 'upjawSide',
                        },
                      })
                    "
                    v-for="(item, index) in caseOptions.choose_option"
                    :key="index"
                  >
                    <el-popover
                      placement="bottom"
                      :disabled="isBackDisabled"
                      :popper-options="{
                        boundariesElement: 'body',
                      }"
                      trigger="click"
                      v-if="['first_choose', 'chooseable'].includes(item.key)"
                    >
                      <div class="t_b_box">
                        <p
                          style="position: relative; top: -0.04rem"
                          :style="
                            lang === 'en_US'
                              ? 'width: 1.3rem;'
                              : 'width: 0.7rem'
                          "
                          class="t_b_p mr10 main_theme_color_333 fz14"
                        >
                          {{ $t("cases.createDiagnosis.qy") }}
                        </p>
                        <div
                          class="common_btn"
                          :key="itx"
                          @click="
                            levelTowClick({
                              radio: true,
                              addItem: {
                                key: it.key,
                                submitKey: 'upjawSideAnterior',
                                value: diaKeyObj[it.key],
                              },
                              wrapFiled: 'upjawSide',
                            })
                          "
                          :class="{
                            is_active: find(
                              diagnosisParams.upjawSide['children'],
                              (dia) =>
                                dia.key === it.key &&
                                get(dia, 'submitKey') === 'upjawSideAnterior'
                            ),
                          }"
                          v-for="(it, itx) in caseOptions.choose_option"
                        >
                          {{ it.value }}
                        </div>
                      </div>
                      <div class="t_b_box">
                        <p
                          style="position: relative; top: -0.04rem"
                          :style="
                            lang === 'en_US'
                              ? 'width: 1.3rem;'
                              : 'width: 0.7rem'
                          "
                          class="t_b_p mr10 fz14 main_theme_color_333"
                        >
                          {{ $t("cases.createDiagnosis.ychy") }}
                        </p>
                        <div
                          class="common_btn"
                          :key="itx"
                          @click="
                            levelTowClick({
                              radio: true,
                              addItem: {
                                key: it.key,
                                submitKey: 'upjawSideRight',
                                value: diaKeyObj[it.key],
                              },
                              wrapFiled: 'upjawSide',
                            })
                          "
                          :class="{
                            is_active: find(
                              diagnosisParams.upjawSide['children'],
                              (dia) =>
                                dia.key === it.key &&
                                get(dia, 'submitKey') === 'upjawSideRight'
                            ),
                          }"
                          v-for="(it, itx) in caseOptions.choose_option"
                        >
                          {{ it.value }}
                        </div>
                      </div>
                      <div class="t_b_box">
                        <p
                          style="position: relative; top: -0.04rem"
                          :style="
                            lang === 'en_US'
                              ? 'width: 1.3rem;'
                              : 'width: 0.7rem'
                          "
                          class="t_b_p mr10 c-white fz14 main_theme_color_333"
                        >
                          {{ $t("cases.createDiagnosis.zchy") }}
                        </p>
                        <div
                          class="common_btn"
                          :key="itx"
                          @click="
                            levelTowClick({
                              radio: true,
                              addItem: {
                                key: it.key,
                                submitKey: 'upjawSideLeft',
                                value: diaKeyObj[it.key],
                              },
                              wrapFiled: 'upjawSide',
                            })
                          "
                          :class="{
                            is_active: find(
                              diagnosisParams.upjawSide['children'],
                              (dia) =>
                                dia.key === it.key &&
                                get(dia, 'submitKey') === 'upjawSideLeft'
                            ),
                          }"
                          v-for="(it, itx) in caseOptions.choose_option"
                        >
                          {{ it.value }}
                        </div>
                      </div>
                      <div
                        class="common_btn"
                        :class="{
                          is_active: diagnosisParams.upjawSide.key === item.key,
                        }"
                        slot="reference"
                        :data-key="item.key"
                      >
                        {{ item.value }}
                      </div>
                    </el-popover>
                    <div
                      v-else
                      class="common_btn"
                      :class="{
                        is_active: diagnosisParams.upjawSide.key === item.key,
                      }"
                    >
                      {{ item.value }}
                    </div>
                  </span>
                </div>
                <div
                  v-show="diagnosisParams['upjawSide'].children"
                  class="single_tip_box mb20"
                >
                  <template
                    v-for="(node, nox) in diagnosisParams['upjawSide'].children"
                  >
                    <p v-if="node.value" :key="nox">
                      {{
                        `${
                          {
                            upjawSideAnterior: $t("cases.createDiagnosis.qy"),
                            upjawSideLeft: $t("cases.createDiagnosis.zchy"),
                            upjawSideRight: $t("cases.createDiagnosis.ychy"),
                          }[node.submitKey]
                        } - `
                      }}{{ node.value }}
                    </p>
                  </template>
                </div>
                <div
                  class="t_b_box"
                  v-if="caseOptions.upjaw_far && caseOptions.upjaw_far.length"
                >
                  <p
                    :style="lang === 'en_US' ? 'width: 1.3rem' : ''"
                    class="t_b_p fz14"
                  >
                    {{ $t("cases.createDiagnosis.yymy") }}
                  </p>
                  <div
                    class="common_btn"
                    @click="
                      levelOneClick({
                        addItem: {
                          key: item.key,
                          value: item.value,
                          submitKey: 'upjawFar',
                        },
                        radio: false,
                      })
                    "
                    :class="
                      values(
                        map(diagnosisParams.upjawFar.children, (it) => it.key)
                      ).indexOf(item.key) !== -1
                        ? 'is_active'
                        : ''
                    "
                    v-for="(item, index) in caseOptions.upjaw_far"
                    :key="index"
                  >
                    {{ item.value }}
                  </div>
                  <p
                    :class="{
                      main_theme_color_red:
                        values(
                          map(diagnosisParams.upjawFar.children, (it) => it.key)
                        ).includes('left_up') ||
                        values(
                          map(diagnosisParams.upjawFar.children, (it) => it.key)
                        ).includes('right_up'),
                    }"
                    class="t_b_tip"
                  >
                    {{ $t("cases.createDiagnosis.yymyms") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="s_c_box">
              <div
                :style="lang === 'en_US' ? 'width: 1.2rem' : 'width: 0.9rem'"
                class="s_c_b_left"
              >
                {{ $t("cases.createDiagnosis.xe") }}
              </div>
              <div class="s_c_b_right">
                <div class="t_b_box">
                  <p
                    :style="lang === 'en_US' ? 'width: 1.3rem' : ''"
                    class="t_b_p fz14"
                  >
                    {{ $t("cases.createDiagnosis.kk") }}
                  </p>
                  <div
                    class="common_btn"
                    @click="
                      levelOneClick({
                        addItem: {
                          key: item.key,
                          value: item.value,
                          submitKey: 'downjawExt',
                        },
                      })
                    "
                    :class="{
                      is_active: item.key === diagnosisParams.downjawExt.key,
                    }"
                    v-for="item in caseOptions.choose_option"
                    :key="`${item.key}_downjawExt`"
                    :data-key="item.key"
                  >
                    {{ item.value }}
                  </div>
                </div>
                <div class="t_b_box">
                  <p
                    :style="lang === 'en_US' ? 'width: 1.3rem' : ''"
                    class="t_b_p fz14"
                  >
                    {{ $t("cases.createDiagnosis.cq") }}
                  </p>
                  <div
                    class="common_btn"
                    @click="
                      levelOneClick({
                        addItem: {
                          key: item.key,
                          value: item.value,
                          submitKey: 'downjawLip',
                        },
                      })
                    "
                    :class="{
                      is_active: item.key === diagnosisParams.downjawLip.key,
                    }"
                    v-for="(item, index) in caseOptions.choose_option"
                    :key="index"
                  >
                    {{ item.value }}
                  </div>
                </div>
                <div class="t_b_box">
                  <p
                    :style="lang === 'en_US' ? 'width: 1.3rem' : ''"
                    class="t_b_p fz14"
                  >
                    {{ $t("cases.createDiagnosis.lmqz") }}
                  </p>
                  <span
                    @click="
                      levelOneClick({
                        noClear: true,
                        addItem: {
                          key: item.key,
                          value: item.value,
                          submitKey: 'downjawSide',
                        },
                      })
                    "
                    v-for="(item, index) in caseOptions.choose_option"
                    :key="index"
                  >
                    <el-popover
                      placement="bottom"
                      :disabled="isBackDisabled"
                      :popper-options="{
                        boundariesElement: 'body',
                      }"
                      trigger="click"
                      v-if="['first_choose', 'chooseable'].includes(item.key)"
                    >
                      <div class="t_b_box">
                        <p
                          style="position: relative; top: -0.04rem"
                          :style="
                            lang === 'en_US'
                              ? 'width: 1.3rem;'
                              : 'width: 0.7rem'
                          "
                          class="t_b_p fz14 mr10 main_theme_color_333"
                        >
                          {{ $t("cases.createDiagnosis.qy") }}
                        </p>
                        <div
                          class="common_btn"
                          :key="itx"
                          @click="
                            levelTowClick({
                              radio: true,
                              addItem: {
                                key: it.key,
                                submitKey: 'downjawSideAnterior',
                                value: diaKeyObj[it.key],
                              },
                              wrapFiled: 'downjawSide',
                            })
                          "
                          :class="{
                            is_active: find(
                              diagnosisParams.downjawSide['children'],
                              (dia) =>
                                dia.key === it.key &&
                                get(dia, 'submitKey') === 'downjawSideAnterior'
                            ),
                          }"
                          v-for="(it, itx) in caseOptions.choose_option"
                        >
                          {{ it.value }}
                        </div>
                      </div>
                      <div class="t_b_box">
                        <p
                          style="position: relative; top: -0.04rem"
                          :style="
                            lang === 'en_US'
                              ? 'width: 1.3rem;'
                              : 'width: 0.7rem'
                          "
                          class="t_b_p mr10 fz14 main_theme_color_333"
                        >
                          {{ $t("cases.createDiagnosis.ychy") }}
                        </p>
                        <div
                          class="common_btn"
                          :key="itx"
                          @click="
                            levelTowClick({
                              radio: true,
                              addItem: {
                                key: it.key,
                                submitKey: 'downjawSideRight',
                                value: diaKeyObj[it.key],
                              },
                              wrapFiled: 'downjawSide',
                            })
                          "
                          :class="{
                            is_active: find(
                              diagnosisParams.downjawSide['children'],
                              (dia) =>
                                dia.key === it.key &&
                                get(dia, 'submitKey') === 'downjawSideRight'
                            ),
                          }"
                          v-for="(it, itx) in caseOptions.choose_option"
                        >
                          {{ it.value }}
                        </div>
                      </div>
                      <div class="t_b_box">
                        <p
                          style="position: relative; top: -0.04rem"
                          :style="
                            lang === 'en_US' ? 'width: 1.3rem' : 'width: 0.7rem'
                          "
                          class="t_b_p fz14 mr10 main_theme_color_333"
                        >
                          {{ $t("cases.createDiagnosis.zchy") }}
                        </p>
                        <div
                          class="common_btn"
                          :key="itx"
                          @click="
                            levelTowClick({
                              radio: true,
                              addItem: {
                                key: it.key,
                                submitKey: 'downjawSideLeft',
                                value: diaKeyObj[it.key],
                              },
                              wrapFiled: 'downjawSide',
                            })
                          "
                          :class="{
                            is_active: find(
                              diagnosisParams.downjawSide['children'],
                              (dia) =>
                                dia.key === it.key &&
                                get(dia, 'submitKey') === 'downjawSideLeft'
                            ),
                          }"
                          v-for="(it, itx) in caseOptions.choose_option"
                        >
                          {{ it.value }}
                        </div>
                      </div>
                      <div
                        class="common_btn"
                        :class="{
                          is_active:
                            diagnosisParams.downjawSide.key === item.key,
                        }"
                        slot="reference"
                        :data-key="item.key"
                      >
                        {{ item.value }}
                      </div>
                    </el-popover>
                    <div
                      v-else
                      class="common_btn"
                      :class="{
                        is_active: diagnosisParams.downjawSide.key === item.key,
                      }"
                    >
                      {{ item.value }}
                    </div>
                  </span>
                </div>
                <div
                  v-show="diagnosisParams['downjawSide'].children"
                  class="single_tip_box mb20"
                >
                  <template
                    v-for="(node, nox) in diagnosisParams['downjawSide']
                      .children"
                  >
                    <p v-if="node.value" :key="nox">
                      {{
                        `${
                          {
                            downjawSideAnterior: $t("cases.createDiagnosis.qy"),
                            downjawSideLeft: $t("cases.createDiagnosis.zchy"),
                            downjawSideRight: $t("cases.createDiagnosis.ychy"),
                          }[node.submitKey]
                        } - `
                      }}{{ node.value }}
                    </p>
                  </template>
                </div>
                <div class="t_b_box">
                  <p
                    :style="lang === 'en_US' ? 'width: 1.3rem' : ''"
                    class="t_b_p fz14"
                  >
                    {{ $t("cases.createDiagnosis.yymy") }}
                  </p>
                  <div
                    class="common_btn"
                    @click="
                      levelOneClick({
                        addItem: {
                          key: item.key,
                          value: item.value,
                          submitKey: 'downjawFar',
                        },
                        radio: false,
                      })
                    "
                    :class="{
                      is_active: values(
                        map(diagnosisParams.downjawFar.children, (it) => it.key)
                      ).includes(item.key),
                    }"
                    v-for="(item, index) in caseOptions.downjaw_far"
                    :key="index"
                  >
                    {{ item.value }}
                  </div>
                  <p
                    :class="{
                      main_theme_color_red:
                        values(
                          map(
                            diagnosisParams.downjawFar.children,
                            (it) => it.key
                          )
                        ).includes('left_down') ||
                        values(
                          map(
                            diagnosisParams.downjawFar.children,
                            (it) => it.key
                          )
                        ).includes('right_down'),
                    }"
                    class="t_b_tip"
                  >
                    {{ $t("cases.createDiagnosis.yymyms") }}
                  </p>
                </div>
              </div>
            </div>
            <div
              style="
                height: 0.01rem;
                background-color: #c3c3c3;
                transform: translateY(0.55rem);
              "
            ></div>
            <div
              :style="lang === 'en_US' ? 'margin-left: 2.39rem' : ''"
              class="t_b_box"
            >
              <div
                class="common_btn"
                @click="
                  levelOneClick({
                    addItem: {
                      key: 'after_design',
                      value: $t('cases.createDiagnosis.threedsjhqd'),
                      submitKey: 'crowdCorrect',
                    },
                  })
                "
                :class="{
                  is_active:
                    'after_design' === diagnosisParams.crowdCorrect.key,
                }"
              >
                {{ $t("cases.createDiagnosis.sjhzqr") }}
              </div>
            </div>
          </div>
          <div class="flex-y-c">
            <span class="fz14 main_theme_color_333 mr10">{{
              $t("cases.createDiagnosis.by")
            }}</span>
            <div
              class="common_btn mt10"
              :class="{
                is_active: item.key === diagnosisParams.toothExtraction.key,
              }"
              @click="
                levelOneClick({
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'toothExtraction',
                  },
                })
              "
              v-for="(item, index) in caseOptions['tooth_extraction_options']"
              :key="index"
            >
              {{ item.value }}
            </div>
          </div>
          <ChildrenTeeth
            v-show="diagnosisParams.toothExtraction.key === 'true'"
            :readOnly="isBackDisabled"
            :commonTeeth="commonTeeth"
            ref="teethComponents"
            @updateTeeth="updateTeeth"
            class="mb40 mt40"
            :teethType="4"
          />
        </div>
      </div>
      <div class="select_type" id="16">
        <div class="flex-y-c mb10">
          <p v-if="!needRTD">
            <span class="main_theme_color_red fz14 mr4">●</span>
          </p>
          <p class="select_type_title mr10">
            16.{{ $t("cases.createDiagnosis.txt15") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['16']">
          {{ diagnosisProblem["16"] }}
        </p>
        <div class="flex-y-c">
          <div
            @click="
              levelOneClick({
                noClear: true,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'growNailFlag',
                },
              })
            "
            v-for="(item, index) in caseOptions['grow_nail_flag']"
            :key="index"
          >
            <div
              class="common_btn"
              :class="
                values(diagnosisParams.growNailFlag).indexOf(item.key) !== -1
                  ? 'is_active'
                  : ''
              "
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="ml20"
          v-if="get(diagnosisParams.growNailFlag, 'key') === 'true'"
        >
          <p class="fz14">
            <span v-if="!needRTD" class="main_theme_color_red fz14 mr4">●</span>
            <span class="main_theme_color_333"
              >16.1 {{ $t("cases.createDiagnosis.nzrzzkd") }}</span
            >
          </p>
          <div class="flex-y-c">
            <span class="mr10 main_theme_color_333 fz14">{{
              $t("cases.createDiagnosis.se")
            }}</span>
            <div
              class="mt10"
              v-for="(it, itx) in caseOptions['grow_nail_position']"
              :key="itx"
              @click="
                levelTowClick({
                  wrapFiled: 'growNailFlag',
                  addItem: {
                    key: it.key,
                    value: it.value,
                    submitKey: 'upGrowNailPosition',
                  },
                })
              "
            >
              <div
                class="common_btn"
                :class="{
                  is_active: values(
                    map(
                      filter(
                        diagnosisParams.growNailFlag.children,
                        (a) => a.submitKey === 'upGrowNailPosition'
                      ),
                      (b) => b.key
                    )
                  ).includes(it.key),
                }"
              >
                {{ it.value }}
              </div>
            </div>
          </div>
          <div class="flex-y-c">
            <span class="mr10 main_theme_color_333 fz14">{{
              $t("cases.createDiagnosis.xe")
            }}</span>
            <div
              class="mt10"
              v-for="(it, itx) in caseOptions['grow_nail_position']"
              :key="itx"
              @click="
                levelTowClick({
                  wrapFiled: 'growNailFlag',
                  addItem: {
                    key: it.key,
                    value: it.value,
                    submitKey: 'downGrowNailPosition',
                  },
                })
              "
            >
              <div
                class="common_btn"
                :class="{
                  is_active: values(
                    map(
                      filter(
                        diagnosisParams.growNailFlag.children,
                        (a) => a.submitKey === 'downGrowNailPosition'
                      ),
                      (b) => b.key
                    )
                  ).includes(it.key),
                }"
              >
                {{ it.value }}
              </div>
            </div>
          </div>
          <div class="mt10">
            <p class="mb10 fz14 main_theme_color_333">
              <span v-if="!needRTD" class="main_theme_color_red fz14 mr4"
                >●</span
              >
              <span class="main_theme_color_333"
                >16.2 {{ $t("cases.createDiagnosis.nzrzzkdzy") }}</span
              >
            </p>
            <div class="flex-y-c">
              <div
                style="margin-left: 0 !important; margin-right: 0.2rem"
                class="common_btn"
                @click="
                  levelTowClick({
                    wrapFiled: 'growNailFlag',
                    addItem: {
                      key: idt.key,
                      value: idt.value,
                      submitKey: 'growNailPurpose',
                    },
                    radio: false,
                  })
                "
                :class="
                  values(
                    map(diagnosisParams.growNailFlag.children, (it) => it.key)
                  ).indexOf(idt.key) !== -1
                    ? 'is_active'
                    : ''
                "
                v-for="(idt, idtx) in caseOptions['grow_nail_purpose']"
                :key="`${idt.key}_${idtx}`"
              >
                {{ idt.value }}
              </div>
            </div>
            <div
              class="remark_box"
              v-show="
                values(
                  map(diagnosisParams.growNailFlag.children, (it) => it.key)
                ).includes('other')
              "
            >
              <el-input
                :disabled="isBackDisabled"
                :autosize="{ minRows: 4, maxRows: 6 }"
                maxlength="2000"
                class="tex_b_a"
                type="textarea"
                show-word-limit
                v-model="diagnosisParams.growNailFlagNote"
                :placeholder="$t('common.common.qsrnr')"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="select_type" id="17">
        <div class="flex-y-c mb10">
          <p><span class="main_theme_color_red fz14 mr4">●</span></p>
          <p class="select_type_title mr10">
            17.{{ $t("cases.createDiagnosis.exgjsfczyc") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['17']">
          {{ diagnosisProblem["17"] }}
        </p>
        <div class="flex-y-c">
          <div
            class="common_btn"
            @click="
              levelOneClick({
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'underjawAbnormalFlag',
                },
              })
            "
            :class="{
              is_active: item.key === diagnosisParams.underjawAbnormalFlag.key,
            }"
            v-for="(item, index) in caseOptions['underjaw_abnormal_flag']"
            :key="index"
          >
            {{ item.value }}
          </div>
        </div>
        <div
          class="remark_box"
          v-show="diagnosisParams.underjawAbnormalFlag.key === 'true'"
        >
          <el-input
            :disabled="isBackDisabled"
            class="tex_b_a"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            type="textarea"
            show-word-limit
            v-model="diagnosisParams.underjawAbnormalFlagNote"
            :placeholder="$t('cases.createDiagnosis.hxgjtswz')"
          ></el-input>
        </div>
      </div>
      <div class="select_type" id="18">
        <div class="flex-y-c mb10">
          <p><span class="main_theme_color_red fz14 mr4">●</span></p>
          <p class="select_type_title mr10">
            18.{{ $t("cases.createDiagnosis.sfxybdjzq") }}
          </p>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('cases.createDiagnosis.sfxybdjzqts')"
            placement="right"
          >
            <img
              style="position: relative; top: -5px"
              src="../../../common/imgs/case/case_dia_query.png"
              alt=""
            />
          </el-tooltip>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['18']">
          {{ diagnosisProblem["18"] }}
        </p>
        <div class="flex-y-c">
          <div
            class="common_btn"
            @click="
              levelOneClick({
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'needPassiveAppliance',
                },
              })
            "
            :class="{
              is_active: item.key === diagnosisParams.needPassiveAppliance.key,
              disabled_btn: item.key === 'true' && isDisabledPassivity,
            }"
            v-for="(item, index) in caseOptions['underjaw_abnormal_flag']"
            :key="index"
          >
            {{ item.value }}
          </div>
        </div>
        <div
          class="remark_box"
          v-show="diagnosisParams.needPassiveAppliance.key === 'true'"
        >
          <el-input
            :disabled="isBackDisabled"
            class="tex_b_a"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            type="textarea"
            show-word-limit
            v-model="diagnosisParams.needPassiveApplianceNote"
            :placeholder="$t('cases.createDiagnosis.qtjdxjzq')"
          ></el-input>
        </div>
      </div>
      <div class="select_type" id="19">
        <p class="select_type_title mb20">
          19.{{ $t("cases.createDiagnosis.txt17") }}
        </p>
        <p class="problem_note" v-if="diagnosisProblem['19']">
          {{ diagnosisProblem["19"] }}
        </p>
        <div class="remark_box">
          <el-input
            :disabled="isBackDisabled"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            class="tex_b_a"
            type="textarea"
            show-word-limit
            v-model="diagnosisParams.specialInstructions"
            :placeholder="$t('common.common.qsrnr')"
          ></el-input>
        </div>
      </div>
    </div>
    <full-loading v-show="isLoading" />
  </div>
</template>

<script>
import FullLoading from "components/full-loading/full-loading";
import { getDictAllList } from "common/api/public";
import {
  createCurePlan,
  editCurePlan,
  detailCurePlan,
  cureProblemList,
} from "common/api/cases";
import { notifyMsg } from "common/js/util";
import $ from "jquery";
import { toNumber } from "../../../common/js/Fn";
import { changeRouteQuery } from "../../../common/js/Fn";
import ChildrenTeeth from "../../../components/treatment-table/children-teeth/index";
import IntervalCorrect from "../../../components/treatment-table/interval-correct/index";
import AdjoinDislodge from "../../../components/treatment-table/children-adjoin-dislodge/index";
import ReserveDinterval from "../../../components/treatment-table/reserve-dinterval/index";
import { mapGetters, mapActions, mapState } from "vuex";
import {
  each,
  map,
  filter,
  get,
  isArray,
  keys,
  values,
  join,
  flattenDeep,
  find,
  isObject,
  extend,
  pickBy,
  split,
  isNumber,
  some,
} from "lodash";
import {
  adultOptions,
  adultTeethList,
  adultOptionsKey,
} from "../../../components/treatment-table/common";

export default {
  data() {
    return {
      caseOptions: {},
      diaKeyObj: {},
      rightHeight: "",
      diagnosisParams: {
        //1
        toothProblem: {},
        jawProblem: {},
        toothOtherProblem: "",

        //2
        toothProblemTwo: {},
        jawProblemTwo: {},
        toothOtherProblemTwo: "",

        //3
        angleClassification: {},
        sagittalMaxillomandibularClassification: {},
        clinicInfo: {},
        clinicOtherInfo: "",

        //4
        toCorrectToothJaw: {},

        // 6
        nonMoveableTeeth: "",
        // 7
        notAvailableAttachments: "",

        // 8
        leftSagittalRelation: {},
        leftSagittalRelationNote: {},
        leftSagittalRelationFar: {
          children: [],
        },
        leftSagittalRelationBitJump: {
          children: [],
        },
        leftSagittalRelationExtraction: {
          children: [],
        },

        rightSagittalRelation: {},
        rightSagittalRelationNote: {},
        rightSagittalRelationFar: {
          children: [],
        },
        rightSagittalRelationBitJump: {
          children: [],
        },
        rightSagittalRelationExtraction: {
          children: [],
        },

        //9
        coverInfo: {},

        // 10
        overbiteInfo: {},

        overbiteInfoOpen: {},
        overbiteInfoDeep: {},

        overbiteInfoIntrudeAnterior: {},
        overbiteInfoPosteriorsExrusion: {},
        overbiteOtherInfo: "",

        // 11
        frontToothInfo: {},

        // 12
        backToothInfo: {},

        //13
        middleLine: {},

        //14
        upjawGapCorrect: {},
        downjawGapCorrect: {},
        gapCorrect: {},

        // 15
        acceptWisdomTeethExtraction: {},
        acceptPremolarsExtraction: {},
        acceptDecayedTeethExtraction: {},
        acceptIpr: {},
        undergoTeethRestoration: {},
        implantAnchoragePlacement: {},
        cbctPhotography: {},
        orthodonticsTreatmentBefore: {},
        upjawLip: {},
        upjawExt: {},
        upjawFar: {},
        downjawExt: {},
        downjawLip: {},
        downjawFar: {},
        crowdCorrectSide: {},
        crowdCorrect: {},
        upjawSide: {},
        downjawSide: {},
        toothExtraction: {},
        upjawSideAnterior: {},
        upjawSideRight: {},
        upjawSideLeft: {},
        downjawSideAnterior: {},
        downjawSideRight: {},
        downjawSideLeft: {},

        //16
        growNailFlag: {},
        upGrowNailPosition: {},
        downGrowNailPosition: {},
        growNailPurpose: {},
        growNailFlagNote: "",

        //17
        underjawAbnormalFlag: {},
        underjawAbnormalFlagNote: "",

        //18
        needPassiveAppliance: {},
        needPassiveApplianceNote: "",

        // 19
        specialInstructions: "",
      },
      commonTeeth: adultTeethList,
      lang: localStorage.getItem("user_lang") || "zh_CN",
      selectLeftKey: "1",
      leftList: [
        {
          title: this.$t("cases.createDiagnosis.txt1"),
        },
        {
          title: this.$t("cases.createDiagnosis.zyjzmb"),
        },
        {
          title: this.$t("cases.createDiagnosis.txt2"),
        },
        {
          title: this.$t("cases.createDiagnosis.txt3"),
        },
        {
          title: this.$t("cases.createDiagnosis.txt4"),
        },
        {
          title: this.$t("cases.createDiagnosis.txt5"),
        },
        {
          title: this.$t("cases.createDiagnosis.txt6"),
        },
        {
          title: this.$t("cases.createDiagnosis.txt7"),
        },
        {
          title: this.$t("cases.createDiagnosis.txt8"),
        },
        {
          title: this.$t("cases.createDiagnosis.txt9"),
        },
        {
          title: this.$t("cases.createDiagnosis.txt10"),
        },
        {
          title: this.$t("cases.createDiagnosis.txt11"),
        },
        {
          title: this.$t("cases.createDiagnosis.txt12"),
        },
        {
          title: this.$t("cases.createDiagnosis.txt13"),
        },
        {
          title: this.$t("cases.createDiagnosis.txt14"),
        },
        {
          title: this.$t("cases.createDiagnosis.txt15"),
        },
        {
          title: this.$t("cases.createDiagnosis.txt16"),
        },
        {
          title: this.$t("cases.createDiagnosis.sfxybdjzq"),
        },
        {
          title: this.$t("cases.createDiagnosis.txt17"),
        },
      ],
      leftTopObj: {},
      isScroll: true,
      needRTD: sessionStorage.getItem("needRTD") === "1",
      diagnosisProblem: {},
      details: {},
      isLoading: false,
      problemList: [],

      //间隙的矫治
      uploadIntervalList: [],

      // 8 二级弹层 选项
      sagittalRelationshipOption: [],
      sagittalRelationshipOptionTow: [],
      sagittalRelationshipOptionThree: [],
      sagittalRelationshipOptionFour: [],

      isResponse: 0,

      isProductErr: false,
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language,
    }),
    ...mapGetters({
      commonCaseDetail: "getCommonCaseDetail", //病例详情
    }),
    queryCaseId() {
      return this.$route.query.caseId;
    },
    queryCurePlanId() {
      return this.$route.query.curePlanId;
    },
    isBackDisabled() {
      return false;
      return get(this.details, "status") === "to_resubmit" && this.isProductErr;
    },
    isDisabledPassivity() {
      return ["upjaw", "downjaw"].includes(
        this.diagnosisParams.toCorrectToothJaw.key
      );
    },
  },
  methods: {
    ...mapActions({
      getCaseCureDetail: "actionGetCaseCureDetail", //治疗表详情
      getCommonCaseDetail: "actionGetCommonCaseDetail", //病例详情
    }),
    isArray,
    map,
    keys,
    values,
    filter,
    join,
    flattenDeep,
    find,
    get,
    updateInterval(list) {
      this.uploadIntervalList = list;
    },
    overbiteText() {
      let list = this.diagnosisParams["overbiteInfo"]["children"];
      let ls = map(list, (item, index) => {
        if (item.children) {
          return (
            item.value +
            (["press_front_rise_back", "press_front"].includes(item.key)
              ? `${
                  (item.children.length ? "-" : "") +
                  map(item.children, (it) => it.value).join("、")
                }`
              : "") +
            (list.length === 1 ? "" : ",")
          );
        }
        return (
          item.value +
          (index === list.length - 1
            ? ""
            : ["press_front_rise_back", "press_front"].includes(item.key)
            ? ","
            : "、")
        );
      });
      return ls.join("");
    },
    selectLeft(key, left) {
      this.isScroll = false;
      let leftStr;
      if (left === "left") {
        leftStr = `${+key + 1}`;
        document.documentElement.scrollTop =
          document.getElementById(leftStr).offsetTop;
      } else {
        leftStr = `${key}`;
        document.documentElement.scrollTop =
          document.getElementById(key).offsetTop;
      }
      this.selectLeftKey = "d" + leftStr;
      this.leftHandleScroll(document.documentElement.scrollTop / 8);
      setTimeout(() => {
        this.isScroll = true;
      }, 1000);
    },
    disposeNumber({ key, value }) {
      if (value === "") return (key.input = "");
      key.input =
        isNumber(toNumber(value)) && toNumber(value) <= 10
          ? toNumber(value)
          : "";
    },
    leftHandleScroll(val) {
      let div = this.$refs.scrollbar && this.$refs.scrollbar.$refs["wrap"];
      if (val < 0 && !div) return;
      div.scrollTop = val;
    },
    updateTeeth(data) {
      this.commonTeeth = data;
    },
    sortOptionThree(wrapFiled, index, parentKey) {
      let list = [];
      each(adultOptions[parentKey], (i) => {
        each(adultOptionsKey, (item) => {
          each(
            this.diagnosisParams[wrapFiled]["children"][index]["children"],
            (it) => {
              if (item === parentKey) {
                if (i === it.key) {
                  list.push(it);
                }
              }
            }
          );
        });
      });
      this.diagnosisParams[wrapFiled]["children"][index]["children"] = list;
    },
    sortOption(wrapFiled) {
      let list = [];
      each(adultOptions[wrapFiled], (item) => {
        each(this.diagnosisParams[wrapFiled]["children"], (it) => {
          if (item === it.key) {
            list.push(it);
          }
        });
      });
      this.diagnosisParams[wrapFiled]["children"] = list;
    },
    levelThreeClick({ wrapFiled, parentKey, addItem }) {
      const { submitKey, key } = addItem;
      if (!key || this.isBackDisabled) return;
      each(this.diagnosisParams[wrapFiled]["children"], (item, index) => {
        if (item.key === parentKey) {
          let list =
            this.diagnosisParams[wrapFiled]["children"][index].children;
          if (isArray(list) && list.length) {
            let isSame;
            each(
              this.diagnosisParams[wrapFiled]["children"][index].children,
              (item, index) => {
                if (item.key === key && submitKey === item.submitKey) {
                  isSame = index;
                }
              }
            );
            if (isNumber(isSame)) {
              return this.diagnosisParams[wrapFiled]["children"][index][
                "children"
              ].splice(isSame, 1);
            }
            this.diagnosisParams[wrapFiled]["children"][index].children.push(
              addItem
            );
          } else {
            this.$set(
              this.diagnosisParams[wrapFiled]["children"][index],
              "children",
              [addItem]
            );
          }
          each(adultOptionsKey, (it) => {
            if (parentKey === it) {
              this.sortOptionThree(wrapFiled, index, parentKey);
            }
          });
        }
      });
    },
    levelTowClick({
      wrapFiled,
      radio = false,
      addItem,
      noClear = false,
      multipleRadio = false,
      multipleRadios = false,
      oneOfMore = false,
    }) {
      // radio：是否单选,  multipleRadio, multipleRadios：1对1   oneOfMore: 1对多  addItem: 想要添加的数据
      // const submitKey = addItem.submitKey;
      const { key, submitKey } = addItem;
      if (!key || this.isBackDisabled) return;
      //输入框的清空
      let inputKeyObj = {
        growNailPurpose: "growNailFlagNote",
      };
      let inputKeyList = keys(inputKeyObj);
      if (
        inputKeyList.includes(submitKey) &&
        ["other", "false"].includes(key)
      ) {
        this.$set(this.diagnosisParams, inputKeyObj[submitKey], "");
      }

      if (radio) {
        if (!this.diagnosisParams[wrapFiled]["children"]) {
          this.$set(this.diagnosisParams[wrapFiled], "children", []);
        }
        let index;
        each(this.diagnosisParams[wrapFiled]["children"], (dia, dix) => {
          if (dia.submitKey === addItem.submitKey) {
            index = dix;
          }
        });
        if (index !== undefined) {
          return this.diagnosisParams[wrapFiled]["children"].splice(
            index,
            1,
            addItem
          );
        }
        this.diagnosisParams[wrapFiled]["children"].push(addItem);
      } else {
        let keyList = map(
          this.diagnosisParams[wrapFiled]["children"],
          (item) => item.key
        );

        if (isArray(this.diagnosisParams[wrapFiled]["children"])) {
          //1对1
          if (multipleRadio) {
            if (
              noClear &&
              map(
                this.diagnosisParams[wrapFiled]["children"],
                (item) => item.key
              ).includes(key)
            ) {
              return "";
            }

            if (multipleRadio.indexOf(key) !== -1) {
              let list = filter(multipleRadio, (it) => it !== key);
              each(keyList, (item, index) => {
                list.indexOf(item) !== -1 &&
                  this.diagnosisParams[wrapFiled]["children"].splice(index, 1);
              });
            }
          }

          if (multipleRadios) {
            if (
              noClear &&
              map(
                this.diagnosisParams[wrapFiled]["children"],
                (item) => item.key
              ).includes(key)
            ) {
              return "";
            }

            if (multipleRadios.indexOf(key) !== -1) {
              let list = filter(multipleRadios, (it) => it !== key);
              each(keyList, (item, index) => {
                list.indexOf(item) !== -1 &&
                  this.diagnosisParams[wrapFiled]["children"].splice(index, 1);
              });
            }
          }

          //1对多互斥
          if (oneOfMore) {
            let { one, more } = oneOfMore;
            if (key === one) {
              this.diagnosisParams[wrapFiled]["children"] = filter(
                this.diagnosisParams[wrapFiled]["children"],
                (it) => more.indexOf(it.key) === -1
              );
            }
            if (more.indexOf(key) !== -1) {
              this.diagnosisParams[wrapFiled]["children"] = filter(
                this.diagnosisParams[wrapFiled]["children"],
                (it) => it.key !== one
              );
            }
          }

          let isSame;
          each(this.diagnosisParams[wrapFiled]["children"], (item, index) => {
            if (item.key === key && submitKey === item.submitKey) {
              isSame = index;
            }
          });
          if (isNumber(isSame)) {
            // 成人 8矢状关系 多选 三级清空
            if (
              [
                "rightSagittalRelationNote",
                "leftSagittalRelationNote",
              ].includes(submitKey)
            ) {
              let isLeft = submitKey === "leftSagittalRelationNote";
              let resetKey = {
                far_grind: isLeft
                  ? "leftSagittalRelationFar"
                  : "rightSagittalRelationFar",
                bit_jump: isLeft
                  ? "leftSagittalRelationBitJump"
                  : "rightSagittalRelationBitJump",
                tooth_extraction: isLeft
                  ? "leftSagittalRelationExtraction"
                  : "rightSagittalRelationExtraction",
              }[key];
              this.$set(this.diagnosisParams, resetKey, { children: [] });
            }
            return this.diagnosisParams[wrapFiled]["children"].splice(
              isSame,
              1
            );
          }
          this.diagnosisParams[wrapFiled]["children"].push(addItem);
        } else {
          this.$set(this.diagnosisParams[wrapFiled], "children", []);
          this.diagnosisParams[wrapFiled]["children"].push(addItem);
        }
        each(adultOptionsKey, (it) => {
          if (it === wrapFiled) {
            this.sortOption(it);
          }
        });
      }
    },
    levelOneClick({
      addItem,
      multipleRadio,
      radio = true,
      oneOfMore = false,
      noClear = false,
    }) {
      let { key, submitKey } = addItem;
      if (!key || this.isBackDisabled) return;
      if (
        ["upjaw", "downjaw"].includes(key) &&
        submitKey === "toCorrectToothJaw"
      ) {
        if (this.diagnosisParams.needPassiveAppliance.key !== "false") {
          this.diagnosisParams.needPassiveAppliance = {
            submitKey: "needPassiveAppliance",
            key: "false",
            value: this.lang === "zh_CN" ? "否" : "No",
          };
          this.diagnosisParams.needPassiveApplianceNote = "";
        }
      }
      if (
        submitKey === "needPassiveAppliance" &&
        key === "true" &&
        this.isDisabledPassivity
      ) {
        return "";
      }

      let frontToothInDefault = () => {
        let frontToothInfoKetList = map(
          this.diagnosisParams[submitKey].children,
          (item) => item.key
        );
        let frontToothInfoValue =
          !frontToothInfoKetList.includes("front_tooth_back") &&
          !frontToothInfoKetList.includes("front_tooth_unback");
        if (key === "correct_front_reverse" && frontToothInfoValue) {
          let obj = {
            key: "front_tooth_unback",
            submitKey: "correctFrontReverse",
            value:
              this.lang === "zh_CN"
                ? "前牙不可后退至切对切"
                : "Anterior Edge to Edge Occlusion cannot be Achieved",
          };
          this.diagnosisParams[submitKey]["children"].push(obj);
        }
      };

      //输入框的清空
      let inputKeyObj = {
        toothProblem: "toothOtherProblem",
        toothProblemTwo: "toothOtherProblemTwo",
        clinicInfo: "clinicOtherInfo",
        underjawAbnormalFlag: "underjawAbnormalFlagNote",
        needPassiveAppliance: "needPassiveApplianceNote",
      };
      let inputKeyList = keys(inputKeyObj);
      if (
        inputKeyList.includes(submitKey) &&
        ["other", "false"].includes(key)
      ) {
        this.$set(this.diagnosisParams, inputKeyObj[submitKey], "");
      }

      //组件值清空
      let componentsObj = {
        crowdCorrectSide:
          this.$refs["adjoinDislodge"] &&
          this.$refs["adjoinDislodge"].clearTeeth,
        toothExtraction:
          this.$refs["teethComponents"] &&
          this.$refs["teethComponents"].clearTeethType,
        notAvailableAttachments:
          this.$refs["teethComponents"] &&
          this.$refs["teethComponents"].clearTeethType,
        nonMoveableTeeth:
          this.$refs["teethComponents"] &&
          this.$refs["teethComponents"].clearTeethType,
        gapCorrect:
          this.$refs["intervalCorrect"] &&
          this.$refs["intervalCorrect"].clearTeeth,
      };
      let componentsKeyList = keys(componentsObj);
      if (
        componentsKeyList.includes(submitKey) &&
        [
          "unchooseable",
          "tooth_extraction_options_nono",
          "false",
          "reserved_gap",
        ].includes(key)
      ) {
        let type = {
          toothExtraction: 4,
          notAvailableAttachments: 3,
          nonMoveableTeeth: 2,
        }[submitKey];
        componentsObj[submitKey] && componentsObj[submitKey](type ? type : "");
      }

      // //拥挤的矫治互斥
      // let optionList = ['upjawExt','upjawLip','upjawFar','downjawExt',
      //   'downjawLip','downjawFar','upjawSide','downjawSide'];
      // if(optionList.includes(submitKey)){
      //   this.$set(this.diagnosisParams,'crowdCorrect',{});
      // }

      // 单选
      if (radio) {
        if (this.diagnosisParams[submitKey].key === key && !noClear) {
          return (this.diagnosisParams[submitKey] = {});
        }
        if (this.diagnosisParams[submitKey].key === key) return;
        if (submitKey === "overbiteInfo") {
          this.diagnosisParams.overbiteOtherInfo = "";
        }
        this.$set(this.diagnosisParams, submitKey, addItem);

        //拥挤的矫治  3D设计后再确定重置
        // if(key === 'after_design'){
        //   each(optionList, item => {
        //     this.$set(this.diagnosisParams,item,{})
        //   });
        // }

        //拟矫治牙颌 默认选中第一项
        if (
          submitKey === "toCorrectToothJaw" &&
          ["upjaw", "downjaw"].includes(key)
        ) {
          let list =
            key === "upjaw"
              ? this.caseOptions["to_correct_tooth_upjaw"]
              : this.caseOptions["to_correct_tooth_downjaw"];
          let currentObj = this.diagnosisParams.toCorrectToothJaw;
          let obj = extend({}, currentObj, {
            children: [
              {
                key: list[0].key,
                value: list[0].value,
                submitKey: {
                  upjaw: "toCorrectToothUpjaw",
                  downjaw: "toCorrectToothDownjaw",
                }[key],
              },
            ],
          });
          this.$set(this.diagnosisParams, "toCorrectToothJaw", obj);
        }
      } else {
        if (isArray(this.diagnosisParams[submitKey]["children"])) {
          let itx;
          each(this.diagnosisParams[submitKey]["children"], (item, index) => {
            if (item.key === key) {
              itx = index;
            }
          });
          if (itx !== undefined && !noClear) {
            return this.diagnosisParams[submitKey]["children"].splice(itx, 1);
          }
          if (itx !== undefined && noClear) {
            frontToothInDefault();
            return "";
          }

          //1对多互斥
          if (oneOfMore) {
            let { one, more } = oneOfMore;
            if (key === one) {
              this.diagnosisParams[submitKey]["children"] = [];
              this.diagnosisParams[submitKey]["children"] = filter(
                this.diagnosisParams[submitKey]["children"],
                (it) => more.indexOf(it.key) === -1
              );
            }
            if (more.indexOf(key) !== -1) {
              this.diagnosisParams[submitKey]["children"] = filter(
                this.diagnosisParams[submitKey]["children"],
                (it) => it.key !== one
              );
            }
          }

          this.diagnosisParams[submitKey]["children"].push(addItem);
        } else {
          this.$set(this.diagnosisParams[submitKey], "children", []);
          this.diagnosisParams[submitKey]["children"].push(addItem);
        }

        frontToothInDefault();

        each(adultOptionsKey, (it) => {
          if (it === submitKey) {
            this.sortOption(it);
          }
        });
      }
    },
    saveData() {
      let dia = this.diagnosisParams;
      let obj = {};
      for (let i in dia) {
        let k;
        if (isObject(dia[i]) && keys(dia[i]).length) {
          // 一级对象
          if (dia[i].key) {
            obj[i] = dia[i].key;
          }
          for (let childrenI in dia[i]) {
            // 对象内数组
            if (
              isArray(dia[i][childrenI]) &&
              dia[i][childrenI].length &&
              dia[i].key
            ) {
              let length = Array.from(
                new Set(map(dia[i][childrenI], (item) => item.submitKey))
              ).length;
              let threeList = flattenDeep(
                filter(map(dia[i][childrenI], (item) => item["children"]))
              );
              if (threeList.length) {
                let listKeyList = [];
                each(threeList, (item) => {
                  if (!listKeyList.includes(item.submitKey)) {
                    listKeyList.push(item.submitKey);
                  }
                });
                // 8 每个一个字段
                if (listKeyList.length > 1) {
                  each(listKeyList, (submitKey) => {
                    obj[submitKey] = map(
                      filter(threeList, (item) => item.submitKey === submitKey),
                      (it) => it.key
                    ).join();
                  });
                } else {
                  //10 多用一个字段
                  let str = map(threeList, (item) => {
                    k = item.submitKey;
                    if (item.inputKey) {
                      obj[item.inputKey] = item.input;
                    }
                    return item.key;
                  }).join();
                  obj[k] = str;
                }
              }

              if (length === 1) {
                let str = map(dia[i][childrenI], (item) => {
                  k = item.submitKey;
                  if (item.inputKey) {
                    obj[item.inputKey] = item.input;
                  }
                  return item.key;
                }).join();
                obj[k] = str;
              } else if (length > 1) {
                let soleList = [];
                each(dia[i][childrenI], (item) => {
                  if (soleList.indexOf(item.submitKey) === -1) {
                    soleList.push(item.submitKey);
                  }
                  if (item.inputKey) {
                    obj[item.inputKey] = item.input;
                  }
                });
                each(soleList, (item) => {
                  obj[item] = map(
                    filter(dia[i][childrenI], (it) => it.submitKey === item),
                    (a) => a.key
                  ).join();
                });
              }
            }

            if (
              !dia[i].key &&
              isArray(dia[i][childrenI]) &&
              dia[i][childrenI].length
            ) {
              //用于区分数组内是否有不同的submitKey   例子11
              let list = [];
              each(dia[i][childrenI], (item) => {
                if (!list.includes(item.submitKey)) {
                  list.push(item.submitKey);
                }
              });
              if (list.length > 1) {
                each(list, (submitKey) => {
                  obj[submitKey] = map(
                    filter(
                      dia[i][childrenI],
                      (item) => item.submitKey === submitKey
                    ),
                    (it) => it.key
                  ).join();
                });
              } else {
                let str = map(dia[i][childrenI], (item) => {
                  if (item.inputKey) {
                    obj[item.inputKey] = item.input;
                  }
                  return item.key;
                }).join();
                obj[i] = str;
              }
            }
          }
        }
      }
      let {
        overbiteOtherInfo,
        specialInstructions,
        toothOtherProblem,
        toothOtherProblemTwo,
        clinicOtherInfo,
        underjawAbnormalFlagNote,
        growNailFlagNote,
        needPassiveApplianceNote,
      } = dia;

      let sumInterValList = filter(
        this.$refs["intervalCorrect"] &&
          this.$refs["intervalCorrect"].totalTeeth,
        (item) => item.width
      );
      let intervalList = map(sumInterValList, (it) => {
        return extend({}, it, { type: 5 });
      });
      let list = [];
      each(this.commonTeeth, (item) => {
        if (item.noMove) {
          list.push(extend({}, item, { type: 2 }));
        }
        if (item.noDesign) {
          list.push(extend({}, item, { type: 3 }));
        }
        if (item.remove) {
          list.push(extend({}, item, { type: 4 }));
        }
        if (item.deficiency) {
          list.push(extend({}, item, { type: 1 }));
        }
        if (item.number > 48) {
          list.push(extend({}, item, { type: 7 }));
        }
      });
      let toothInfoList = filter(
        [...list, ...intervalList],
        (item) => item.number > 0
      );

      let imgObj = pickBy({
        sideFacePicture: dia.sideFacePicture, // 侧面像
        frontFacePicture: dia.frontFacePicture, // 正面像
        smilePicture: dia.smilePicture, // 微笑像
        upToothPicture: dia.upToothPicture, // 上牙列像
        downToothPicture: dia.downToothPicture, // 下牙列像
        mouthRightPicture: dia.mouthRightPicture, // 口内右侧位像
        mouthFrontPicture: dia.mouthFrontPicture, // 口内正位像
        mouthLeftPicture: dia.mouthLeftPicture, // 口内左侧位像
        xrayCurvePicture: dia.xrayCurvePicture, // 全颌曲面断层片
        xraySidePicture: dia.xraySidePicture, // 头颅侧位定位片
        anteriorOverjetPicture: dia.anteriorOverjetPicture, //前牙覆盖像
        otherPicture:
          dia.otherPicture && dia.otherPicture.length ? dia.otherPicture : "", // 其他影像资料
      });
      let {
        acceptWisdomTeethExtraction,
        acceptPremolarsExtraction,
        acceptDecayedTeethExtraction,
        acceptIpr,
        undergoTeethRestoration,
        implantAnchoragePlacement,
        cbctPhotography,
        orthodonticsTreatmentBefore,
      } = obj;
      let PicObj = {};
      for (const item in imgObj) {
        if (item !== "otherPicture") {
          PicObj[item] = JSON.stringify(this.isJSON(imgObj[item]));
        } else {
          try {
            JSON.parse(imgObj[item]);
            PicObj[item] = imgObj[item];
          } catch (error) {
            let list = [];
            map(imgObj[item].split(","), (item) => {
              list.push(this.isJSON(item));
            });
            PicObj[item] = JSON.stringify(list);
          }
        }
      }
      obj = extend(
        {},
        PicObj,
        obj,
        {
          acceptWisdomTeethExtraction: this.needRTD
            ? acceptWisdomTeethExtraction
            : "",
          acceptPremolarsExtraction: this.needRTD
            ? acceptPremolarsExtraction
            : "",
          acceptDecayedTeethExtraction: this.needRTD
            ? acceptDecayedTeethExtraction
            : "",
          acceptIpr: this.needRTD ? acceptIpr : "",
          undergoTeethRestoration: this.needRTD ? undergoTeethRestoration : "",
          implantAnchoragePlacement: this.needRTD
            ? implantAnchoragePlacement
            : "",
          cbctPhotography: this.needRTD ? cbctPhotography : "",
          orthodonticsTreatmentBefore: this.needRTD
            ? orthodonticsTreatmentBefore
            : "",
        },
        pickBy({
          overbiteOtherInfo,
          specialInstructions,
          toothOtherProblem,
          toothOtherProblemTwo,
          clinicOtherInfo,
          underjawAbnormalFlagNote,
          growNailFlagNote,
          toothInfoList: toothInfoList.filter((item) => item.number !== "0"),
          needPassiveApplianceNote,
          caseId: dia.caseId || this.$route.query.caseId,
          id: dia.id,
          digitalModelFile: dia.digitalModelFile, //数字模型
          siliconeRubberModel: dia.siliconeRubberModel, // 硅橡胶模型
        })
      );
      this.isLoading = true;
      if (!dia.id) {
        return createCurePlan(pickBy(obj))
          .then((data) => {
            this.isLoading = false;
            this.diagnosisParams.id = data;
            notifyMsg(this, "success", this.$t("common.message.yzc"), 2500);
            changeRouteQuery(this, { curePlanId: data });
            return Promise.resolve();
          })
          .catch(() => {
            this.isLoading = false;
            this.$emit("saveError");
            return Promise.reject();
          });
      } else {
        return editCurePlan(pickBy(obj))
          .then(() => {
            this.isLoading = false;
            notifyMsg(this, "success", this.$t("common.message.yzc"), 2500);
            changeRouteQuery(this, { curePlanId: dia.id });
            return Promise.resolve();
          })
          .catch(() => {
            this.isLoading = false;
            this.$emit("saveError");
            return Promise.reject();
          });
      }
    },
    isJSON(str) {
      if (str == "") {
        return "";
      }
      if (typeof str == "string") {
        try {
          JSON.parse(str);
          let obj = JSON.parse(str);
          if (!obj.nanoId) {
            obj.nanoId = obj.ETag;
          }
          return obj;
        } catch (error) {
          return { ETag: str, nanoId: str };
        }
      }
    },
    //页面滚动
    handleScroll() {
      if (this.isScroll) {
        this.isScroll = false;
        return new Promise((resolve) => {
          return setTimeout(() => {
            const TOP =
              document.documentElement && document.documentElement.scrollTop;
            let obj = {};
            let {
              d1,
              d2,
              d3,
              d4,
              d5,
              d6,
              d7,
              d8,
              d9,
              d10,
              d11,
              d12,
              d13,
              d14,
              d15,
              d16,
              d17,
              d18,
            } = this.leftTopObj;
            obj = {
              [TOP < d1]: "d1",
              [TOP < d2 && TOP >= d1]: "d1",
              [TOP < d3 && TOP >= d2]: "d2",
              [TOP < d4 && TOP >= d3]: "d3",
              [TOP < d5 && TOP >= d4]: "d4",
              [TOP < d6 && TOP >= d5]: "d5",
              [TOP < d7 && TOP >= d6]: "d6",
              [TOP < d8 && TOP >= d7]: "d7",
              [TOP < d9 && TOP >= d8]: "d8",
              [TOP < d10 && TOP >= d9]: "d9",
              [TOP < d11 && TOP >= d10]: "d10",
              [TOP < d12 && TOP >= d11]: "d11",
              [TOP < d13 && TOP >= d12]: "d12",
              [TOP < d14 && TOP >= d13]: "d13",
              [TOP < d15 && TOP >= d14]: "d14",
              [TOP < d16 && TOP >= d15]: "d15",
              [TOP < d17 && TOP >= d16]: "d16",
              [TOP < d18 && TOP >= d17]: "d17",
              [TOP >= d18]: "d18",
            };
            this.selectLeftKey = obj[true] || (this.needRTD ? "d7" : "d19");
            this.leftHandleScroll(Math.floor(TOP / 8));
            this.isScroll = true;
            return resolve();
          }, 500);
        });
      }
    },
    initOption({ type, submitKey, addItem }) {
      // type: 1 一级对象, 2 数组
      let data = this.details;
      let valueData = this.diaKeyObj;
      let dia = this.diagnosisParams;
      if (data[submitKey]) {
        if (type === 1) {
          dia[submitKey] = {
            submitKey,
            key: data[submitKey],
            value: valueData[data[submitKey]],
          };
        } else if (type === 2) {
          dia[submitKey] = {
            children: map(
              data[submitKey] && data[submitKey].split(","),
              (item) => {
                return { key: item, value: valueData[item], submitKey };
              }
            ),
          };
        } else if (type === 3) {
          this.$set(this.diagnosisParams, submitKey, addItem);
        }
      }
    },
    // 数据初始化
    initParams(data) {
      //预览提交的返回
      const diagnsisLeftKey = sessionStorage.getItem("diagnosisKey") || 1;
      this.selectLeft(
        diagnsisLeftKey !== "undefined" ? diagnsisLeftKey : 1,
        "init"
      );
      let imgObj = pickBy({
        sideFacePicture: data.sideFacePicture, // 侧面像
        frontFacePicture: data.frontFacePicture, // 正面像
        smilePicture: data.smilePicture, // 微笑像
        upToothPicture: data.upToothPicture, // 上牙列像
        downToothPicture: data.downToothPicture, // 下牙列像
        mouthRightPicture: data.mouthRightPicture, // 口内右侧位像
        mouthFrontPicture: data.mouthFrontPicture, // 口内正位像
        mouthLeftPicture: data.mouthLeftPicture, // 口内左侧位像
        xrayCurvePicture: data.xrayCurvePicture, // 全颌曲面断层片
        xraySidePicture: data.xraySidePicture, // 头颅侧位定位片
        otherPicture: data.otherPicture, // 其他影像资料
        anteriorOverjetPicture: data.anteriorOverjetPicture, //前牙覆盖像
      });
      extend(this.diagnosisParams, imgObj);
      let dia = this.diagnosisParams;
      let valueData = this.diaKeyObj;

      this.diagnosisParams.digitalModelFile = data.digitalModelFile; // 数字模型
      this.diagnosisParams.siliconeRubberModel = data.siliconeRubberModel; // 硅橡胶模型

      let noMoveList = filter(data.toothInfoList, (item) => item.type === "2");
      let deficiencyList = filter(
        data.toothInfoList,
        (item) => item.type === "1"
      );
      let noDesignList = filter(
        data.toothInfoList,
        (item) => item.type === "3"
      );
      let removeList = filter(data.toothInfoList, (item) => item.type === "4");
      let mainList = filter(
        data.toothInfoList,
        (item) => item.type === "7"
      ).concat(adultTeethList);
      let untreatedList = [
        ...noMoveList,
        ...noDesignList,
        ...removeList,
        ...deficiencyList,
      ];
      if (untreatedList.length) {
        let teethTypeObj = {
          2: "noMove",
          3: "noDesign",
          4: "remove",
          1: "deficiency",
        };
        this.commonTeeth = filter(
          map(mainList, (item) => {
            let obj;
            each(untreatedList, (it) => {
              if (item.number === it.number && !item.nextNumber) {
                obj = extend({}, item, obj, {
                  [teethTypeObj[it.type]]: teethTypeObj[it.type],
                });
              }
            });
            return obj ? obj : item;
          })
        );
      } else {
        this.commonTeeth = data.toothInfoList
          ? filter(data.toothInfoList, (item) => !item.nextNumber).concat(
              adultTeethList
            )
          : adultTeethList;
      }

      //间隙的矫治
      this.uploadIntervalList = filter(
        data.toothInfoList,
        (item) => item.type === "5"
      );

      // 1
      this.initOption({ type: 2, submitKey: "toothProblem" });
      dia.toothOtherProblem = data.toothOtherProblem || "";
      this.initOption({ type: 2, submitKey: "jawProblem" });

      // 2
      this.initOption({ type: 2, submitKey: "toothProblemTwo" });
      this.initOption({ type: 1, submitKey: "jawProblemTwo" });
      dia.toothOtherProblemTwo = data.toothOtherProblemTwo || "";

      // 3
      this.initOption({ type: 1, submitKey: "angleClassification" });
      this.initOption({ type: 2, submitKey: "clinicInfo" });
      this.initOption({
        type: 1,
        submitKey: "sagittalMaxillomandibularClassification",
      });
      dia.clinicOtherInfo = data.clinicOtherInfo;

      // 4
      if (data["toCorrectToothJaw"]) {
        let isShowTowKey =
          data.toCorrectToothUpjaw || data.toCorrectToothDownjaw;
        let twoKeyList = ["upjaw", "downjaw"].includes(data.toCorrectToothJaw);
        let twoSubmitKey =
          isShowTowKey && twoKeyList
            ? data.toCorrectToothUpjaw
              ? "toCorrectToothUpjaw"
              : "toCorrectToothDownjaw"
            : "";
        let list;
        if (twoSubmitKey) {
          list = map(
            data[twoSubmitKey] && split(data[twoSubmitKey]),
            (item) => {
              return pickBy({
                key: item,
                value: valueData[item],
                submitKey: twoSubmitKey,
              });
            }
          );
        }
        let addItem = pickBy({
          submitKey: "toCorrectToothJaw",
          key: data["toCorrectToothJaw"],
          value: valueData[data["toCorrectToothJaw"]],
          children: isArray(list) && list.length ? list : "",
        });
        this.initOption({ type: 3, submitKey: "toCorrectToothJaw", addItem });
      }

      // 6
      if (data["nonMoveableTeeth"]) {
        this.initOption({ type: 1, submitKey: "nonMoveableTeeth" });
      }

      // 7
      if (data["notAvailableAttachments"]) {
        this.initOption({ type: 1, submitKey: "notAvailableAttachments" });
      }

      // 8
      if (data["rightSagittalRelation"]) {
        let list = split(data["rightSagittalRelationNote"], ",");
        let addList = map(list, (item) => {
          return pickBy({
            key: item,
            submitKey: "rightSagittalRelationNote",
            value: valueData[item],
          });
        });
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "rightSagittalRelation",
          value: valueData[data["rightSagittalRelation"]],
          key: data["rightSagittalRelation"],
        });
        this.initOption({
          type: 3,
          submitKey: "rightSagittalRelation",
          addItem,
        });
        let threeList = [
          "rightSagittalRelationFar",
          "rightSagittalRelationBitJump",
          "rightSagittalRelationExtraction",
        ];
        each(threeList, (submitKey) => {
          if (data[submitKey]) {
            this.initOption({ type: 2, submitKey });
          }
        });
      }
      if (data["leftSagittalRelation"]) {
        let list = split(data["leftSagittalRelationNote"], ",");
        let addList = map(list, (item) => {
          return pickBy({
            key: item,
            submitKey: "leftSagittalRelationNote",
            value: valueData[item],
          });
        });
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "leftSagittalRelation",
          value: valueData[data["leftSagittalRelation"]],
          key: data["leftSagittalRelation"],
        });
        this.initOption({
          type: 3,
          submitKey: "leftSagittalRelation",
          addItem,
        });

        let threeList = [
          "leftSagittalRelationFar",
          "leftSagittalRelationBitJump",
          "leftSagittalRelationExtraction",
        ];
        each(threeList, (submitKey) => {
          if (data[submitKey]) {
            this.initOption({ type: 2, submitKey });
          }
        });
      }

      // 9
      if (data["coverInfo"]) {
        this.initOption({ type: 1, submitKey: "coverInfo" });
      }

      // 10
      if (data["overbiteInfo"]) {
        let overbiteInfoOpen = filter(split(data["overbiteInfoOpen"], ","));
        let overbiteInfoDeep = filter(split(data["overbiteInfoDeep"], ","));
        let list = [...overbiteInfoOpen, ...overbiteInfoDeep];
        let twoList = ["press_front", "press_front_rise_back"];
        let addList;
        let threeKey = {
          press_front: data["overbiteInfoIntrudeAnterior"],
          press_front_rise_back: data["overbiteInfoPosteriorsExrusion"],
        };
        let threeSubKey = {
          press_front: "overbiteInfoIntrudeAnterior",
          press_front_rise_back: "overbiteInfoPosteriorsExrusion",
        };
        let isThree = filter([
          data["overbiteInfoIntrudeAnterior"],
          data["overbiteInfoPosteriorsExrusion"],
        ]);
        addList = map(list, (item) => {
          return pickBy({
            key: item,
            submitKey: overbiteInfoOpen.length
              ? "overbiteInfoOpen"
              : "overbiteInfoDeep",
            value: valueData[item],
            children:
              twoList.includes(item) && isThree.length
                ? map(split(threeKey[item], ","), (three) => {
                    return {
                      key: three,
                      value: valueData[three],
                      submitKey: threeSubKey[item],
                    };
                  })
                : "",
          });
        });
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "overbiteInfo",
          value: valueData[data["overbiteInfo"]],
          key: data["overbiteInfo"],
        });
        this.initOption({ type: 3, submitKey: "overbiteInfo", addItem });
        dia.overbiteOtherInfo = data.overbiteOtherInfo;
      }

      // 11
      if (data["frontToothInfo"]) {
        let addList = filter(
          map(split(data["frontToothInfo"], ","), (item) => {
            return pickBy({
              key: item,
              value: valueData[item],
              submitKey: "frontToothInfo",
            });
          }),
          (it) => it.key
        );
        let addList2 = filter(
          map(split(data["correctFrontReverse"], ","), (item) => {
            return pickBy({
              key: item,
              value: valueData[item],
              submitKey: "correctFrontReverse",
            });
          }),
          (it) => it.key
        );
        let addList3 = filter(
          map(split(data["correctFrontOpposite"], ","), (item) => {
            return {
              key: item,
              value: valueData[item],
              submitKey: "correctFrontOpposite",
            };
          }),
          (it) => it.key
        );
        let list = [...addList, ...addList2, ...addList3];
        let addItem = pickBy({
          children: isArray(list) && list.length ? list : "",
        });
        this.initOption({ type: 3, submitKey: "frontToothInfo", addItem });
      }

      // 12
      if (data["backToothInfo"]) {
        let addList = map(split(data["backToothInfoCorrect"], ","), (item) => {
          return {
            key: item,
            value: valueData[item],
            submitKey: "backToothInfoCorrect",
          };
        });
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "backToothInfo",
          value: valueData[data["backToothInfo"]],
          key: data["backToothInfo"],
        });
        this.initOption({ type: 3, submitKey: "backToothInfo", addItem });
      }

      // 13
      if (data["middleLine"]) {
        let twoSubmitKey = filter([
          data["upjawMiddleLineNote"],
          data["downjawMiddleLineNote"],
        ]);
        let addList = [];
        let obj, obj1;
        if (twoSubmitKey.length) {
          obj = {
            key: data["upjawMiddleLineNote"],
            value: valueData[data["upjawMiddleLineNote"]],
            submitKey: "upjawMiddleLineNote",
            input: data["upjawMiddleLine"],
            inputKey: "upjawMiddleLine",
          };
          obj1 = {
            key: data["downjawMiddleLineNote"],
            value: valueData[data["downjawMiddleLineNote"]],
            submitKey: "downjawMiddleLineNote",
            input: data["downjawMiddleLine"],
            inputKey: "downjawMiddleLine",
          };
          addList.push(obj, obj1);
        }
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "middleLine",
          value: valueData[data["middleLine"]],
          key: data["middleLine"],
        });
        this.initOption({ type: 3, submitKey: "middleLine", addItem });
      }

      //14
      if (data["upjawGapCorrect"]) {
        this.initOption({ type: 1, submitKey: "upjawGapCorrect" });
      }
      if (data["downjawGapCorrect"]) {
        this.initOption({ type: 1, submitKey: "downjawGapCorrect" });
      }
      if (data["gapCorrect"]) {
        this.initOption({ type: 2, submitKey: "gapCorrect" });
      }

      // 15{
      if (data["acceptWisdomTeethExtraction"]) {
        this.initOption({ type: 1, submitKey: "acceptWisdomTeethExtraction" });
      }
      if (data["acceptPremolarsExtraction"]) {
        this.initOption({ type: 1, submitKey: "acceptPremolarsExtraction" });
      }
      if (data["acceptDecayedTeethExtraction"]) {
        this.initOption({ type: 1, submitKey: "acceptDecayedTeethExtraction" });
      }
      if (data["acceptIpr"]) {
        this.initOption({ type: 1, submitKey: "acceptIpr" });
      }
      if (data["undergoTeethRestoration"]) {
        this.initOption({ type: 1, submitKey: "undergoTeethRestoration" });
      }
      if (data["implantAnchoragePlacement"]) {
        this.initOption({ type: 1, submitKey: "implantAnchoragePlacement" });
      }
      if (data["cbctPhotography"]) {
        this.initOption({ type: 1, submitKey: "cbctPhotography" });
      }
      if (data["orthodonticsTreatmentBefore"]) {
        this.initOption({ type: 1, submitKey: "orthodonticsTreatmentBefore" });
      }
      let optionList = [
        "upjawExt",
        "upjawLip",
        "upjawFar",
        "downjawExt",
        "downjawLip",
        "downjawFar",
        "crowdCorrectSide",
        "crowdCorrect",
      ];
      for (let i in data) {
        each(optionList, (correct) => {
          if (correct === i) {
            if (["upjawFar", "downjawFar"].includes(correct)) {
              let list = map(split(data[i], ","), (item) => {
                return {
                  key: item,
                  value: valueData[item],
                  submitKey: correct,
                };
              });
              this.$set(
                this.diagnosisParams,
                i,
                pickBy({
                  children: list.length ? list : "",
                })
              );
            } else {
              this.$set(this.diagnosisParams, i, {
                key: data[i],
                value: valueData[data[i]],
                submitKey: correct,
              });
            }
          }
        });
      }
      if (data["upjawSide"]) {
        let addList;
        let optionsList = [
          "upjawSideAnterior",
          "upjawSideRight",
          "upjawSideLeft",
        ];
        addList = filter(
          map(optionsList, (item) => {
            return {
              key: data[item],
              value: valueData[data[item]],
              submitKey: item,
            };
          }),
          (it) => it.key
        );
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "upjawSide",
          value: valueData[data["upjawSide"]],
          key: data["upjawSide"],
        });
        this.initOption({ type: 3, submitKey: "upjawSide", addItem });
      }
      if (data["downjawSide"]) {
        let addList;
        let optionsList = [
          "downjawSideAnterior",
          "downjawSideRight",
          "downjawSideLeft",
        ];
        addList = filter(
          map(optionsList, (item) => {
            return {
              key: data[item],
              value: valueData[data[item]],
              submitKey: item,
            };
          }),
          (it) => it.key
        );
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "downjawSide",
          value: valueData[data["downjawSide"]],
          key: data["downjawSide"],
        });
        this.initOption({ type: 3, submitKey: "downjawSide", addItem });
      }
      //拔牙
      if (data["toothExtraction"]) {
        this.initOption({ type: 1, submitKey: "toothExtraction" });
      }

      //16
      if (data["growNailFlag"]) {
        let optionsList = [
          "upGrowNailPosition",
          "downGrowNailPosition",
          "growNailPurpose",
        ];
        let lt = [];
        each(optionsList, (submitKey) => {
          let list = map(split(data[submitKey], ","), (item) => {
            return {
              key: item,
              value: valueData[item],
              submitKey: submitKey,
            };
          });
          lt.push(list);
        });
        let list = flattenDeep(lt);
        let addItem = pickBy({
          children: isArray(list) && list.length ? list : "",
          submitKey: "growNailFlag",
          value: valueData[data["growNailFlag"]],
          key: data["growNailFlag"],
        });
        this.initOption({ type: 3, submitKey: "growNailFlag", addItem });
      }
      dia.growNailFlagNote = data.growNailFlagNote || "";

      //17
      if (data["underjawAbnormalFlag"]) {
        this.initOption({ type: 1, submitKey: "underjawAbnormalFlag" });
        dia.underjawAbnormalFlagNote = data.underjawAbnormalFlagNote;
      }

      //18
      if (data["needPassiveAppliance"]) {
        this.initOption({ type: 1, submitKey: "needPassiveAppliance" });
        dia.needPassiveApplianceNote = data.needPassiveApplianceNote;
      }

      // 19
      if (data["specialInstructions"]) {
        dia.specialInstructions = data.specialInstructions;
      }
      return Promise.resolve();
    },
    getCaseOptions() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      let id = this.queryCurePlanId;
      this.isLoading = true;
      getDictAllList([
        // 获取病例选项的值
        "tooth_problem",
        "jaw_problem",
        "jaw_problem_two",
        "tooth_problem_two",
        "angle_classification",
        "sagittal_maxillomandibular_classification",
        "clinic_info",
        "bad_habits",
        "dentition",
        "to_correct_tooth_jaw",
        "to_correct_tooth_upjaw",
        "to_correct_tooth_downjaw",
        "non_moveable_teeth",
        "not_available_for_attachments",
        "sagittal_relation",
        "left_sagittal_relation",
        "right_sagittal_relation",
        "improve_tip",
        "precision_cut",
        "updown_jaw_gap_correct",
        "gap_correct",
        "underjaw_abnormal_flag",
        "grow_nail_flag",
        "grow_nail_position",
        "grow_nail_purpose",

        "improve_molar",
        "up_horizontal_relationship",
        "down_horizontal_relationship",
        "cover_info",
        "overbite_info",
        "correct_open",
        "correct_deep_cover",
        "updown_jaw",
        "front_tooth_info",
        "correct_front_reverse",
        "back_tooth_info",
        "back_tooth_info_correct",
        "correct_front_reverse_two",
        "correct_front_opposite",
        "choose_option_b",
        "middle_line",
        "tooth_extraction",
        "improve_middle_move",
        "to_correct_tooth_downjaw",
        "tooth_extraction_options",
        "choose_option",
        "choose_option_b",
        "upjaw_far",
        "downjaw_far",
        "accept_decayed_teeth_extraction",
        "undergo_teeth_restoration",
      ])
        .then((data) => {
          this.isLoading = false;
          let dictObj = {},
            diaKeyObj = {};
          data.forEach((item) => {
            if (!dictObj[item.parentKey]) {
              dictObj[item.parentKey] = [item];
            } else {
              dictObj[item.parentKey].push(item);
            }
            diaKeyObj[item.key] = item.value;
          });
          this.diaKeyObj = diaKeyObj;
          this.caseOptions = dictObj;

          this.sagittalRelationshipOption = this.caseOptions[
            "improve_tip"
          ].slice(0, 2);
          this.sagittalRelationshipOptionTow = this.caseOptions[
            "improve_tip"
          ].slice(2, 3);
          this.sagittalRelationshipOptionThree = this.caseOptions[
            "improve_tip"
          ].slice(3, 4);
          this.sagittalRelationshipOptionFour = this.caseOptions[
            "improve_tip"
          ].slice(4, 5);

          this.diagnosisParams.caseId = this.$route.query.caseId;
          if (id) {
            this.diagnosisParams.id = id;
            detailCurePlan(id).then((obj) => {
              this.details = obj;
              this.initParams(obj).then(() => {
                setTimeout(() => {
                  this.isResponse = 0;
                }, 800);
              });
            });
            cureProblemList({
              caseId: this.queryCaseId,
              curePlanId: id,
              backNode: "to_submit",
            }).then((data) => {
              const diagnosisProblem = {};
              let gnType = "groupNo";
              data.forEach((item) => {
                diagnosisProblem[item[gnType]] = item.content;
              });
              this.problemList = data.filter(
                (item) => +item[gnType] < 20 && +item[gnType] > 0
              );

              this.isProductErr = this.problemList.length
                ? false
                : !some(data, (item) => +item.groupNo === 0);

              this.diagnosisProblem = diagnosisProblem;
              setTimeout(() => {
                if ($(".dia_problem").length > 0) {
                  this.pEleHeight = $(".dia_problem").height() + 30;
                }
              }, 100);
            });
          } else {
            setTimeout(() => {
              this.isResponse = 0;
            }, 800);
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  components: {
    ChildrenTeeth,
    IntervalCorrect,
    FullLoading,
    AdjoinDislodge,
    ReserveDinterval,
  },
  mounted() {
    this.rightHeight = window.getComputedStyle(this.$refs["rightTable"]).height;
    each(this.leftList, (item, index) => {
      if (document.getElementById(index + 1)) {
        this.leftTopObj["d" + (index + 1)] = document.getElementById(
          index + 1
        ).offsetTop;
      }
    });
    window.addEventListener("scroll", this.handleScroll, false);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, false);
  },
  created() {
    this.isResponse += 1;
    this.getCaseOptions();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .mui-popover {
  position: fixed;
}
.dia_problem {
  width: 100%;
  background: #f4f4f4;
  padding: 10px 16px 30px;
  margin-bottom: 30px;
  h5 {
    font-size: 0.22rem;
    color: $main_theme_color_333;
  }
  span {
    margin-right: 0.2rem;
  }
  .pro_box {
    word-break: break-all;
    font-size: 0.16rem;
    line-height: 0.3rem;
    color: $main_theme_color_333;
  }
}

/deep/.el-scrollbar__wrap {
  overflow-x: hidden !important;
}
.sagittal_relationship_input_mm {
  position: absolute;
  right: 0;
  top: 0.08rem;
}
.midcourt_line_input_mm {
  position: absolute;
  right: 0.6rem;
  top: 0.1rem;
}
.midcourt_line_input_mm_two {
  position: absolute;
  right: 0.6rem;
  top: 0;
}
.table_wrap {
  display: flex;
  margin-bottom: -1rem;
  .left_option {
    width: 2.4rem;
    height: 6.1rem;
    background-color: #fff;
    position: fixed;
    .left_option_item {
      width: 100%;
      height: 0.5rem;
      transition: all 0.2s;
      font-size: 0.16rem;
      color: $main_theme_color_333;
      cursor: pointer;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 0 0.12rem;
      .left_wire {
        width: 4px;
        height: 32px;
        background: $main_theme_color;
        position: absolute;
        left: 0;
      }
    }
    .left_option_item:hover {
      transition: all 0.2s;
      background: #f9f9f9;
    }
  }
  .right_table {
    margin-left: 2.4rem;
    width: calc(100% - 2.4rem);
    background-color: #fff;
    padding: 0.3rem 0.2rem 0.5rem 0.59rem;
    border-left: 1px solid #e5e5e5;
    .select_type {
      margin-bottom: 30px;
      .select_type_title {
        font-size: 0.16rem;
        color: $main_theme_color_333;
      }
    }
  }
}
.input_mm {
  position: absolute;
  right: 0.2rem;
  top: 0.08rem;
}
.common_btn {
  position: relative;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.2rem;
  color: $main_theme_color_666;
  border-radius: 4px;
  border: 0.01rem solid #bbbbbb;
  cursor: pointer;
  font-size: 0.14rem;
  margin-bottom: 0.1rem;
  white-space: nowrap;
  user-select: none;
  background-color: #fff;
}

.is_active {
  background-color: $main_theme_color;
  color: $main_theme_color_666 !important;
  border: 0.01rem solid $main_theme_color;
}
.remark_box {
  width: 100%;
  margin-top: 0.1rem;
  textarea {
    width: 90%;
    border: 0.01rem solid #c8c8c8;
    border-radius: 0.08rem;
    padding: 0.2rem;
    color: #333;
    min-height: 1.34rem;
  }
}
.option_left_title {
  min-width: 0.7rem;
  font-size: 14px;
  color: $main_theme_color_666;
}
.single_tip_box {
  color: $main_theme_color_333;
  background-color: #f4f4f4;
  font-size: 0.14rem;
  padding: 0.15rem 0.2rem;
  p {
    margin-bottom: 0.1rem;
    &:last-child {
      margin-bottom: 0;
    }
    input {
      width: 100%;
      outline: none;
      border: none;
    }
  }
}
.popover_con_right_iup {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.2rem;
  border: 0.01rem solid #c8c8c8;
  width: 80%;
  height: 0.4rem;
  border-radius: 0.06rem;
  padding-right: 0.17rem;
  input {
    width: 50%;
    padding: 0.13rem 0.9rem 0.13rem 0.15rem;
    font-size: 0.14rem;
    flex: 1;
    outline: none;
    border: none;
  }
}
.single_con {
  color: $main_theme_color_333;
  font-size: 0.16rem;
  position: relative;
  .remark_box {
    width: 100%;
    textarea {
      width: 100%;
      border: 0.01rem solid #c8c8c8;
      border-radius: 0.08rem;
      padding: 0.2rem;
      color: #333;
      min-height: 1.34rem;
    }
  }
  .lc_box {
    background-color: #f9f9f9;
    padding: 0.3rem 0.2rem 0.16rem 0.3rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.14rem;
    color: #333;
    .check_box {
      flex: 1;
    }
    .el-checkbox-group {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      .check_single {
        margin: 0 0.1rem 0.2rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .other_box {
      width: 2.81rem;
      .tit {
        margin-bottom: 0.12rem;
      }
      textarea {
        width: 100%;
        height: 0.98rem;
        border-radius: 0.04rem;
        background-color: #fff;
        padding: 0.1rem;
        box-sizing: border-box;
      }
    }
  }
  .single_c_head {
    margin-bottom: 0.3rem;
  }
  .s_c_box {
    display: flex;
    .s_c_b_left {
      margin-right: 0.2rem;
      margin-top: 0.13rem;
      color: $main_theme_color_333;
    }
    .t_b_p {
      color: $main_theme_color_666;
      min-width: 0.64rem;
      margin-right: 20px;
      position: relative;
      top: -4px;
    }
    .t_b_tip {
      color: $main_theme_color_999;
      font-size: 0.14rem;
    }
  }
}
.t_b_box {
  display: flex;
  align-items: center;
  font-size: 0.16rem;
  flex-wrap: wrap;
  margin-bottom: 0.16rem;
  line-height: 0.2rem;
}
.popover_con {
  display: flex;
  .popover_con_left {
    min-width: 0.3rem;
    white-space: nowrap;
    padding-top: 0.12rem;
    margin-right: 10px;
    color: $main_theme_color_333;
  }
  .popover_con_right {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    span {
      margin-top: 0.1rem;
      margin-bottom: 0.1rem;
    }
    .popover_con_right_p {
      margin-bottom: 0.1rem;
    }
    .popover_con_right_iup {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 0.2rem;
      border: 0.01rem solid #c8c8c8;
      width: 80%;
      height: 0.4rem;
      border-radius: 0.06rem;
      padding-right: 0.17rem;
      input {
        padding: 0.13rem 0 0.13rem 0.15rem;
        font-size: 0.14rem;
        flex: 1;
        outline: none;
        border: none;
      }
    }
  }
}
.left_active {
  color: $main_theme_color !important;
}
.popover_db {
  display: flex;
  justify-content: center;
  align-items: center;
  .popover_db_left {
    border-left: 0.01rem solid #c8c8c8;
    border-bottom: 0.01rem solid #c8c8c8;
    width: 0.2rem;
    height: 0.3rem;
  }
  .popover_db_right {
    display: flex;
    padding-top: 0.4rem;
    span {
      margin-left: 0;
      margin-right: 0.2rem;
    }
  }
}
.problem_note {
  word-break: break-all;
  color: $main_theme_color;
  margin-bottom: 0.2rem;
  line-height: 0.22rem;
  font-size: 0.14rem;
  margin-top: 4px;
}
</style>

