var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table_wrap"},[_c('div',{staticClass:"left_option"},_vm._l((_vm.leftList),function(item,index){return _c('div',{key:index,staticClass:"left_option_item",class:{ left_active: _vm.selectLeftKey === ("d" + (index + 1)) },on:{"click":function($event){return _vm.selectLeft(index)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectLeftKey === ("d" + (index + 1))),expression:"selectLeftKey === `d${index + 1}`"}],staticClass:"left_wire"}),_vm._v(" "+_vm._s(item.title)+" ")])}),0),_c('div',{ref:"rightTable",staticClass:"right_table"},[(_vm.problemList && _vm.problemList.length > 0)?_c('div',{staticClass:"dia_problem"},[_c('h5',[_vm._v(_vm._s(_vm.$t("cases.createImage.czrxwt")))]),_c('div',{staticClass:"pro_box"},_vm._l((_vm.problemList),function(item,index){return _c('span',{key:((item.id) + "_" + index)},[_vm._v(_vm._s(item.groupNo)+" "+_vm._s(item.groupName))])}),0)]):_vm._e(),_c('div',{staticClass:"select_type",attrs:{"id":"1"}},[_c('div',{staticClass:"flex-y-c mb10"},[_vm._m(0),_c('p',{staticClass:"fz16 main_theme_color_333"},[_vm._v(" 1."+_vm._s(_vm.$t("cases.createDiagnosis.exgjsfczyc"))+" ")]),_c('p',{staticClass:"main_theme_color_999 fz14 ml20"},[_vm._v(" "+_vm._s(_vm.$t("cases.createDiagnosis.exgjsfczycinit"))+" ")])]),(_vm.diagnosisProblem['1'])?_c('p',{staticClass:"problem_note"},[_vm._v(" "+_vm._s(_vm.diagnosisProblem["1"])+" ")]):_vm._e(),_c('div',{staticClass:"flex-y-c"},_vm._l((_vm.caseOptions['underjaw_abnormal_flag']),function(item,index){return _c('span',{key:index,staticClass:"common_btn",class:{
            is_active: item.key === _vm.diagnosisParams.underjawAbnormalFlag.key,
          },on:{"click":function($event){return _vm.levelOneClick({
              addItem: {
                key: item.key,
                value: item.value,
                submitKey: 'underjawAbnormalFlag',
              },
            })}}},[_vm._v(_vm._s(item.value))])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.diagnosisParams.underjawAbnormalFlag.key === 'true'),expression:"diagnosisParams.underjawAbnormalFlag.key === 'true'"}],staticClass:"remark_box"},[_c('el-input',{staticClass:"tex_b_a",attrs:{"disabled":_vm.isBackDisabled,"autosize":{ minRows: 4, maxRows: 6 },"maxlength":"2000","type":"textarea","show-word-limit":"","placeholder":_vm.$t('common.common.qsrnr')},model:{value:(_vm.diagnosisParams.underjawAbnormalFlagNote),callback:function ($$v) {_vm.$set(_vm.diagnosisParams, "underjawAbnormalFlagNote", $$v)},expression:"diagnosisParams.underjawAbnormalFlagNote"}})],1)]),_c('div',{staticClass:"select_type",attrs:{"id":"2"}},[_c('div',{staticClass:"flex-y-c mb10"},[_vm._m(1),_c('p',{staticClass:"fz16 main_theme_color_333"},[_vm._v(" 2."+_vm._s(_vm.$t("cases.createDiagnosis.yzsfzc"))+" ")]),_c('p',{staticClass:"main_theme_color_999 fz14 ml20"},[_vm._v(" "+_vm._s(_vm.$t("cases.createDiagnosis.yzsfzcinit"))+" ")])]),(_vm.diagnosisProblem['2'])?_c('p',{staticClass:"problem_note"},[_vm._v(" "+_vm._s(_vm.diagnosisProblem["2"])+" ")]):_vm._e(),_c('div',{staticClass:"flex-y-c"},_vm._l((_vm.caseOptions['underjaw_abnormal_flag']),function(item,index){return _c('span',{key:index,staticClass:"common_btn",class:{
            is_active: item.key === _vm.diagnosisParams.periodontalDisease.key,
          },on:{"click":function($event){return _vm.levelOneClick({
              addItem: {
                key: item.key,
                value: item.value,
                submitKey: 'periodontalDisease',
              },
            })}}},[_vm._v(_vm._s(item.value))])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.diagnosisParams.periodontalDisease.key === 'true'),expression:"diagnosisParams.periodontalDisease.key === 'true'"}],staticClass:"remark_box"},[_c('el-input',{staticClass:"tex_b_a",attrs:{"disabled":_vm.isBackDisabled,"autosize":{ minRows: 4, maxRows: 6 },"maxlength":"2000","type":"textarea","show-word-limit":"","placeholder":_vm.$t('common.common.qsrnr')},model:{value:(_vm.diagnosisParams.periodontalDiseaseNote),callback:function ($$v) {_vm.$set(_vm.diagnosisParams, "periodontalDiseaseNote", $$v)},expression:"diagnosisParams.periodontalDiseaseNote"}})],1)]),_c('div',{staticClass:"select_type",attrs:{"id":"3"}},[_c('p',{staticClass:"fz16 main_theme_color_333"},[_vm._v(" 3."+_vm._s(_vm.$t("cases.createDiagnosis.txt17"))+" ")]),(_vm.diagnosisProblem['3'])?_c('p',{staticClass:"problem_note"},[_vm._v(" "+_vm._s(_vm.diagnosisProblem["3"])+" ")]):_vm._e(),_c('div',{staticClass:"remark_box"},[_c('el-input',{staticClass:"tex_b_a",attrs:{"disabled":_vm.isBackDisabled,"autosize":{ minRows: 4, maxRows: 6 },"maxlength":"2000","type":"textarea","show-word-limit":"","placeholder":_vm.$t('common.common.qsrnr')},model:{value:(_vm.diagnosisParams.specialInstructions),callback:function ($$v) {_vm.$set(_vm.diagnosisParams, "specialInstructions", $$v)},expression:"diagnosisParams.specialInstructions"}})],1)])]),_c('full-loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mr4"},[_c('span',{staticClass:"main_theme_color_red fz14"},[_vm._v("●")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mr4"},[_c('span',{staticClass:"main_theme_color_red fz14"},[_vm._v("●")])])}]

export { render, staticRenderFns }