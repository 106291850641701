<template>
  <div class="table_wrap">
    <div :style="{ height: rightHeight }" class="left_option">
      <div style="height: 6.3rem">
        <el-scrollbar ref="scrollbar" style="height: 100%">
          <template>
            <div v-for="(item, index) in leftList" :key="index" class="left_option_item"
              :class="{ left_active: selectLeftKey === `d${index + 1}` }" @click="selectLeft(index, 'left')">
              <div v-show="selectLeftKey === `d${index + 1}`" class="left_wire"></div>
              {{ item.title }}
            </div>
          </template>
        </el-scrollbar>
      </div>
    </div>
    <div ref="rightTable" class="right_table">
      <div class="dia_problem" v-if="problemList && problemList.length > 0">
        <div class="flex-y-c mb10">
          <img class="mr10" style="width: 24px" src="common/imgs/case/dia_warn_cion.png" alt="" />
          <h5>{{ $t("cases.createImage.czrxwt") }}</h5>
        </div>
        <div class="pro_box curp">
          <span @click="selectLeft(+item.groupNo - 1)" v-for="(item, index) in problemList" :key="`${item.id}_${index}`">
            {{ item.groupNo }} {{ item.groupName }}
          </span>
        </div>
      </div>
      <div class="title_paragraph">{{ $t("cases.createDiagnosis.yyzk") }}</div>
      <div class="select_type" id="1">
        <div class="flex-y-c mb10">
          <p class="select_type_title mv20">
            1.{{ $t("cases.createDiagnosis.ycxxqy") }}
          </p>
          <p>
            <span class="main_theme_color_999 fz14 tips_text">{{
              $t("cases.createDiagnosis.qzylzbjqsy")
            }}</span>
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[1]">
          {{ diagnosisProblem[1] }}
        </p>
        <YysdTeeth ref="teethComponents" :commonTeeth="commonTeeth" :readOnly="false" :teethType="1"
          @updateTeeth="updateTeeth" />
      </div>
      <div class="select_type" id="2">
        <div class="flex-y-c mb10">
          <p class="select_type_title mv20">
            2.{{ $t("cases.createDiagnosis.ycxxjy") }}
          </p>
          <p>
            <span class="main_theme_color_999 fz14 tips_text">{{
              $t("cases.createDiagnosis.qzylzbjjy")
            }}</span>
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[2]">
          {{ diagnosisProblem[2] }}
        </p>
        <YysdTeeth ref="teethComponents" :commonTeeth="commonTeeth" :readOnly="false" :teethType="11"
          @updateTeeth="updateTeeth" />
      </div>
      <div class="select_type" id="3">
        <div class="flex-y-c mb10">
          <p class="select_type_title mv20">
            3.{{ $t("cases.createDiagnosis.lczk") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[3]">
          {{ diagnosisProblem[3] }}
        </p>
        <!-- 错合类型 -->
        <div class="flex-y-c mt10">
          <div class="flex-y-c mt10">
            <span class="main_theme_color_red fz14 mr4">●</span>
            <div :style="language === 'en_US' ? 'width: 1.2rem' : ''" class="option_left_title">
              {{ `1.${$t("cases.createDiagnosis.chlx")}` }}
            </div>
          </div>
          <div :class="{ ml14: needRTD }" class="flex-y-c mt10">
            <div class="common_btn" @click="
              levelOneClick({
                radio: false,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'clinicInfo',
                },
              })
              " :class="{
    is_active: values(
      map(diagnosisParams.clinicInfo.children, (it) => it.key)
    ).includes(item.key),
  }" v-for="(item, index) in caseOptions['clinic_info_f']" :key="index">
              {{ item.value }}
            </div>
          </div>
        </div>
        <div class="remark_box" v-show="values(
          map(diagnosisParams.clinicInfo.children, (it) => it.key)
        ).includes('other')
          ">
          <el-input :disabled="isBackDisabled" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="2000" class="tex_b_a"
            type="textarea" show-word-limit v-model="diagnosisParams.clinicOtherInfo"
            :placeholder="$t('cases.createDiagnosis.gs2000')"></el-input>
        </div>
        <!-- 咬合关系 -->
        <div class="flex-y-c mt10">
          <div class="flex-y-c mt10">
            <span class="main_theme_color_red fz14 mr4">●</span>
            <div :style="language === 'en_US' ? 'width: 1.2rem' : ''" class="option_left_title">
              {{ `2.${$t("cases.createDiagnosis.yhgx")}` }}
            </div>
          </div>
          <div class="flex-y-c mt10">
            <div class="common_btn" @click="
              levelOneClick({
                radio: true,
                noClear: true,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'occlusalRelationship',
                },
              })
              " :class="{
    is_active:
      item.key === diagnosisParams.occlusalRelationship.key,
  }" v-for="(item, index) in caseOptions['occlusal_relationship']" :key="`${item.key}_${index}`">
              {{ item.value }}
            </div>
          </div>
        </div>
        <!-- 邻间隙 -->
        <div class="flex-y-c mt10">
          <div class="flex-y-c mt10">
            <span class="main_theme_color_red fz14 mr4">●</span>
            <div :style="language === 'en_US' ? 'width: 1.2rem' : ''" class="option_left_title">
              {{ `3.${$t("cases.createDiagnosis.ljx")}` }}
            </div>
          </div>
          <div class="flex-y-c mt10">
            <div class="common_btn" @click="
              levelOneClick({
                radio: true,
                noClear: true,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'adjacentGap',
                },
              })
              " :class="{
    is_active: item.key === diagnosisParams.adjacentGap.key,
  }" v-for="(item, index) in caseOptions['adjacent_gap']" :key="`${item.key}_${index}`">
              {{ item.value }}
            </div>
          </div>
        </div>
        <!-- 牙齿信息 -->
        <div class="flex-y-c mt10">
          <div class="flex-y-c mt10">
            <span class="main_theme_color_red fz14 mr4">●</span>
            <div :style="language === 'en_US' ? 'width: 1.2rem' : ''" class="option_left_title">
              {{ `4.${$t("cases.createDiagnosis.ycwt")}` }}
            </div>
          </div>
          <div :class="{ ml14: needRTD }" class="flex-y-c mt10">
            <div class="common_btn" @click="
              levelOneClick({
                radio: false,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'toothProblemThree',
                },
              })
              " :class="{
    is_active: values(
      map(
        diagnosisParams.toothProblemThree.children,
        (it) => it.key
      )
    ).includes(item.key),
  }" v-for="(item, index) in caseOptions['tooth_problem_three']" :key="index">
              {{ item.value }}
            </div>
          </div>
        </div>
        <div class="remark_box" v-show="values(
          map(diagnosisParams.toothProblemThree.children, (it) => it.key)
        ).includes('other')
          ">
          <el-input :disabled="isBackDisabled" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="2000" class="tex_b_a"
            type="textarea" show-word-limit v-model="diagnosisParams.toothOtherProblemThree"
            :placeholder="$t('cases.createDiagnosis.gs2000')"></el-input>
        </div>
      </div>
      <div class="title_paragraph">{{ $t("cases.createDiagnosis.sjmb") }}</div>
      <div class="select_type" id="4">
        <div class="flex-y-c mb10">
          <span class="main_theme_color_red fz14 mr4">●</span>

          <p class="select_type_title mv20">
            4.{{ $t("cases.createDiagnosis.sxyw") }}
          </p>
          <p>
            <span class="main_theme_color_999 fz14 tips_text">{{
              $t("cases.createDiagnosis.qzylzbjsxyw")
            }}</span>
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[4]">
          {{ diagnosisProblem[4] }}
        </p>
        <YysdTeeth ref="teethComponents" :commonTeeth="commonTeeth" :readOnly="false" :childKey="true"
          @updateTeeth="updateTeeth" />
        <div class="remark_box">
          <el-input :disabled="isBackDisabled" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="2000" class="tex_b_a"
            type="textarea" show-word-limit v-model="diagnosisParams.desiredToothPosition"
            :placeholder="$t('cases.createDiagnosis.rzzyg')"></el-input>
        </div>
        <div class="options_tips" v-show="showNeedTeethTips">
          <p v-if="this.commonTeeth.some((item) => item.hasOwnProperty('VENEER'))
            ">
            {{
              this.commonTeeth
                .filter((item) => item.hasOwnProperty("VENEER") && item.number)
                .map((it) => it.number)
                .sort()
                .join("，") + ` ${$t("cases.createDiagnosis.tm")}`
            }}
          </p>
          <p v-if="this.commonTeeth.some((item) => item.hasOwnProperty('CROWN'))">
            {{
              this.commonTeeth
                .filter((item) => item.hasOwnProperty("CROWN") && item.number)
                .map((it) => it.number)
                .sort()
                .join("，") + ` ${$t("cases.createDiagnosis.guan")}`
            }}
          </p>
          <p v-if="this.commonTeeth.some((item) => item.hasOwnProperty('INLAY'))">
            {{
              this.commonTeeth
                .filter((item) => item.hasOwnProperty("INLAY") && item.number)
                .map((it) => it.number)
                .sort()
                .join("，") + ` ${$t("cases.createDiagnosis.qt")}`
            }}
          </p>
        </div>
      </div>
      <div class="select_type" id="5">
        <div class="flex-y-c mb10">
          <span class="main_theme_color_red fz14 mr4">●</span>
          <p class="select_type_title mv20">
            5.{{ $t("cases.createDiagnosis.yysx") }}
          </p>
          <p>
            <span class="main_theme_color_999 fz14 tips_text">{{
              $t("cases.createDiagnosis.kxzqtxxmssx")
            }}</span>
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[5]">
          {{ diagnosisProblem[5] }}
        </p>
        <div class="flex-y-c mt10">
          <div @click="
            levelOneClick({
              radio: true,
              noClear: true,
              otherClear: true,
              addItem: {
                key: item.key,
                value: item.value,
                submitKey: 'originalTeethColor',
              },
            })
            " v-for="(item, index) in caseOptions['original_teeth_color_f']" :key="`${item.key}_${index}`">
            <el-popover v-if="item.key !== 'other'" trigger="click" :popper-options="{
              boundariesElement: 'body',
            }">
              <div class="popover_con">
                <div style="flex-direction: column">
                  <div class="flex-y-c">
                    <div class="common_btn" @click.stop="
                      levelTowClick({
                        wrapFiled: 'colorSystem',
                        radio: true,
                        noClear: true,
                        addItem: {
                          key: get(it, 'key'),
                          submitKey: 'colorSystem',
                          value: diaKeyObj[get(it, 'key')],
                          input: '',
                          inputKey: 'colorNumber',
                        },
                        parentFiled: item.key,
                      })
                      " :class="{
    is_active: find(
      diagnosisParams.colorSystem['children'],
      (dia) =>
        get(dia, 'key') === get(it, 'key') &&
        get(dia, 'submitKey') === 'colorSystem'
    ),
  }" v-for="(it, itx) in caseOptions[item.key]" :key="itx">
                      {{ it.value }}
                    </div>
                  </div>
                  <p class="flex-y-c posr required_container" v-show="find(
                    diagnosisParams.colorSystem['children'],
                    (dia) => get(dia, 'submitKey') === 'colorSystem'
                  )
                    ">
                    <el-input style="width: 2.6rem" :disabled="isBackDisabled" maxlength="50" :value="find(
                      diagnosisParams.colorSystem['children'],
                      (dia) => get(dia, 'submitKey') === 'colorSystem'
                    ) &&
                      find(
                        diagnosisParams.colorSystem['children'],
                        (dia) => get(dia, 'submitKey') === 'colorSystem'
                      )['input']
                      " @change="
    disposeNumber({
      key: find(
        diagnosisParams.colorSystem['children'],
        (dia) => get(dia, 'submitKey') === 'colorSystem'
      ),
      value: arguments[0],
    })
    " @input="
    disposeNumber({
      key: find(
        diagnosisParams.colorSystem['children'],
        (dia) => get(dia, 'submitKey') === 'colorSystem'
      ),
      value: arguments[0],
    })
    " :placeholder="$t('cases.createDiagnosis.qsrsh')" />
                    <span class="required_input">*</span>
                  </p>
                </div>
              </div>
              <div class="common_btn" :class="{
                is_active:
                  item.key === diagnosisParams.originalTeethColor.key,
              }" slot="reference">
                {{ item.value }}
              </div>
            </el-popover>
            <div v-else class="common_btn" :class="{
              is_active: item.key === diagnosisParams.originalTeethColor.key,
            }">
              {{ item.value }}
            </div>
          </div>
        </div>
        <div class="options_tips" v-show="find(
          diagnosisParams.colorSystem['children'],
          (dia) => get(dia, 'submitKey') === 'colorSystem'
        ) &&
          find(
            diagnosisParams.colorSystem['children'],
            (dia) => get(dia, 'submitKey') === 'colorSystem'
          )['input']
          ">
          {{
            find(
              diagnosisParams.colorSystem["children"],
              (dia) => get(dia, "submitKey") === "colorSystem"
            ) &&
            diaKeyObj[
            find(
              diagnosisParams.colorSystem["children"],
              (dia) => get(dia, "submitKey") === "colorSystem"
            ).key
            ]
          }}
          :
          {{
            find(
              diagnosisParams.colorSystem["children"],
              (dia) => get(dia, "submitKey") === "colorSystem"
            ) &&
            find(
              diagnosisParams.colorSystem["children"],
              (dia) => get(dia, "submitKey") === "colorSystem"
            )["input"]
          }}
        </div>
        <div class="remark_box" v-show="diagnosisParams.originalTeethColor.key == 'other'">
          <el-input :disabled="isBackDisabled" class="tex_b_a" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="2000"
            type="textarea" show-word-limit v-model="diagnosisParams.originalTeethColorNote" :placeholder="$t('cases.createDiagnosis.mqbzbsbdysh') +
              '，' +
              $t('common.common.qsrnr')
              "></el-input>
        </div>
      </div>
      <div class="select_type" id="6">
        <div class="flex-y-c mb10">
          <span class="main_theme_color_red fz14 mr4">●</span>
          <p class="select_type_title mv20">
            6.{{ $t("cases.createDiagnosis.mbyase") }}
          </p>
          <p>
            <span class="main_theme_color_999 fz14 tips_text">{{
              $t("cases.createDiagnosis.kxzqtxxmsys")
            }}</span>
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[6]">
          {{ diagnosisProblem[6] }}
        </p>
        <div class="flex-y-c-w mt10">
          <div class="common_btn" @click="
            levelOneClick({
              oneAsOther: true,
              addItem: {
                key: item.key,
                value: item.value,
                submitKey: 'targetColor',
              },
              radio: false,
            })
            " :class="values(
    map(diagnosisParams.targetColor.children, (it) => it.key)
  ).indexOf(item.key) !== -1
      ? 'is_active'
      : ''
    " v-for="(item, index) in caseOptions['target_color']" :key="`${item.key}_${index}`">
            {{ item.value }}
          </div>
        </div>
        <div class="remark_box" v-show="values(
          map(diagnosisParams.targetColor.children, (it) => it.key)
        ).includes('other')
          ">
          <el-input :disabled="isBackDisabled" class="tex_b_a" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="2000"
            type="textarea" show-word-limit v-model="diagnosisParams.targetColorNote" :placeholder="$t('cases.createDiagnosis.mqbzbsbdysh') +
              '，' +
              $t('common.common.qsrnr')
              "></el-input>
        </div>
      </div>
      <div class="select_type" id="7">
        <div class="flex-y-c mb10">
          <span class="main_theme_color_red fz14 mr4">●</span>
          <p class="select_type_title mv20">
            7.{{ $t("cases.createDiagnosis.mblx") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[7]">
          {{ diagnosisProblem[7] }}
        </p>
        <div class="flex-y-c-w mt10">
          <div class="common_btn" @click="
            levelOneClick({
              oneAsOther: true,
              addItem: {
                key: item.key,
                value: item.value,
                submitKey: 'targetType',
              },
              radio: false,
            })
            " :class="values(
    map(diagnosisParams.targetType.children, (it) => it.key)
  ).indexOf(item.key) !== -1
      ? 'is_active'
      : ''
    " v-for="(item, index) in caseOptions['target_type']" :key="`${item.key}_${index}`">
            {{ item.value }}
          </div>
        </div>
        <div class="remark_box" v-show="values(
          map(diagnosisParams.targetType.children, (it) => it.key)
        ).includes('other')
          ">
          <el-input :disabled="isBackDisabled" class="tex_b_a" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="2000"
            type="textarea" show-word-limit v-model="diagnosisParams.targetTypeNote" :placeholder="$t('cases.createDiagnosis.mqbzbsbdysh') +
              '，' +
              $t('common.common.qsrnr')
              "></el-input>
        </div>
      </div>
      <div class="select_type" id="8">
        <div class="flex-y-c mb10">
          <span class="main_theme_color_red fz14 mr4">●</span>
          <p class="select_type_title mv20">
            8.{{ $t("cases.createDiagnosis.mbtmd") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[8]">
          {{ diagnosisProblem[8] }}
        </p>
        <div class="flex-y-c-w mt10">
          <div class="common_btn" @click="
            levelOneClick({
              oneAsOther: true,
              addItem: {
                key: item.key,
                value: item.value,
                submitKey: 'targetTransparency',
              },
              radio: false,
            })
            " :class="values(
    map(diagnosisParams.targetTransparency.children, (it) => it.key)
  ).indexOf(item.key) !== -1
      ? 'is_active'
      : ''
    " v-for="(item, index) in caseOptions['target_transparency']" :key="`${item.key}_${index}`">
            {{ item.value }}
          </div>
        </div>
      </div>
      <div class="select_type" id="9">
        <div class="flex-y-c mb10">
          <span class="main_theme_color_red fz14 mr4">●</span>
          <p class="select_type_title mv20">
            9.{{ $t("cases.createDiagnosis.cllx") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[9]">
          {{ diagnosisProblem[9] }}
        </p>
        <div class="flex-y-c-w mt10">
          <div class="common_btn" @click="
            levelOneClick({
              oneAsOther: true,
              addItem: {
                key: item.key,
                value: item.value,
                submitKey: 'materialType',
              },
              radio: false,
            })
            " :class="values(
    map(diagnosisParams.materialType.children, (it) => it.key)
  ).indexOf(item.key) !== -1
      ? 'is_active'
      : ''
    " v-for="(item, index) in caseOptions['material_type']" :key="`${item.key}_${index}`">
            {{ item.value }}
          </div>
        </div>
      </div>
      <div class="select_type" id="10">
        <div class="flex-y-c mb10">
          <span class="main_theme_color_red fz14 mr4">●</span>
          <p class="select_type_title mv20">
            10.{{ $t("cases.createDiagnosis.sfby") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[10]">
          {{ diagnosisProblem[10] }}
        </p>
        <div class="mt10 flex-y-c">
          <div class="common_btn" :class="{
            is_active: item.key === diagnosisParams.whetherToPrepareTeeth.key,
          }" @click="
  levelOneClick({
    addItem: {
      key: item.key,
      value: item.value,
      submitKey: 'whetherToPrepareTeeth',
    },
    noClear: true,
  })
  " v-for="(item, index) in caseOptions['whether_to_prepare_teeth']" :key="index">
            {{ item.value }}
          </div>
        </div>
        <div style="display: flex; flex-direction: column; align-items: center"
          v-show="diagnosisParams.whetherToPrepareTeeth.key === 'true'">
          <div style="width: 7.8rem">
            <span class="main_theme_color_999 fz14">{{
              $t("cases.createDiagnosis.qzylzbjxybyyw")
            }}</span>
          </div>
          <YysdTeeth ref="teethComponents" :commonTeeth="commonTeeth" :readOnly="false" :teethType="14"
            @updateTeeth="updateTeeth" />
        </div>
      </div>
      <div class="select_type" id="11">
        <div class="flex-y-c mb10">
          <span class="main_theme_color_red fz14 mr4">●</span>
          <p class="select_type_title mv20">
            11.{{ $t("cases.createDiagnosis.tmhdyq") }}
          </p>
          <p>
            <span class="main_theme_color_999 fz14 tips_text">{{
              $t("cases.createDiagnosis.bzhd")
            }}</span>
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[11]">
          {{ diagnosisProblem[11] }}
        </p>
        <div class="mt10">
          <div class="thickness_progress" style="width: 400px">
            <div class="progress_container" style="width: 400px">
              <div class="triangle left_triangle" :class="[
                +diagnosisParams.veneerThicknessRequirements >= 0.1 &&
                'active_left',
              ]"></div>
              <svg width="400" height="2">
                <line class="default_color" stroke-dasharray="10, 3" x1="0" x2="400" y1="0" y2="0" rx="0"
                  style="stroke-width: 20px"></line>
                <line class="main_color" stroke-dasharray="10, 3" x1="0" :x2="(400 / 100) *
                  diagnosisParams.veneerThicknessRequirements *
                  100
                  " y1="0" y2="0" rx="0" style="stroke-width: 20px"></line>
              </svg>
              <div class="triangle right_triangle" :class="[
                +diagnosisParams.veneerThicknessRequirements == 1 &&
                'active_right',
              ]"></div>
            </div>
            <div class="progress_text flex-y-c" style="width: 400px">
              <p v-for="(it, idx) in 10" :class="[
                diagnosisParams.veneerThicknessRequirements >= it / 10 &&
                'active_text',
              ]" :key="idx">
                {{ it / 10 }}
              </p>
            </div>
          </div>
          <div class="flex-y-c">
            <div style="color: #666666, font-size: 16px">
              {{ $t("cases.createDiagnosis.hd") }}
            </div>
            <div class="input_number">
              <div class="numbox" :class="[isFocus && 'focus_input']">
                <input class="num_input" type="number" :max="max" :min="min"
                  v-model="diagnosisParams.veneerThicknessRequirements" @input="handleInput" @blur="handleBlur"
                  @focus="hanleFocus" :placeholder="$t('cases.createDiagnosis.qsrhd')"
                  oninput="if(value.length>4)value=value.slice(0,4)" />
                <div class="triangle add" @click="handleAdd"></div>
                <div class="triangle minu" @click="handleMinu"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="select_type" id="12">
        <div class="flex-y-c mb10">
          <span class="main_theme_color_red fz14 mr4">●</span>
          <p class="select_type_title mv20">
            12.{{ $t("cases.createDiagnosis.ymwl") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[12]">
          {{ diagnosisProblem[12] }}
        </p>
        <div class="flex-y-c-w mt10">
          <div class="common_btn" @click="
            levelOneClick({
              oneAsOther: true,
              addItem: {
                key: item.key,
                value: item.value,
                submitKey: 'surfaceTexture',
              },
              radio: false,
            })
            " :class="values(
    map(diagnosisParams.surfaceTexture.children, (it) => it.key)
  ).indexOf(item.key) !== -1
      ? 'is_active'
      : ''
    " v-for="(item, index) in caseOptions['surface_texture']" :key="`${item.key}_${index}`">
            {{ item.value }}
          </div>
        </div>
      </div>
      <div class="select_type" id="13">
        <div class="flex-y-c mb10">
          <span class="main_theme_color_red fz14 mr4">●</span>
          <p class="select_type_title mv20">
            13.{{ $t("cases.createDiagnosis.sjyq") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[13]">
          {{ diagnosisProblem[13] }}
        </p>
        <div class="flex-y-c-w mt10">
          <div class="common_btn" @click="
            levelOneClick({
              addItem: {
                key: item.key,
                value: item.value,
                submitKey: 'designRequestOne',
              },
              noClear: true,
            })
            " :class="{
    is_active: item.key === diagnosisParams.designRequestOne.key,
  }" v-for="(item, index) in caseOptions['design_request_one_f']" :key="index">
            {{ item.value }}
          </div>
        </div>
        <div class="flex-y-c-w mt10">
          <div class="common_btn" :class="{
            is_active: values(
              map(diagnosisParams.designRequestTwo.children, (it) => it.key)
            ).includes(item.key),
          }" @click="
  levelOneClick({
    radio: false,
    addItem: {
      key: item.key,
      value: item.value,
      submitKey: 'designRequestTwo',
    },
  })
  " v-for="(item, index) in caseOptions['design_request_two_f']" :key="index">
            {{ item.value }}
          </div>
        </div>
      </div>
      <div class="select_type" id="14">
        <div class="flex-y-c mb10">
          <p class="select_type_title mv20">
            14.{{ $t("cases.createDiagnosis.txt17") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[14]">
          {{ diagnosisProblem[14] }}
        </p>
        <div class="remark_box">
          <el-input :disabled="isBackDisabled" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="2000" class="tex_b_a"
            type="textarea" show-word-limit v-model="diagnosisParams.specialInstructions"
            :placeholder="$t('common.common.qsrnr')"></el-input>
        </div>
      </div>
    </div>
    <full-loading v-show="isLoading" />
  </div>
</template>

<script>
import FullLoading from "components/full-loading/full-loading";
import { getDictAllList } from "common/api/public";
import {
  createCurePlan,
  editCurePlan,
  detailCurePlan,
  cureProblemList,
} from "common/api/cases";
import { notifyMsg } from "common/js/util";
import $ from "jquery";
import { toNumber } from "common/js/Fn";
import { changeRouteQuery } from "common/js/Fn";
import YysdTeeth from "components/treatment-table/yysd-teeth/yysd-teeth.vue";
import ChildrenTeeth from "components/treatment-table/children-teeth/index";
import VeneersTeeth from "components/treatment-table/veneers-teeth/index";
import IntervalCorrect from "components/treatment-table/interval-correct/index";
import AdjoinDislodge from "components/treatment-table/children-adjoin-dislodge/index";
import ReserveDinterval from "components/treatment-table/reserve-dinterval/index";
import { mapGetters, mapActions, mapState } from "vuex";
import {
  each,
  map,
  filter,
  get,
  isArray,
  keys,
  values,
  join,
  flattenDeep,
  find,
  isObject,
  extend,
  pickBy,
  split,
  isNumber,
  some,
} from "lodash";
import {
  adultOptions,
  yysdDefaultBright,
  adultOptionsKey,
} from "components/treatment-table/common";
const adultTeethList = [
  { number: 11 },
  { number: 12 },
  { number: 13 },
  { number: 14 },
  { number: 15 },
  { number: 16 },
  { number: 17 },
  { number: 18 },
  { number: 21 },
  { number: 22 },
  { number: 23 },
  { number: 24 },
  { number: 25 },
  { number: 26 },
  { number: 27 },
  { number: 28 },
  { number: 48 },
  { number: 47 },
  { number: 46 },
  { number: 45 },
  { number: 44 },
  { number: 43 },
  { number: 42 },
  { number: 41 },
  { number: 31 },
  { number: 32 },
  { number: 33 },
  { number: 34 },
  { number: 35 },
  { number: 36 },
  { number: 37 },
  { number: 38 },
];
export default {
  data() {
    return {
      caseOptions: {},
      diaKeyObj: {},
      rightHeight: "",
      isFocus: false,
      diagnosisParams: {
        //3
        clinicInfo: {},
        clinicOtherInfo: "",
        occlusalRelationship: {},
        adjacentGap: {},
        toothProblemThree: {},
        toothOtherProblemThree: "",

        //4
        desiredToothPosition: "",
        //5
        originalTeethColor: {},
        colorSystem: {},
        colorNumber: "",
        originalTeethColorNote: "",
        //6
        targetColor: {},
        targetColorNote: "",
        //7
        targetType: {},
        targetTypeNote: "",
        //8
        targetTransparency: {},
        //9
        materialType: {},
        //10
        whetherToPrepareTeeth: "",
        //11
        veneerThicknessRequirements: "",
        //12
        surfaceTexture: {},
        //13
        designRequestOne: {},
        designRequestTwo: {},
        //14
        specialInstructions: "",
      },
      commonTeeth: [],
      veneersTeeth: [],
      lang: localStorage.getItem("user_lang") || "zh_CN",
      selectLeftKey: "1",
      leftList: [
        { title: this.$t("cases.createDiagnosis.ycxxqy") },
        { title: this.$t("cases.createDiagnosis.ycxxjy") },
        { title: this.$t("cases.createDiagnosis.lczk") },
        { title: this.$t("cases.createDiagnosis.sxyw") },
        { title: this.$t("cases.createDiagnosis.yysx") },
        { title: this.$t("cases.createDiagnosis.mbyase") },
        { title: this.$t("cases.createDiagnosis.mblx") },
        { title: this.$t("cases.createDiagnosis.mbtmd") },
        { title: this.$t("cases.createDiagnosis.cllx") },
        { title: this.$t("cases.createDiagnosis.sfby") },
        { title: this.$t("cases.createDiagnosis.tmhdyq") },
        { title: this.$t("cases.createDiagnosis.ymwl") },
        { title: this.$t("cases.createDiagnosis.sjyq") },
        { title: this.$t("cases.createDiagnosis.txt17") },
      ],
      leftTopObj: {},
      isScroll: true,
      needRTD: sessionStorage.getItem("needRTD") === "1",
      diagnosisProblem: {},
      details: {},
      isLoading: false,
      problemList: [],

      //间隙的矫治
      uploadIntervalList: [],

      // 8 二级弹层 选项
      sagittalRelationshipOption: [],
      sagittalRelationshipOptionTow: [],
      sagittalRelationshipOptionThree: [],
      sagittalRelationshipOptionFour: [],

      isResponse: 0,

      isProductErr: false,
      max: 1.0,
      min: 0.1,
      step: 0.05,
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language,
    }),
    ...mapGetters({
      commonCaseDetail: "getCommonCaseDetail", //病例详情
    }),
    queryCaseId() {
      return this.$route.query.caseId;
    },
    queryCurePlanId() {
      return this.$route.query.curePlanId;
    },
    showNeedTeethTips() {
      const tipsList = map(
        filter(
          this.commonTeeth,
          (item) =>
            item.hasOwnProperty("VENEER") ||
            item.hasOwnProperty("CROWN") ||
            item.hasOwnProperty("INLAY")
        ),
        (it) => it.number
      );
      if (tipsList.length) return true;
    },
    isBackDisabled() {
      return false;
      return get(this.details, "status") === "to_resubmit" && this.isProductErr;
    },
    isDisabledPassivity() {
      return ["upjaw", "downjaw"].includes(
        this.diagnosisParams.toCorrectToothJaw.key
      );
    },
  },
  methods: {
    ...mapActions({
      getCaseCureDetail: "actionGetCaseCureDetail", //治疗表详情
      getCommonCaseDetail: "actionGetCommonCaseDetail", //病例详情
    }),
    isArray,
    map,
    keys,
    values,
    filter,
    join,
    flattenDeep,
    find,
    get,
    handleInput(e) {
      const inputValue = e.target.value;
      this.setCurrentValue(+inputValue);
    },
    handleBlur() {
      let value = this.diagnosisParams["veneerThicknessRequirements"];
      this.isFocus = false;
      if (+value >= this.max) {
        this.$set(
          this.diagnosisParams,
          "veneerThicknessRequirements",
          this.max
        );
      }
      if (+value <= this.min) {
        this.$set(
          this.diagnosisParams,
          "veneerThicknessRequirements",
          this.min
        );
      }
    },
    hanleFocus() {
      this.isFocus = true;
    },
    setCurrentValue(newVal) {
      const oldVal = this.currentValue;
      if (typeof newVal === "number") {
        newVal = newVal;
      }
      if (oldVal === newVal) return;
    },
    handleAdd() {
      let value = this.diagnosisParams["veneerThicknessRequirements"];
      if (+value + this.step < this.min) {
        this.$set(
          this.diagnosisParams,
          "veneerThicknessRequirements",
          this.min
        );
        return;
      }
      if (+value + this.step >= this.max) {
        this.$set(
          this.diagnosisParams,
          "veneerThicknessRequirements",
          this.max
        );
        return;
      }
      this.$set(
        this.diagnosisParams,
        "veneerThicknessRequirements",
        (+value + this.step).toFixed(2)
      );
    },
    handleMinu() {
      let value = this.diagnosisParams["veneerThicknessRequirements"];
      if (+value - this.step <= this.min) {
        this.$set(
          this.diagnosisParams,
          "veneerThicknessRequirements",
          this.min
        );
        return;
      }
      this.$set(
        this.diagnosisParams,
        "veneerThicknessRequirements",
        (+value - this.step).toFixed(2)
      );
    },
    updateInterval(list) {
      this.uploadIntervalList = list;
    },
    thicknessChange(newVal) {
      this.diagnosisParams["veneerThicknessRequirements"] = newVal;
    },
    overbiteText() {
      let list = this.diagnosisParams["overbiteInfo"]["children"];
      let ls = map(list, (item, index) => {
        if (item.children) {
          return (
            item.value +
            (["press_front_rise_back", "press_front"].includes(item.key)
              ? `${(item.children.length ? "-" : "") +
              map(item.children, (it) => it.value).join("、")
              }`
              : "") +
            (list.length === 1 ? "" : ",")
          );
        }
        return (
          item.value +
          (index === list.length - 1
            ? ""
            : ["press_front_rise_back", "press_front"].includes(item.key)
              ? ","
              : "、")
        );
      });
      return ls.join("");
    },
    selectLeft(key, left) {
      this.isScroll = false;
      let leftStr;
      if (left === "left") {
        leftStr = `${+key + 1}`;
        document.documentElement.scrollTop =
          document.getElementById(leftStr).offsetTop;
      } else {
        leftStr = `${key}`;
        document.documentElement.scrollTop =
          document.getElementById(key).offsetTop;
      }
      this.selectLeftKey = "d" + leftStr;
      this.leftHandleScroll(document.documentElement.scrollTop / 8);
      setTimeout(() => {
        this.isScroll = true;
      }, 1000);
    },
    disposeNumber({ key, value }) {
      if (value === "") return (key.input = "");
      key.input = value;
    },
    leftHandleScroll(val) {
      let div = this.$refs.scrollbar && this.$refs.scrollbar.$refs["wrap"];
      if (val < 0 && !div) return;
      div.scrollTop = val;
    },
    updateTeeth(data) {
      this.commonTeeth = data;
    },
    sortOptionThree(wrapFiled, index, parentKey) {
      let list = [];
      each(adultOptions[parentKey], (i) => {
        each(adultOptionsKey, (item) => {
          each(
            this.diagnosisParams[wrapFiled]["children"][index]["children"],
            (it) => {
              if (item === parentKey) {
                if (i === it.key) {
                  list.push(it);
                }
              }
            }
          );
        });
      });
      this.diagnosisParams[wrapFiled]["children"][index]["children"] = list;
    },
    sortOption(wrapFiled) {
      let list = [];
      each(adultOptions[wrapFiled], (item) => {
        each(this.diagnosisParams[wrapFiled]["children"], (it) => {
          if (item === it.key) {
            list.push(it);
          }
        });
      });
      this.diagnosisParams[wrapFiled]["children"] = list;
    },
    clearTeethType(type) {
      let teethType = {
        11: "DENTURE",
      }[type];
      this.diagnosisParams;
      let data = this.commonTeeth;
      data = map(data, (item) => {
        return pickBy(extend({}, item, { [teethType]: "" }));
      });
    },
    levelTowClick({ wrapFiled, radio = false, addItem, noClear = false }) {
      // radio：是否单选,  multipleRadio, multipleRadios：1对1   oneOfMore: 1对多  addItem: 想要添加的数据
      // const submitKey = addItem.submitKey;
      this.$forceUpdate();
      const { key, submitKey } = addItem;
      if (!key || this.isBackDisabled) return;

      if (radio) {
        if (!this.diagnosisParams[wrapFiled]["children"]) {
          this.$set(this.diagnosisParams[wrapFiled], "children", []);
        }
        let index;
        let diaItem;
        each(this.diagnosisParams[wrapFiled]["children"], (dia, dix) => {
          if (dia.submitKey === addItem.submitKey) {
            index = dix;
            diaItem = dia;
          }
        });
        if (this.diagnosisParams[wrapFiled]["children"][0].key === addItem.key) return
        if (index !== undefined && diaItem.key !== addItem.key) {
          this.diagnosisParams[wrapFiled]["children"] = [addItem];
        }
        this.diagnosisParams[wrapFiled]["children"] = [addItem];
      }
    },
    levelOneClick({
      addItem,
      multipleRadio,
      oneAsOther,
      radio = true,
      oneOfMore = false,
      noClear = false,
      otherClear = false,
    }) {
      let { key, submitKey } = addItem;
      if (!key || this.isBackDisabled) return;

      //输入框的清空
      let inputKeyObj = {
        clinicInfo: "clinicOtherInfo",
        toothProblemThree: "toothOtherProblemThree",
        targetColor: "targetColorNote",
        targetType: "targetTypeNote",
      };
      let inputKeyList = keys(inputKeyObj);
      if (
        inputKeyList.includes(submitKey) &&
        ["other", "false"].includes(key)
      ) {
        this.$set(this.diagnosisParams, inputKeyObj[submitKey], "");
      }
      if (submitKey == "originalTeethColor") {
        this.diagnosisParams["originalTeethColorNote"] = "";
      }

      // 单选
      if (radio) {
        if (
          this.diagnosisParams[submitKey].key === "other" &&
          key == "other" &&
          otherClear
        ) {
          return (this.diagnosisParams[submitKey] = {});
        }
        let componentsObj = {
          whetherToPrepareTeeth:
            this.$refs["teethComponents"] &&
            this.$refs["teethComponents"].clearTeethType,
        };
        let componentsKeyList = keys(componentsObj);
        if (
          componentsKeyList.includes(submitKey) &&
          ["unchooseable", "tooth_extraction_options_nono", "false"].includes(
            key
          )
        ) {
          let type = {
            whetherToPrepareTeeth: 11,
          }[submitKey];
          componentsObj[submitKey] &&
            componentsObj[submitKey](type ? type : "");
        }
        if (this.diagnosisParams[submitKey].key === key && !noClear) {
          return (this.diagnosisParams[submitKey] = {});
        }
        if (this.diagnosisParams[submitKey].key === key) return;
        if (submitKey === "originalTeethColor") {
          this.diagnosisParams.colorSystem = {};
          this.diagnosisParams.colorNumber = "";
        }
        this.$set(this.diagnosisParams, submitKey, addItem);
        if (
          submitKey === "originalTeethColor" &&
          ["ivoclar", "vita"].includes(key)
        ) {
          let list =
            key === "ivoclar"
              ? this.caseOptions["ivoclar"]
              : this.caseOptions["vita"];
          let currentObj = this.diagnosisParams.originalTeethColor;
          let obj = extend({}, currentObj, {
            children: [
              {
                key: list[0].key,
                value: list[0].value,
                submitKey: "colorSystem",
                inputKey: "colorNumber",
                input: "",
              },
            ],
          });
          this.$set(this.diagnosisParams, "colorSystem", obj);
        }
      } else {
        if (isArray(this.diagnosisParams[submitKey]["children"])) {
          if (oneAsOther) {
            let itx;
            each(this.diagnosisParams[submitKey]["children"], (item, index) => {
              if (item.key === key) {
                itx = index;
              }
            });
            if (itx !== undefined) {
              return this.diagnosisParams[submitKey]["children"].splice(itx, 1);
            }
            if (
              this.diagnosisParams[submitKey]["children"].some(
                (item) => item.key === "other"
              )
            ) {
              each(
                this.diagnosisParams[submitKey]["children"],
                (item, index) => {
                  if (item.key === "other") {
                    this.diagnosisParams[submitKey]["children"] = [item];
                  }
                }
              );
            } else {
              if (
                this.diagnosisParams[submitKey]["children"].length == 1 &&
                key !== "other"
              ) {
                this.diagnosisParams[submitKey]["children"] = [addItem];
                return;
              }
            }
          } else {
            let itx;
            each(this.diagnosisParams[submitKey]["children"], (item, index) => {
              if (item.key === key) {
                itx = index;
              }
            });
            if (itx !== undefined && !noClear) {
              return this.diagnosisParams[submitKey]["children"].splice(itx, 1);
            }
            if (itx !== undefined && noClear) {
              frontToothInDefault();
              return "";
            }
          }
          //1对多互斥
          if (oneOfMore) {
            let { one, more } = oneOfMore;
            if (key === one) {
              this.diagnosisParams[submitKey]["children"] = [];
              this.diagnosisParams[submitKey]["children"] = filter(
                this.diagnosisParams[submitKey]["children"],
                (it) => more.indexOf(it.key) === -1
              );
            }
            if (more.indexOf(key) !== -1) {
              this.diagnosisParams[submitKey]["children"] = filter(
                this.diagnosisParams[submitKey]["children"],
                (it) => it.key !== one
              );
            }
          }

          this.diagnosisParams[submitKey]["children"].push(addItem);
        } else {
          this.$set(this.diagnosisParams[submitKey], "children", []);
          this.diagnosisParams[submitKey]["children"].push(addItem);
        }

        each(adultOptionsKey, (it) => {
          if (it === submitKey) {
            this.sortOption(it);
          }
        });
      }
    },
    saveData() {
      let dia = this.diagnosisParams;
      let obj = {};
      for (let i in dia) {
        let k;
        if (isObject(dia[i]) && keys(dia[i]).length) {
          // 一级对象
          if (dia[i].key) {
            obj[i] = dia[i].key;
          }
          for (let childrenI in dia[i]) {
            // 对象内数组
            if (
              isArray(dia[i][childrenI]) &&
              dia[i][childrenI].length &&
              dia[i].key
            ) {
              let length = Array.from(
                new Set(map(dia[i][childrenI], (item) => item.submitKey))
              ).length;
              let threeList = flattenDeep(
                filter(map(dia[i][childrenI], (item) => item["children"]))
              );
              if (threeList.length) {
                let listKeyList = [];
                each(threeList, (item) => {
                  if (!listKeyList.includes(item.submitKey)) {
                    listKeyList.push(item.submitKey);
                  }
                });
                // 8 每个一个字段
                if (listKeyList.length > 1) {
                  each(listKeyList, (submitKey) => {
                    obj[submitKey] = map(
                      filter(threeList, (item) => item.submitKey === submitKey),
                      (it) => it.key
                    ).join();
                  });
                } else {
                  //10 多用一个字段
                  let str = map(threeList, (item) => {
                    k = item.submitKey;
                    if (item.inputKey) {
                      obj[item.inputKey] = item.input;
                    }
                    return item.key;
                  }).join();
                  obj[k] = str;
                }
              }

              if (length === 1) {
                let str = map(dia[i][childrenI], (item) => {
                  k = item.submitKey;
                  if (item.inputKey) {
                    obj[item.inputKey] = item.input;
                  }
                  return item.key;
                }).join();
                obj[k] = str;
              } else if (length > 1) {
                let soleList = [];
                each(dia[i][childrenI], (item) => {
                  if (soleList.indexOf(item.submitKey) === -1) {
                    soleList.push(item.submitKey);
                  }
                  if (item.inputKey) {
                    obj[item.inputKey] = item.input;
                  }
                });
                each(soleList, (item) => {
                  obj[item] = map(
                    filter(dia[i][childrenI], (it) => it.submitKey === item),
                    (a) => a.key
                  ).join();
                });
              }
            }

            if (
              !dia[i].key &&
              isArray(dia[i][childrenI]) &&
              dia[i][childrenI].length
            ) {
              //用于区分数组内是否有不同的submitKey   例子11
              let list = [];
              each(dia[i][childrenI], (item) => {
                if (!list.includes(item.submitKey)) {
                  list.push(item.submitKey);
                }
              });
              if (list.length > 1) {
                each(list, (submitKey) => {
                  obj[submitKey] = map(
                    filter(
                      dia[i][childrenI],
                      (item) => item.submitKey === submitKey
                    ),
                    (it) => it.key
                  ).join();
                });
              } else {
                let str = map(dia[i][childrenI], (item) => {
                  if (item.inputKey) {
                    obj[item.inputKey] = item.input;
                  }
                  return item.key;
                }).join();
                obj[i] = str;
              }
            }
          }
        }
      }
      let {
        clinicOtherInfo,
        veneerThicknessRequirements,
        toothOtherProblemThree,
        desiredToothPosition,
        originalTeethColorNote,
        targetTypeNote,
        targetColorNote,
        specialInstructions,
      } = dia;
      let list = [];
      each(this.commonTeeth, (item) => {
        if (item.LOSS) {
          list.push(extend({}, item, { type: 1 }));
        }
        if (item.DENTURE) {
          list.push(extend({}, item, { type: 11 }));
        }
        if (item.VENEER) {
          list.push(extend({}, item, { type: 10 }));
        }
        if (item.CROWN) {
          list.push(extend({}, item, { type: 12 }));
        }
        if (item.INLAY) {
          list.push(extend({}, item, { type: 13 }));
        }
        if (item.GRIND) {
          list.push(extend({}, item, { type: 14 }));
        }
      });
      let toothInfoList = filter([...list], (item) => item.number > 0);

      let imgObj = pickBy({
        sideFacePicture: dia.sideFacePicture, // 侧面像
        frontFacePicture: dia.frontFacePicture, // 正面像
        smilePicture: dia.smilePicture, // 微笑像
        upToothPicture: dia.upToothPicture, // 上牙列像
        downToothPicture: dia.downToothPicture, // 下牙列像
        mouthRightPicture: dia.mouthRightPicture, // 口内右侧位像
        mouthFrontPicture: dia.mouthFrontPicture, // 口内正位像
        mouthLeftPicture: dia.mouthLeftPicture, // 口内左侧位像
        xrayCurvePicture: dia.xrayCurvePicture, // 全颌曲面断层片
        xraySidePicture: dia.xraySidePicture, // 头颅侧位定位片
        otherPicture:
          dia.otherPicture && dia.otherPicture.length ? dia.otherPicture : "", // 其他影像资料
        anteriorOverjetPicture: dia.anteriorOverjetPicture, //前牙覆盖像,
        openAndClosePicture: dia.openAndClosePicture,
        bitePicture: dia.bitePicture,
      });
      let {
        acceptWisdomTeethExtraction,
        acceptPremolarsExtraction,
        acceptDecayedTeethExtraction,
        acceptIpr,
        undergoTeethRestoration,
        implantAnchoragePlacement,
        cbctPhotography,
        orthodonticsTreatmentBefore,
      } = obj;
      let PicObj = {};
      for (const item in imgObj) {
        if (item !== "otherPicture") {
          PicObj[item] = JSON.stringify(this.isJSON(imgObj[item]));
        } else {
          try {
            JSON.parse(imgObj[item]);
            PicObj[item] = imgObj[item];
          } catch (error) {
            let list = [];
            map(imgObj[item].split(","), (item) => {
              list.push(this.isJSON(item));
            });
            PicObj[item] = JSON.stringify(list);
          }
        }
      }
      obj = extend(
        {},
        PicObj,
        obj,
        {
          acceptWisdomTeethExtraction: this.needRTD
            ? acceptWisdomTeethExtraction
            : "",
          acceptPremolarsExtraction: this.needRTD
            ? acceptPremolarsExtraction
            : "",
          acceptDecayedTeethExtraction: this.needRTD
            ? acceptDecayedTeethExtraction
            : "",
          acceptIpr: this.needRTD ? acceptIpr : "",
          undergoTeethRestoration: this.needRTD ? undergoTeethRestoration : "",
          implantAnchoragePlacement: this.needRTD
            ? implantAnchoragePlacement
            : "",
          cbctPhotography: this.needRTD ? cbctPhotography : "",
          orthodonticsTreatmentBefore: this.needRTD
            ? orthodonticsTreatmentBefore
            : "",
        },
        pickBy({
          toothInfoList: toothInfoList.filter((item) => item.number !== "0"),
          clinicOtherInfo,
          veneerThicknessRequirements,
          toothOtherProblemThree,
          desiredToothPosition,
          originalTeethColorNote,
          targetTypeNote,
          targetColorNote,
          specialInstructions,
          caseId: dia.caseId || this.$route.query.caseId,
          id: dia.id,
          digitalModelFile: dia.digitalModelFile, //数字模型
          siliconeRubberModel: dia.siliconeRubberModel, // 硅橡胶模型
        })
      );
      this.isLoading = true;
      if (!dia.id) {
        return createCurePlan(pickBy(obj))
          .then((data) => {
            this.isLoading = false;
            this.diagnosisParams.id = data;
            notifyMsg(this, "success", this.$t("common.message.yzc"), 2500);
            changeRouteQuery(this, { curePlanId: data });
            return Promise.resolve();
          })
          .catch(() => {
            this.isLoading = false;
            this.$emit("saveError");
            return Promise.reject();
          });
      } else {
        return editCurePlan(pickBy(obj))
          .then(() => {
            this.isLoading = false;
            notifyMsg(this, "success", this.$t("common.message.yzc"), 2500);
            changeRouteQuery(this, { curePlanId: dia.id });
            return Promise.resolve();
          })
          .catch(() => {
            this.isLoading = false;
            this.$emit("saveError");
            return Promise.reject();
          });
      }
    },
    isJSON(str) {
      if (str == "") {
        return "";
      }
      if (typeof str == "string") {
        try {
          JSON.parse(str);
          let obj = JSON.parse(str);
          if (!obj.nanoId) {
            obj.nanoId = obj.ETag;
          }
          return obj;
        } catch (error) {
          return { ETag: str, nanoId: str };
        }
      }
    },
    //页面滚动
    handleScroll() {
      if (this.isScroll) {
        this.isScroll = false;
        return new Promise((resolve) => {
          return setTimeout(() => {
            const TOP =
              document.documentElement && document.documentElement.scrollTop;
            let obj = {};
            let {
              d1,
              d2,
              d3,
              d4,
              d5,
              d6,
              d7,
              d8,
              d9,
              d10,
              d11,
              d12,
              d13,
              d14,
            } = this.leftTopObj;
            obj = {
              [TOP < d1]: "d1",
              [TOP < d2 && TOP >= d1]: "d1",
              [TOP < d3 && TOP >= d2]: "d2",
              [TOP < d4 && TOP >= d3]: "d3",
              [TOP < d5 && TOP >= d4]: "d4",
              [TOP < d6 && TOP >= d5]: "d5",
              [TOP < d7 && TOP >= d6]: "d6",
              [TOP < d8 && TOP >= d7]: "d7",
              [TOP < d9 && TOP >= d8]: "d8",
              [TOP < d10 && TOP >= d9]: "d9",
              [TOP < d11 && TOP >= d10]: "d10",
              [TOP < d12 && TOP >= d11]: "d11",
              [TOP < d13 && TOP >= d12]: "d12",
              [TOP < d14 && TOP >= d13]: "d13",
              [TOP >= d14]: "d14",
            };
            this.selectLeftKey = obj[true] || "d14";
            this.leftHandleScroll(Math.floor(TOP / 8));
            this.isScroll = true;
            return resolve();
          }, 500);
        });
      }
    },
    initOption({ type, submitKey, addItem }) {
      // type: 1 一级对象, 2 数组
      let data = this.details;
      let valueData = this.diaKeyObj;
      let dia = this.diagnosisParams;
      if (data[submitKey]) {
        if (type === 1) {
          dia[submitKey] = {
            submitKey,
            key: data[submitKey],
            value: valueData[data[submitKey]],
          };
        } else if (type === 2) {
          dia[submitKey] = {
            children: map(
              data[submitKey] && data[submitKey].split(","),
              (item) => {
                return { key: item, value: valueData[item], submitKey };
              }
            ),
          };
        } else if (type === 3) {
          this.$set(this.diagnosisParams, submitKey, addItem);
        }
      }
    },
    // 数据初始化
    initParams(data) {
      //预览提交的返回
      const diagnsisLeftKey = sessionStorage.getItem("diagnosisKey") || 1;
      this.selectLeft(
        diagnsisLeftKey !== "undefined" ? diagnsisLeftKey : 1,
        "init"
      );

      let imgObj = pickBy({
        sideFacePicture: data.sideFacePicture, // 侧面像
        frontFacePicture: data.frontFacePicture, // 正面像
        smilePicture: data.smilePicture, // 微笑像
        upToothPicture: data.upToothPicture, // 上牙列像
        downToothPicture: data.downToothPicture, // 下牙列像
        mouthRightPicture: data.mouthRightPicture, // 口内右侧位像
        mouthFrontPicture: data.mouthFrontPicture, // 口内正位像
        mouthLeftPicture: data.mouthLeftPicture, // 口内左侧位像
        xrayCurvePicture: data.xrayCurvePicture, // 全颌曲面断层片
        xraySidePicture: data.xraySidePicture, // 头颅侧位定位片
        otherPicture: data.otherPicture, // 其他影像资料
        anteriorOverjetPicture: data.anteriorOverjetPicture, //前牙覆盖像
        bitePicture: data.bitePicture,
        openAndClosePicture: data.openAndClosePicture,
      });
      extend(this.diagnosisParams, imgObj);
      let dia = this.diagnosisParams;
      let valueData = this.diaKeyObj;

      this.diagnosisParams.digitalModelFile = data.digitalModelFile; // 数字模型
      this.diagnosisParams.siliconeRubberModel = data.siliconeRubberModel; // 硅橡胶模型

      let lossTeeth = filter(data.toothInfoList, (item) => item.type === "1");
      let dentureTeeth = filter(
        data.toothInfoList,
        (item) => item.type === "11"
      );
      let veneerTeeth = filter(
        data.toothInfoList,
        (item) => item.type === "10"
      );
      let crownTeeth = filter(data.toothInfoList, (item) => item.type === "12");
      let inlayTeeth = filter(data.toothInfoList, (item) => item.type === "13");
      let grindTeeth = filter(data.toothInfoList, (item) => item.type === "14");
      let untreatedList = [
        ...lossTeeth,
        ...dentureTeeth,
        ...veneerTeeth,
        ...crownTeeth,
        ...inlayTeeth,
        ...grindTeeth,
      ];

      let mainList = adultTeethList;

      if (untreatedList.length) {
        let teethTypeObj = {
          1: "LOSS", // 缺失牙
          10: "VENEER", // 贴面
          11: "DENTURE", // 假牙
          12: "CROWN", // 冠
          13: "INLAY", // 嵌体
          14: "GRIND", // 备牙
        };
        this.commonTeeth = filter(
          map(mainList, (item) => {
            let obj;
            each(untreatedList, (it) => {
              if (item.number === it.number) {
                obj = extend({}, item, obj, {
                  [teethTypeObj[it.type]]: teethTypeObj[it.type],
                });
              }
            });
            return obj ? obj : item;
          })
        );
      } else {
        this.commonTeeth = yysdDefaultBright;
      }

      // 3. 临床状况
      this.initOption({ type: 2, submitKey: "clinicInfo" });
      dia.clinicOtherInfo = data.clinicOtherInfo;
      this.initOption({ type: 1, submitKey: "occlusalRelationship" });
      this.initOption({ type: 1, submitKey: "adjacentGap" });
      this.initOption({ type: 2, submitKey: "toothProblemThree" });
      dia.toothOtherProblemThree = data.toothOtherProblemThree;

      // 4. 所需牙位
      if (data["desiredToothPosition"]) {
        dia.desiredToothPosition = data.desiredToothPosition;
      }

      // 5. 原牙色系
      if (data["originalTeethColor"]) {
        this.initOption({ type: 1, submitKey: "originalTeethColor" });
      }
      if (data["colorSystem"]) {
        let addItem = pickBy({
          children: [
            {
              submitKey: "colorSystem",
              key: data["colorSystem"],
              value: valueData[data["colorSystem"]],
              input: data["colorNumber"] || "",
              inputKey: "colorNumber",
            },
          ],
          key: data["colorSystem"],
          submitKey: "originalTeethColor",
          value: valueData[data["colorSystem"]],
        });
        this.initOption({ type: 3, submitKey: "colorSystem", addItem });
      }
      if (data["originalTeethColorNote"]) {
        dia.originalTeethColorNote = data.originalTeethColorNote;
      }

      // 6. 目标牙色
      if (data["targetColor"]) {
        this.initOption({ type: 2, submitKey: "targetColor" });
      }
      if (data["targetColorNote"]) {
        dia.targetColorNote = data["targetColorNote"];
      }

      // 7. 目标类型
      if (data["targetType"]) {
        this.initOption({ type: 2, submitKey: "targetType" });
      }
      if (data["targetTypeNote"]) {
        dia.targetTypeNote = data["targetTypeNote"];
      }

      // 8. 目标透明度
      if (data["targetTransparency"]) {
        this.initOption({ type: 2, submitKey: "targetTransparency" });
      }

      // 9. 材料类型
      if (data["materialType"]) {
        this.initOption({ type: 2, submitKey: "materialType" });
      }

      // 10. 是否备牙
      if (data["whetherToPrepareTeeth"]) {
        this.initOption({ type: 1, submitKey: "whetherToPrepareTeeth" });
      }

      // 11. 贴面厚度要求
      if (data["veneerThicknessRequirements"]) {
        dia.veneerThicknessRequirements = data.veneerThicknessRequirements;
      }

      // 12. 牙面纹路
      if (data["surfaceTexture"]) {
        this.initOption({ type: 2, submitKey: "surfaceTexture" });
      }

      // 13. 设计要求
      if (data["designRequestOne"]) {
        this.initOption({ type: 1, submitKey: "designRequestOne" });
      }
      if (data["designRequestTwo"]) {
        this.initOption({ type: 2, submitKey: "designRequestTwo" });
      }

      // 14. 特殊说明
      if (data["specialInstructions"]) {
        dia.specialInstructions = data.specialInstructions;
      }
      return Promise.resolve();
    },
    getCaseOptions() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      let id = this.queryCurePlanId;
      this.isLoading = true;
      getDictAllList([
        // 获取病例选项的值
        "clinic_info_f",
        "occlusal_relationship",
        "adjacent_gap",
        "tooth_problem_three",
        "original_teeth_color_f",
        "ivoclar",
        "vita",
        "target_color",
        "target_type",
        "target_transparency",
        "material_type",
        "whether_to_prepare_teeth",
        "veneer_thickness_requirements",
        "surface_texture",
        "design_request_one_f",
        "design_request_two_f",
        "special_instructions",
        "silicone_rubber_model",
      ])
        .then((data) => {
          this.isLoading = false;
          let dictObj = {},
            diaKeyObj = {};
          data.forEach((item) => {
            if (!dictObj[item.parentKey]) {
              dictObj[item.parentKey] = [item];
            } else {
              dictObj[item.parentKey].push(item);
            }
            diaKeyObj[item.key] = item.value;
          });
          this.diaKeyObj = diaKeyObj;
          this.caseOptions = dictObj;
          this.diagnosisParams.caseId = this.$route.query.caseId;
          if (id) {
            this.diagnosisParams.id = id;
            detailCurePlan(id).then((obj) => {
              this.details = obj;
              this.initParams(obj).then(() => {
                setTimeout(() => {
                  this.isResponse = 0;
                }, 800);
              });
            });
            cureProblemList({
              caseId: this.queryCaseId,
              curePlanId: id,
              backNode: "to_submit",
            }).then((data) => {
              const diagnosisProblem = {};
              let gnType = "groupNo";
              data.forEach((item) => {
                diagnosisProblem[item[gnType]] = item.content;
              });
              this.problemList = data.filter(
                (item) => +item[gnType] < 15 && +item[gnType] > 0
              );

              this.isProductErr = this.problemList.length
                ? false
                : !some(data, (item) => +item.groupNo === 0);

              this.diagnosisProblem = diagnosisProblem;
              setTimeout(() => {
                if ($(".dia_problem").length > 0) {
                  this.pEleHeight = $(".dia_problem").height() + 30;
                }
              }, 100);
            });
          } else {
            setTimeout(() => {
              this.isResponse = 0;
            }, 800);
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  components: {
    ChildrenTeeth,
    YysdTeeth,
    VeneersTeeth,
    IntervalCorrect,
    FullLoading,
    AdjoinDislodge,
    ReserveDinterval,
  },
  mounted() {
    this.rightHeight = window.getComputedStyle(this.$refs["rightTable"]).height;
    each(this.leftList, (item, index) => {
      if (document.getElementById(index + 1)) {
        this.leftTopObj["d" + (index + 1)] = document.getElementById(
          index + 1
        ).offsetTop;
      }
    });
    window.addEventListener("scroll", this.handleScroll, false);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, false);
  },
  created() {
    this.isResponse += 1;
    this.getCaseOptions();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .mui-popover {
  position: fixed;
}

.dia_problem {
  width: 100%;
  background: #f4f4f4;
  padding: 10px 16px 30px;
  margin-bottom: 30px;

  h5 {
    font-size: 0.22rem;
    color: $main_theme_color_333;
  }

  span {
    margin-right: 0.2rem;
  }

  .pro_box {
    word-break: break-all;
    font-size: 0.16rem;
    line-height: 0.3rem;
    color: $main_theme_color_333;
  }
}

/deep/.el-scrollbar__wrap {
  overflow-x: hidden !important;
}

.sagittal_relationship_input_mm {
  position: absolute;
  right: 0;
  top: 0.08rem;
}

.midcourt_line_input_mm {
  position: absolute;
  right: 0.6rem;
  top: 0.1rem;
}

.midcourt_line_input_mm_two {
  position: absolute;
  right: 0.6rem;
  top: 0;
}

.table_wrap {
  display: flex;
  margin-bottom: -1rem;

  .left_option {
    width: 2.4rem;
    height: 6.1rem;
    background-color: #fff;
    position: fixed;

    .left_option_item {
      width: 100%;
      height: 0.5rem;
      transition: all 0.2s;
      font-size: 0.16rem;
      color: $main_theme_color_333;
      cursor: pointer;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 0 0.12rem;

      .left_wire {
        width: 4px;
        height: 32px;
        background: $main_theme_color;
        position: absolute;
        left: 0;
      }
    }

    .left_option_item:hover {
      transition: all 0.2s;
      background: #f9f9f9;
    }
  }

  .right_table {
    margin-left: 2.4rem;
    width: calc(100% - 2.4rem);
    background-color: #fff;
    padding: 0.3rem 0.2rem 0.5rem 0.59rem;
    border-left: 1px solid #e5e5e5;

    .title_paragraph {
      font-size: 18px;
      line-height: 24px;
      color: $main_theme_color_333;
      margin-bottom: 10px;
    }

    .select_type {
      margin-bottom: 30px;

      .select_type_title {
        font-size: 16px;
        color: $main_theme_color_333;
      }

      .tips_text {
        margin-left: 20px;
      }
    }
  }
}

.input_mm {
  position: absolute;
  right: 0.2rem;
  top: 0.08rem;
}

.common_btn {
  position: relative;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.2rem;
  color: $main_theme_color_666;
  border-radius: 4px;
  border: 0.01rem solid #bbbbbb;
  cursor: pointer;
  font-size: 0.14rem;
  margin-bottom: 0.1rem;
  white-space: nowrap;
  user-select: none;
  background-color: #fff;
}

.is_active {
  background-color: $main_theme_color;
  color: $main_theme_color_666 !important;
  border: 0.01rem solid $main_theme_color;
}

.remark_box {
  width: 100%;
  margin-top: 0.1rem;

  textarea {
    width: 90%;
    border: 0.01rem solid #c8c8c8;
    border-radius: 0.08rem;
    padding: 0.2rem;
    color: #333;
    min-height: 1.34rem;
  }
}

.option_left_title {
  min-width: 0.7rem;
  font-size: 14px;
  color: $main_theme_color_666;
}

.single_tip_box {
  color: $main_theme_color_333;
  background-color: #f4f4f4;
  font-size: 0.14rem;
  padding: 0.15rem 0.2rem;

  p {
    margin-bottom: 0.1rem;

    &:last-child {
      margin-bottom: 0;
    }

    input {
      width: 100%;
      outline: none;
      border: none;
    }
  }
}

.popover_con_right_iup {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.2rem;
  border: 0.01rem solid #c8c8c8;
  width: 80%;
  height: 0.4rem;
  border-radius: 0.06rem;
  padding-right: 0.17rem;

  input {
    width: 50%;
    padding: 0.13rem 0.9rem 0.13rem 0.15rem;
    font-size: 0.14rem;
    flex: 1;
    outline: none;
    border: none;
  }
}

.single_con {
  color: $main_theme_color_333;
  font-size: 0.16rem;
  position: relative;

  .remark_box {
    width: 100%;

    textarea {
      width: 100%;
      border: 0.01rem solid #c8c8c8;
      border-radius: 0.08rem;
      padding: 0.2rem;
      color: #333;
      min-height: 1.34rem;
    }
  }

  .lc_box {
    background-color: #f9f9f9;
    padding: 0.3rem 0.2rem 0.16rem 0.3rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.14rem;
    color: #333;

    .check_box {
      flex: 1;
    }

    .el-checkbox-group {
      display: flex;
      flex: 1;
      flex-wrap: wrap;

      .check_single {
        margin: 0 0.1rem 0.2rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .other_box {
      width: 2.81rem;

      .tit {
        margin-bottom: 0.12rem;
      }

      textarea {
        width: 100%;
        height: 0.98rem;
        border-radius: 0.04rem;
        background-color: #fff;
        padding: 0.1rem;
        box-sizing: border-box;
      }
    }
  }

  .single_c_head {
    margin-bottom: 0.3rem;
  }

  .s_c_box {
    display: flex;

    .s_c_b_left {
      margin-right: 0.2rem;
      margin-top: 0.13rem;
      color: $main_theme_color_333;
    }

    .t_b_p {
      color: $main_theme_color_666;
      min-width: 0.64rem;
      margin-right: 20px;
      position: relative;
      top: -4px;
    }

    .t_b_tip {
      color: $main_theme_color_999;
      font-size: 0.14rem;
    }
  }
}

.t_b_box {
  display: flex;
  align-items: center;
  font-size: 0.16rem;
  flex-wrap: wrap;
  margin-bottom: 0.16rem;
  line-height: 0.2rem;
}

.popover_con {
  display: flex;

  .popover_con_left {
    min-width: 0.3rem;
    white-space: nowrap;
    padding-top: 0.12rem;
    margin-right: 10px;
    color: $main_theme_color_333;
  }

  .popover_con_right {
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    span {
      margin-top: 0.1rem;
      margin-bottom: 0.1rem;
    }

    .popover_con_right_p {
      margin-bottom: 0.1rem;
    }

    .popover_con_right_iup {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 0.2rem;
      border: 0.01rem solid #c8c8c8;
      width: 80%;
      height: 0.4rem;
      border-radius: 0.06rem;
      padding-right: 0.17rem;

      input {
        padding: 0.13rem 0 0.13rem 0.15rem;
        font-size: 0.14rem;
        flex: 1;
        outline: none;
        border: none;
      }
    }
  }
}

.left_active {
  color: $main_theme_color !important;
}

.popover_db {
  display: flex;
  justify-content: center;
  align-items: center;

  .popover_db_left {
    border-left: 0.01rem solid #c8c8c8;
    border-bottom: 0.01rem solid #c8c8c8;
    width: 0.2rem;
    height: 0.3rem;
  }

  .popover_db_right {
    display: flex;
    padding-top: 0.4rem;

    span {
      margin-left: 0;
      margin-right: 0.2rem;
    }
  }
}

.problem_note {
  word-break: break-all;
  color: $main_theme_color;
  margin-bottom: 0.2rem;
  line-height: 0.22rem;
  font-size: 0.14rem;
  margin-top: 4px;
}

.thickness_progress {
  margin: 20px 0;

  .progress_container {
    position: relative;
    width: 400px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  .triangle {
    position: absolute;
    width: 0px;
    height: 0px;
  }

  .left_triangle {
    top: 1px;
    left: -6px;
    border-left: 6px solid transparent;
    border-right: 6px solid #bbbbbb;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    transform: translate(-50%, -50%);
  }

  .right_triangle {
    top: 1px;
    right: -6px;
    border-left: 6px solid #bbbbbb;
    border-right: 6px solid transparent;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    transform: translate(50%, -50%);
  }

  .progress_text {
    justify-content: space-between;
    color: #666666;
    font-size: 14px;

    p {
      flex: 1;
    }

    .active_text {
      color: #fcc80e;
    }
  }

  .main_color {
    stroke: rgb(252, 200, 14);
    stroke-width: 2;
  }

  .default_color {
    stroke: rgb(187, 187, 187);
    stroke-width: 2;
  }

  .active_left {
    border-right: 6px solid #fcc80e !important;
  }

  .active_right {
    border-left: 6px solid #fcc80e !important;
  }
}

.options_tips {
  width: 100%;
  padding: 10px 20px;
  background-color: #f4f4f4;
  border-radius: 4px;
  margin-top: 20px;

  p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

.required_container {
  position: relative;

  .required_input {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #ed4027;
    font-size: 14px;
  }
}

.input_number {
  user-select: none;

  .numbox {
    width: 196px;
    height: 36px;
    border: 1px solid #bbbbbb;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
  }

  .num_input {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    color: #333333;
  }

  .focus_input {
    border-color: #fcc80e !important;
  }

  .triangle {
    position: absolute;
    right: 6px;
    width: 0px;
    height: 0px;
  }

  .add {
    top: 0px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid transparent;
    border-bottom: 6px solid #999;

    &:hover {
      border-bottom: 6px solid #fcc80e;
    }
  }

  .minu {
    bottom: 0px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #999;
    border-bottom: 6px solid transparent;

    &:hover {
      border-top: 6px solid #fcc80e;
    }
  }
}
</style>


